import React from "react";
import clsx from "clsx";
import css from "./cabinet-buttons.module.scss";
import * as Icon from "react-feather";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Button, Dropdown, Hidden } from "ui";
import { useRouter } from "next/router";
import { useApolloClient, useMutation } from "@apollo/client";
import { useUserContext } from "application/context/user-context-provider";
import LOGOUT_ACCOUNT from "application/graphql/logout";
import { useClientProfileQuery, useClientQuery } from "generated/types";
import { useMobileMenuStore } from "../../../../store/mobile-menu";
import { RouteEnum } from "common/enum/route.enum";
import Link from "next/link";
import { LinkEnum } from "common/enum/links.enum";
import {ApplicationsIcon, LogoutIcon, MenuIcon, EditProfileIcon, QuestionIcon, SettingsIcon} from "./cabinet-buttons.svg";

interface CabinetButtonsProps {
  notifications: number;
  name: string;
  onNotifications: () => void;
  onEmails: () => void;
  onName: () => void;
}

const CabinetButtons: React.FC<CabinetButtonsProps> = ({
  name,
  notifications,
}) => {
  const [open, setOpen] = React.useState(false);
  const router = useRouter();
  const apolloClient = useApolloClient();
  const userContext = useUserContext();
  const { data } = useClientProfileQuery();
  const { data: clientProfile } = useClientProfileQuery();

  // close when url changes
  React.useEffect(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const [logout] = useMutation(LOGOUT_ACCOUNT, {});
  const mobileMenuStore = useMobileMenuStore();

  // PRO status state
  const isProActive = userContext.userContext.user?.tariff?.status === "ACTIVE";
  const expiredAt =
    userContext.userContext.user?.tariff?.expiredAt &&
    new Date(
      userContext.userContext.user.tariff.expiredAt
    ).toLocaleDateString();

  return (
    <div className={css.Root}>
      <DropdownMenu.Root
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
        }}
      >
        <DropdownMenu.Trigger asChild>
          <div className={css.Client}>
            <div className={css.ClientButton}>
              <div className={css.ClientButton__name}>{name}</div>
              <div
                className={clsx({
                  [css.ClientButton__badge]: true,
                  [css.ClientButton__badge_show]: isProActive,
                })}
              >
                PRO
              </div>
            </div>
            <Icon.ChevronDown
              width={"1rem"}
              color="#8A9299"
              strokeWidth={"1.4"}
              style={{
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <Dropdown.Content
            style={{
              zIndex: 9999,
            }}
            align="end"
          >
            <Dropdown.Arrow />
            <Dropdown.Item
              onSelect={() => {
                router.push(
                  `/${clientProfile?.getMyClientProfile?.clientProfile?.address}`
                );
              }}
              className={css.ProfileItem}
            >
              <MenuIcon />
              <div>
                {data?.getMyClientProfile?.clientProfile?.name || "Название"}
              </div>
            </Dropdown.Item>

            <Dropdown.Item
              className={clsx({
                [css.ProfileItem]: true,
                [css.ProfileItem_hide]: isProActive,
              })}
              onSelect={() => {
                router.push(RouteEnum.TARIFFS);
              }}
            >
              <div className={css.ProfileItem__subscription_upgrade}>
                Улучшить аккаунт
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              disabled={true}
              style={{ cursor: "unset" }}
              className={clsx({
                [css.ProfileItem]: true,
                [css.ProfileItem_hide]: !isProActive,
                [css.ProfileItem__subscription_finish_item]: true,
              })}
            >
              {expiredAt ? (
                <div className={css.ProfileItem__subscription_finish}>
                  PRO активен до {expiredAt}
                </div>
              ) : (
                <div className={css.ProfileItem__subscription_finish__nodata}>
                  PRO активен
                </div>
              )}
            </Dropdown.Item>

            <Hidden down="tablet">
              <Dropdown.Item
                onSelect={() => {
                  router.push(`/profile/edit/profile`);
                }}
                className={css.ProfileItem}
              >
                <EditProfileIcon />
                <div>Редактировать профиль</div>
              </Dropdown.Item>
            </Hidden>
            <Hidden up="tablet">
              <Dropdown.Item
                onSelect={() => {
                  mobileMenuStore.toggle();
                }}
                className={css.ProfileItem}
              >
                <EditProfileIcon />
                <div>Редактировать профиль</div>
              </Dropdown.Item>
            </Hidden>

            {/* {isProActive && */}
            <Dropdown.Item
              className={css.ProfileItem}
              onSelect={() => {
                router.push(`/applications`);
              }}
            >
              <ApplicationsIcon />
              <div>Заявки</div>
              {notifications > 0 && (
                <div className={css.applicationCounter}>
                  <div className={css.applicationCounter__inner}>
                    <span>{notifications}</span>
                  </div>
                </div>
              )}
            </Dropdown.Item>
            {/* } */}

            <Dropdown.Item
              className={css.ProfileItem}
              onSelect={() => {
                router.push(`/profile/settings`);
              }}
            >
              <SettingsIcon />
              <div>Настройки</div>
            </Dropdown.Item>

            <Dropdown.Item className={css.ProfileItem} onSelect={() => {}}>
              <QuestionIcon />
              <Link
                href={LinkEnum.ASK_QUESTION}
                target="_blank"
                className={css.ProfileItem_link}
              >
                Задать вопрос
              </Link>
            </Dropdown.Item>

            <Dropdown.Item
              onSelect={async () => {
                await logout();
                apolloClient.resetStore();
                apolloClient.clearStore();
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                userContext.logoutUserContext();
                router.push(`/`);
                router.reload();
              }}
              className={css.ProfileItem}
            >
              <LogoutIcon />
              <div>Выход</div>
            </Dropdown.Item>
          </Dropdown.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default CabinetButtons;
