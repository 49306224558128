import { ReactNode } from "react";
import { create } from "zustand";

interface ModalState {
  open: boolean;
  header?: ReactNode;
  headerStyle?: React.CSSProperties;
  dialogStyle?: React.CSSProperties;
  closeStyle?: React.CSSProperties;
  content: ReactNode;
  onClose?: () => void;
  openModal: ({
    header,
    content,
    headerStyle,
    dialogStyle,
    closeStyle,
    onClose,
  }: {
    header?: ReactNode;
    headerStyle?: React.CSSProperties;
    dialogStyle?: React.CSSProperties;
    closeStyle?: React.CSSProperties;
    content: ReactNode;
    onClose: () => void;
  }) => void;
  closeModal: () => void;
}

export const useModalStore = create<ModalState>((set) => ({
  open: false,
  content: null,
  openModal: ({
    content,
    header,
    headerStyle,
    dialogStyle,
    closeStyle,
    onClose,
  }: {
    content: ReactNode;
    header?: ReactNode;
    headerStyle?: React.CSSProperties;
    dialogStyle?: React.CSSProperties;
    closeStyle?: React.CSSProperties;
    onClose?: () => void;
  }) => {
    set({ open: true, content, headerStyle, dialogStyle, closeStyle, header, onClose });
  },
  closeModal: () => {
    set({
      open: false,
      content: null,
      onClose: undefined,
      headerStyle: undefined,
      dialogStyle: undefined,
      closeStyle: undefined,
      header: undefined,
    });
  },
}));
