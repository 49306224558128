import Saturation from "@uiw/react-color-saturation";
import EditableInput from "@uiw/react-color-editable-input";
import EditableInputRGBA from "@uiw/react-color-editable-input-rgba";
import Hue from "@uiw/react-color-hue";
import css from "./color-picker.module.scss";
import { hsvaToHex, hexToHsva } from "@uiw/color-convert";

function ColorPicker({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className={css.ColorPicker}>
      <Saturation
        hsva={hexToHsva(value)}
        className={css.Saturation}
        onChange={(e) => {
          onChange(hsvaToHex(e));
        }}
      />
      <div className={css.Right}>
        <div className={css.Hue}>
          <Hue
            hue={
              // hue from hsva
              hexToHsva(value).h
            }
            className={css.HuePicker}
            onChange={(e) => {
              onChange(
                hsvaToHex({
                  ...hexToHsva(value),
                  h: e.h,
                })
              );
            }}
          />
          <div
            className={css.HueColor}
            style={{
              // color from hsva
              background: value,
            }}
          ></div>
        </div>
        <EditableInput label="Hex" value={value} className={css.HexInput} />
        <div className={css.Rgba}>
          <span>RGB</span>
          <EditableInputRGBA
            hsva={
              // hsva from hex
              hexToHsva(value)
            }
            className={css.RgbaInput}
            aProps={{
              style: {
                display: "none",
              },
            }}
            onChange={(e) => {
              onChange(e.hex);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export { ColorPicker };
