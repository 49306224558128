import React from "react";
import css from "./tabs.module.scss";

interface TabsProps<T> {
  value: T;
  items: { value: T; label: string }[];
  color?: "primary" | "secondary";
  onChange: (value: T) => void;
}

type TabsFC = <T>(props: TabsProps<T>) => React.ReactElement<TabsProps<T>>;

export const Tabs: TabsFC = ({ value, items, color = "primary", onChange }) => {
  return (
    <div className={color === "primary" ? css.Root : css.SecondaryRoot}>
      {items.map((item, index) => {
        const isActive = item.value === value;

        return (
          <div
            key={index}
            className={isActive ? css.ActiveTab : css.Tab}
            onClick={isActive ? undefined : () => onChange(item.value)}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};
