import {gql} from "@apollo/client";

export type Tariff = {
  clientId: number;
  startedAt: string;
  expiredAt: string;
  outgoingRequests: number;
  availableRequests: number;
  type: "NO_TARIFF" | "ONE_MONTH" | "THREE_MONTH" | "YEAR";
  request:
    | "NO_REQUEST"
    | "ONE_MONTH"
    | "THREE_MONTH"
    | "YEAR"
    | "ACTIVE"
    | "NO_ACTIVE";
  assistant: string;
  assistantLink: string;
  paid: "NO_PAID" | "ON_PENDING" | "PAID";
  status: "ACTIVE" | "NO_ACTIVE" | "REQUEST";
  createdAt: string;
};

export type ClientModel = {
  id: string;
  type: "SPOT" | "AGENCY" | "BRAND";
  email: string;
  name: string;
  published: boolean;
  firstRegistration: boolean;
  tariff?: Tariff;
  profile?: {
    clientId: string
    name: string
    address: string
  }
};

export interface GetMyClientQuery {
  getMyClient: {
    client: ClientModel | null;
  };
}

export interface GetMyClientQueryVars {
}

const GET_MY_CLIENT = gql`
  query GetMyClient {
    getMyClient {
      client {
        id
        type
        email
        name
        published
        firstRegistration
        tariff {
          clientId
          paid
          startedAt
          expiredAt
          outgoingRequests
          availableRequests
          type
          status
          request
          assistant
          assistantLink
        }
        profile {
          clientId
          name
          address
        }
      }
    }
  }
`;

export default GET_MY_CLIENT;
