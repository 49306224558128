import type { AppProps } from "next/app";
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { IncomingHttpHeaders } from "http";
import { useMemo } from "react";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";

type InitialState = NormalizedCacheObject | undefined;

interface IInitializeApollo {
  headers?: IncomingHttpHeaders | null;
  initialState?: InitialState | null;
}

const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const createApolloClient = (
  urls: { apiUrl: string; privateApiUrl: string },
  headers: IncomingHttpHeaders | null = null
) => {
  const enhancedFetch = (url: RequestInfo, init: RequestInit) => {
    return fetch(url, {
      ...init,
      headers: {
        ...init.headers,
        "Access-Control-Allow-Origin": "*",
        Cookie: headers?.cookie ?? "",
        "x-locale": "ru",
      },
    }).then((response) => response);
  };

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([
      createUploadLink({
        uri: typeof window === "undefined" ? urls.privateApiUrl : urls.apiUrl,
        fetchOptions: {
          mode: "cors",
        },
        credentials: "include",
        fetch: enhancedFetch,
      }),
    ]),
    cache: new InMemoryCache(),
  });
};

export const initApolloClient = (
  urls: { apiUrl: string; privateApiUrl: string },
  { headers, initialState }: IInitializeApollo = {
    headers: null,
    initialState: null,
  }
) => {
  const newApolloClient = apolloClient ?? createApolloClient(urls, headers);

  if (initialState) {
    const existingCache = newApolloClient.extract();

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    newApolloClient.cache.restore(data);
  }

  if (typeof window === "undefined") {
    return newApolloClient;
  }

  if (!apolloClient) {
    apolloClient = newApolloClient;
  }

  return newApolloClient;
};

export const addApolloState = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps["pageProps"]
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = apolloClient.cache.extract();
  }

  return pageProps;
};

export const useApollo = (
  urls: { apiUrl: string; privateApiUrl: string },
  pageProps: AppProps["pageProps"]
) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initApolloClient(urls, { initialState: state }),
    [urls, state]
  );

  return store;
};
