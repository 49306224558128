import React, {useEffect, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import cx from "classnames";
import css from "./multiple-select.module.scss";
import Сolor from "color";

interface MultipleSelect<T> {
  value: T[];
  items: { value: T; label: string }[];
  onSelect: (values: T[]) => void;
  error?: boolean;
  description?: string;
  placeholder?: string;
  color?: string;
  raisedItems?: T[];
  hasCounter?: boolean;
  disabled?: boolean;
}

type MultipleSelectFC = <T>(
  props: MultipleSelect<T>
) => React.ReactElement<MultipleSelect<T>>;

export const MultipleSelect: MultipleSelectFC = (
  {
    value,
    items,
    onSelect,
    error,
    description,
    placeholder,
    color = "#F4EAFA",
    raisedItems,
    hasCounter = false,
    disabled = false
  }) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("click", clickHandler as any, true);
    return () => {
      document.removeEventListener("click", clickHandler as any);
    };
  }, [isOpen]);

  const tags = items.filter((item) => value.includes(item.value));

  return (
    <div className={css.Root} ref={wrapperRef}>
      <div>
        <div
          className={cx(
            hasCounter
              ? isOpen
                ? css.SelectCounterOpen
                : css.SelectCounter
              : css.Select,
            disabled ? css.disabled : "",
            error && css.ErrorSelect)}
          onClick={() => {
            if (!disabled) {
              setOpen(!isOpen)
            }
          }}
        >
          {hasCounter
            ? <>
              {tags.length > 0
                ?
                <>
                  {tags.length === 1
                    ? <>
                      {tags[0].label}
                    </>
                    : <>
                      <div className={css.Counter}>
                        Выбрано: {tags.length}
                      </div>
                    </>

                  }
                </>
                : placeholder ? (
                  <span className={css.Placeholder}>{placeholder}</span>
                ) : (
                  ""
                )
              }
            </>
            : <>
              {tags.length > 0 ? (
                <div className={css.Tags}>
                  {tags.map((item, index) => (
                    <div
                      key={index}
                      className={css.Tag}
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelect(value.filter((it) => it !== item.value));
                      }}
                      style={{
                        background: color,
                      }}
                    >
                      {item.label}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          paddingLeft: 7,
                          width: 20,
                          height: 20,
                        }}
                      >
                        <path
                          d="M10.5 3.5L3.5 10.5"
                          stroke={Сolor(color).darken(0.2).hex()}
                          strokeWidth="1.225"
                          strokeLinecap="square"
                        />
                        <path
                          d="M3.5 3.5L10.5 10.5"
                          stroke={Сolor(color).darken(0.2).hex()}
                          strokeWidth="1.225"
                          strokeLinecap="square"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              ) : placeholder ? (
                <span className={css.Placeholder}>{placeholder}</span>
              ) : (
                ""
              )}
            </>
          }
        </div>

        <CSSTransition
          in={isOpen}
          timeout={150}
          classNames={{
            enter: css.ListEnter,
            enterActive: css.ListEnterActive,
            exit: css.ListExit,
            exitActive: css.ListExitActive,
          }}
          unmountOnExit
        >
          <div className={css.List}>
            {raisedItems &&
              raisedItems.length > 0 &&
              items
                .filter((item) => raisedItems.includes(item.value))
                .map((item, index) => (
                  <>
                    <div
                      key={`${index}_${item.value}`}
                      className={
                        value.includes(item.value) ? css.ActiveItem : css.Item
                      }
                      onClick={() => {
                        if (value.includes(item.value)) {
                          onSelect(value.filter((it) => it !== item.value));
                        } else {
                          onSelect([...value, item.value]);
                        }
                      }}
                    >
                      {item.label}
                    </div>
                    {
                      // if the last item is raised, add a separator
                      index === raisedItems.length - 1 && (
                        <div className={css.Separator}/>
                      )
                    }
                  </>
                ))}
            {items
              .filter(
                (item) => !raisedItems || !raisedItems.includes(item.value)
              )
              .map((item, index) => (
                <div
                  key={`${index}_${item.value}`}
                  className={
                    value.includes(item.value) ? css.ActiveItem : css.Item
                  }
                  onClick={() => {
                    if (value.includes(item.value)) {
                      onSelect(value.filter((it) => it !== item.value));
                    } else {
                      onSelect([...value, item.value]);
                    }
                  }}
                >
                  {item.label}
                </div>
              ))}
          </div>
        </CSSTransition>
      </div>
      {description !== undefined && (
        <div className={error ? css.DescriptionError : css.Description}>
          {description}
        </div>
      )}
    </div>
  );
};
