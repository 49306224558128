import css from "./warning.module.scss";
import cn from "classnames";
import { RiErrorWarningFill } from "react-icons/ri";
import { X } from "react-feather";

type WarningProps = {
  children?: React.ReactNode;
  className?: string;
  onClose?: () => void;
  style?: React.CSSProperties;
};

export const Warning = ({
  children,
  className,
  onClose,
  style,
}: WarningProps) => {
  return (
    <div className={cn(css.Warning, className)} style={style}>
      <RiErrorWarningFill
        fill="rgba(251, 188, 5, 1)"
        style={{
          width: 40,
          height: 40,
        }}
      />
      <div className={css.Content}>{children}</div>
      <X className={css.Close} onClick={onClose} />
    </div>
  );
};
