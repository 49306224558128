import Head from "next/head";
import React from "react";
import cx from "classnames";
import css from "./registration-layout.module.scss";
import CabinetButtons from "application/layouts/cabinet-layout/cabinet-buttons";

export const RegistrationLayout: React.FC<{
  type?: "SPOT" | "AGENCY" | "BRAND";
}> = ({ type, children }) => {
  return (
    <>
      <Head>
        <title>Eventica - Добро пожаловать!</title>
        <meta name="description" content="" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div
        className={cx(
          type === "SPOT" && css.RootSpot,
          type === "AGENCY" && css.RootAgency,
          type === "BRAND" && css.RootBrand
        )}
      >
        <header className={css.Header}>
          <div className={css.Logo} />
          <CabinetButtons
            notifications={0}
            name={"ЛК"}
            onNotifications={() => {}}
            onEmails={() => {}}
            onName={() => {}}
          />
        </header>
        <main className={css.Main}>{children}</main>
      </div>
    </>
  );
};
