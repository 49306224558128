import { Card } from "./card";
import { Stepper } from "./stepper";
import {Row, FullRow, HalfRow, FlexibleRow} from "./rows";
import { Root } from "./root";
import { Header } from "./header";
import { Title } from "./title";

const Edit = Object.assign(
  {},
  {
    Card,
    Row,
    FullRow,
    Stepper,
    Root,
    Header,
    Title,
    HalfRow,
    FlexibleRow,
  }
);

export { Edit };
