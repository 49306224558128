import React from "react";
import css from "./switch.module.scss";
import classNames from "classnames";

interface SwitchProps {
  label?: string;
  value: boolean;
  error?: boolean;
  description?: string;
  onChange: (value: boolean) => void;
  loading?: boolean;
}

const Switch = ({
  label,
  value,
  description,
  error,
  onChange,
  loading,
}: SwitchProps) => {
  return (
    <label className={classNames(css.Switch, loading && css.Loading)}>
      <input
        type="checkbox"
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span className={css.Slider}></span>
      {label && <span className={css.Label}>{label}</span>}
      {description !== undefined && (
        <div className={error ? css.DescriptionError : css.DescriptionField}>
          {description}
        </div>
      )}
    </label>
  );
};
Switch.displayName = "Switch";

export default Switch;
