import classNames from "classnames";
import css from "./label.module.scss";

type LabelProps = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  className?: string;
};

export const Label = ({ children, icon, onClick, className }: LabelProps) => {
  return (
    <div className={classNames(css.Label, className)}>
      {icon && <div className={css.Icon}>{icon}</div>}
      <span className={css.Content} onClick={onClick}>
        {children}
      </span>
    </div>
  );
};
