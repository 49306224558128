export enum RouteEnum {
  TARIFFS = "/tariffs",
  COLLABORATIONS = "/collaboration",
  PARTNERSHIP = "/partnership",
  APPLICATIONS = "/applications",
  FREELANCE = "/freelance",
  SIGNIN = "/signin",
  EVENTS = "/events",
  EVENT = "/event",
  SIGNUP = "/signup",
  PROFILE_RESOURCES = "/profile/edit/resources",
  SERVICES = "/services",
  SERVICES_NEW = "/services/new",
}
