import React from "react";
import cx from "classnames";
import css from "./dates-picker-calendar.module.scss";
import { values } from "lodash";

interface DatesPickerCalendarProps {
  type: "range" | "days" | "single";
  month: number;
  year: number;
  dates: Date[][];
  onSelect: (date: Date) => void;
}

const DAYS = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];

const getYearMonth = (date: Date) =>
  date.getFullYear() * 10000 + date.getMonth() * 100 + date.getDate();

export const DatesPickerCalendar = ({
  type,
  month,
  year,
  dates,
  onSelect,
}: DatesPickerCalendarProps) => {
  const nowYearMonth = getYearMonth(new Date());

  const startDate = new Date(`${month + 1}/01/${year}`);
  const monthDate = new Date(`${month + 1}/01/${year}`);

  const endDate = new Date(`${month + 1}/01/${year}`);
  endDate.setMonth(endDate.getMonth() + 1);

  const firstDay = startDate.getDay();
  if (firstDay === 0) {
    startDate.setDate(startDate.getDate() - 6);
  } else if (firstDay !== 1) {
    startDate.setDate(startDate.getDate() + 1 - firstDay);
  }

  const daysDiff = (endDate.getTime() - startDate.getTime()) / 86400000;
  const weeks = Array.from(Array(Math.ceil(daysDiff / 7)).keys());

  return (
    <div className={css.MonthWrapper}>
      <div>
        <div className={css.Days}>
          {DAYS.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
        {weeks.map((week) => {
          return (
            <div key={week} className={css.Week}>
              {DAYS.map(() => {
                const selectedDate = new Date(startDate.getTime());
                let selectedClass: string | null = null;
                const selected =
                  dates.findIndex(
                    (item) =>
                      item[0].getTime() <= startDate.getTime() &&
                      startDate.getTime() <= item[1].getTime()
                  ) !== -1;

                if (selected) {
                  selectedClass = css.Selected;

                  const leftDate = new Date(startDate.getTime());
                  leftDate.setDate(leftDate.getDate() - 1);
                  const rightDate = new Date(startDate.getTime());
                  rightDate.setDate(rightDate.getDate() + 1);

                  const leftSelected =
                    type === "range" &&
                    dates.findIndex(
                      (item) =>
                        item[0].getTime() <= leftDate.getTime() &&
                        leftDate.getTime() <= item[1].getTime()
                    ) !== -1;

                  const rightSelected =
                    type === "range" &&
                    dates.findIndex(
                      (item) =>
                        item[0].getTime() <= rightDate.getTime() &&
                        rightDate.getTime() <= item[1].getTime()
                    ) !== -1;

                  if (!leftSelected && !rightSelected) {
                    selectedClass =
                      startDate.getMonth() === month
                        ? css.SelectedSingle
                        : css.SelectedSingleGrey;
                  } else if (leftSelected && !rightSelected) {
                    selectedClass = css.SelectedLeft;
                  } else if (!leftSelected && rightSelected) {
                    selectedClass = css.SelectedRight;
                  }
                }

                if (type === "days" || type === "single") {
                  const rightDate = new Date(startDate.getTime());
                  rightDate.setDate(rightDate.getDate() + 1);

                  const singleSelected =
                    dates.findIndex((item) => {
                      const a1 = new Date(item[0]);
                      const a2 = new Date(selectedDate);

                      return (
                        a1.getDate() === a2.getDate() &&
                        a1.getUTCMonth() + 1 === a2.getUTCMonth() + 1 &&
                        a1.getUTCFullYear() === a2.getUTCFullYear()
                      );
                    }) !== -1;

                  if (singleSelected) {
                    selectedClass =
                      startDate.getMonth() === month
                        ? css.SelectedSingle
                        : css.SelectedSingleGrey;
                  }
                }

                const node = (
                  <div
                    key={startDate.getTime()}
                    className={cx(
                      startDate.getMonth() === month ? css.Day : css.DayGrey,
                      nowYearMonth === getYearMonth(startDate) && css.Today,
                      selectedClass
                    )}
                    onClick={() => onSelect(selectedDate)}
                    data-date={selectedDate.getDate()}
                  >
                    {startDate.getDate()}
                  </div>
                );

                startDate.setDate(startDate.getDate() + 1);

                return node;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
