import React from "react";
import { Button } from "ui";
import { Dialog } from "ui";
import css from "./confirm-dialog.module.scss";

interface ConfirmDialogProps {
  open: boolean;
  header?: string;
  ok?: string;
  cancel?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  header,
  ok,
  cancel,
  onConfirm,
  onCancel,
  children,
}) => (
  <Dialog open={open} header={header} onClose={onCancel}>
    <div className={css.Description}>{children}</div>
    <div className={css.Buttons}>
      <div className={css.Button}>
        <Button type="button" onClick={onConfirm}>
          {ok || "OK"}
        </Button>
      </div>
      <div className={css.Button}>
        <Button type="button" onClick={onCancel} color="secondary">
          {cancel || "Отменить"}
        </Button>
      </div>
    </div>
  </Dialog>
);
