import React from "react";
import css from "./hidden.module.scss";
import cn from "classnames";

interface HiddenProps {
  className?: string;
  mobile?: boolean;
  tablet?: boolean;
  down?: "mobile" | "tablet" | "desktop";
  up?: "mobile" | "tablet" | "desktop";
  element?: "div" | "span" | "section";
  style?: React.CSSProperties;
  responsive?: boolean;
}

const Hidden: React.FC<HiddenProps> = ({
  className,
  mobile,
  tablet,
  children,
  up,
  down,
  element = "div",
  style,
  responsive = false,
}) => {
  const classNames: string[] = [];

  if (className) {
    classNames.push(className);
  }

  if (mobile) {
    classNames.push(css.Mobile);
  }

  if (tablet) {
    classNames.push(css.Tablet);
  }

  if (up === "mobile") {
    classNames.push(css.MobileUp);
  }

  if (up === "tablet") {
    classNames.push(css.TabletUp);
  }

  if (up === "desktop") {
    classNames.push(css.DesktopUp);
  }

  if (down === "mobile") {
    classNames.push(css.MobileDown);
  }

  if (down === "tablet") {
    classNames.push(css.TabletDown);
  }

  if (down === "desktop") {
    classNames.push(css.DesktopDown);
  }

  const Element = element;

  return (
    <Element
      className={cn(classNames, {
        [css.Responsive]: responsive,
      })}
      style={style}
    >
      {children}
    </Element>
  );
};

export default Hidden;
