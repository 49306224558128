import css from "./text.module.scss";
import cn from "classnames";
import { CSSProperties } from "react";

type TextProps = {
  children?: React.ReactNode;
  color?: "primary" | "secondary";
  /**
   * @default "regular"
   * regular - 400
   * medium - 500
   * semibold - 600
   * bold - 700
   */
  weight?: CSSProperties["fontWeight"];
};

export const Text = ({
  children,
  color = "primary",
  weight = "normal",
}: TextProps) => {
  return (
    <span className={cn(css.Text, css[color])} style={{ fontWeight: weight }}>
      {children}
    </span>
  );
};
