import { useHostname } from "application/hooks/useHostname";
import classNames from "classnames";
import { useRouter } from "next/router";
import { CSSProperties } from "react";
import { Edit, X } from "react-feather";
import css from "./card.module.scss";

/* eslint-disable @next/next/no-img-element */
export function Card({
  item: { title, description, image },
  onRemove,
  onEdit,
  style,
  onClick,
}: {
  item: {
    title: string;
    description: string;
    image?: any;
  };
  onRemove?: () => void;
  onEdit?: () => void;
  style?: CSSProperties;
  onClick?: () => void;
}) {
  const hostname = useHostname();

  return (
    <div className={css.Root} style={style} onClick={onClick}>
      {image && (
        <img
          src={
            typeof image === "string"
              ? process.env.NODE_ENV === "development"
                ? `http://localhost:8000/${image}`
                : hostname + "/" + image
              : URL.createObjectURL(image)
          }
          alt={title}
        />
      )}

      <div className={css.Title}>{title}</div>
      <div
        className={classNames(css.Description, {
          [css.DescriptionLong]: !image,
        })}
      >
        {description}
      </div>
      {(onRemove || onEdit) && (
        <div className={css.Actions}>
          {onEdit && (
            <div className={css.Edit}>
              <Edit width={16} stroke="#8A9299" onClick={onEdit} />
            </div>
          )}
          {onRemove && (
            <div className={css.Remove} onClick={onRemove}>
              <X width={16} stroke="#8A9299" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
