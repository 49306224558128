import classNames from "classnames";
import css from "./text.module.scss";

export function Text({
  children,
  color = "grey-0",
}: {
  children?: React.ReactNode;
  /**
   * @default "grey-0"
   * "grey-0" - #6C7278
   */
  color?: "grey-0";
}) {
  return (
    <span
      className={classNames(css.Text, {
        [css[color]]: !!color,
      })}
    >
      {children}
    </span>
  );
}
