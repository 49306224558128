import css from "./styles.module.scss";

export function Root({
  header,
  children,
  right,
}: {
  header?: React.ReactNode;
  children?: React.ReactNode;
  right?: React.ReactNode;
}) {
  return (
    <div className={css.Root}>
      <div className={css.Header}>{header}</div>
      <div className={css.Content}>{children}</div>
      <div className={css.Right}>{right}</div>
    </div>
  );
}
