import React, { useEffect, useRef, useState } from "react";
import css from "./tags-input.module.scss";
import cn from "classnames";
import Color from "color";
import { CSSTransition } from "react-transition-group";
import list from "../multiple-select/multiple-select.module.scss";
import classNames from "classnames";

export type TagsInputTagType = { value: string; label: string };

interface TagsInputProps {
  before?: React.ReactNode;
  values?: string[];
  tags: TagsInputTagType[];
  onChange?: (values: string[]) => void;
  placeholder?: string;
  error?: boolean;
  description?: string;
  disableAdd?: boolean;
  max?: number;
  emptyText?: string;
  maxText?: string;
  onAdd?: (value: string) => void;
  color?: string;
  classNameList?: string;
  /**
   * Если true, то вместо тегов будет список
   */
  isList?: boolean;
  raisedItems?: string[];
  /**
   * Если true, то список будет всегда развернут
   */
  expanded?: boolean;
  /**
   * Функция для рендеринга тегов
   */
  tagRenderer?: (tag: string, value: string) => React.ReactNode;
  disabledInput?: boolean;
  /**
   * Если true, то empty text не будет показываться
   */
  disableEmpty?: boolean;
  /**
   * Текст для кнопки добавления тега
   */
  addTagText?: string;
  /**
   *
   * Функция для определения, показывать ли кнопку добавления тега
   */
  showAddButton?: (value: string) => boolean;
  /**
   * Если true, то компонент будет показывать только теги, без возможности добавления и удаления
   */
  onlyShow?: boolean;
  /**
   * Функция для определения, показывать ли список
   */
  showList?: (value: string) => boolean;
  /**
   *  Показывать тэги в одну строку, прятать остальные, включать совместно с отключением инпута, чтобы поле не растягивалось в высоту
   */
  showOneLine?: boolean;
  disabled?: boolean;

  /**
   * Длина символов для проверки в сворачивание "Еще"
   */
  lowLength?: number;
  /**
   * Ограничить высоту списка 4 строками
   */
  heightLimit?: boolean;
}

export const TagsInput: React.FC<TagsInputProps> = ({
  before,
  values = [],
  tags: defaultTags,
  placeholder,
  error,
  description,
  onChange,
  disableAdd = false,
  max,
  emptyText = "",
  maxText,
  onAdd,
  color = "#ffefe5",
  isList = false,
  raisedItems,
  expanded = false,
  tagRenderer,
  disabledInput = false,
  disableEmpty,
  addTagText = "Добавить тег",
  showAddButton = (value) => value.length > 0,
  onlyShow = false,
  showList,
  showOneLine = false,
  disabled = false,
  lowLength = 50,
  heightLimit = false,
  classNameList = "",
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [inputText, setInputText] = useState("");
  const [active, setActive] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [visibleResults, setVisibleResults] = useState(expanded ? 999 : 2);

  const [summaryWidth, setSummaryWidth] = useState(0);

  const [newTags, setNewTags] = useState<{ value: string; label: string }[]>(
    []
  );

  const tags = React.useMemo(() => {
    return [...defaultTags, ...newTags];
  }, [defaultTags, newTags]);

  const results = React.useMemo(() => {
    return tags.filter(
      (item) =>
        !values.includes(item.value) &&
        item.label.toLowerCase().includes(inputText.toLowerCase())
    );
  }, [tags, values, inputText]);

  useEffect(() => {
    setInputVisible(active);

    if (!active) {
      return;
    }

    const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setActive(false);
        setInputText("");
      }
    };

    window.addEventListener("click", clickHandler as any, true);
    return () => {
      document.removeEventListener("click", clickHandler as any);
    };
  }, [active]);

  const staticVisibleValue = React.useRef<number>(0);

  useEffect(() => {
    let width = wrapperRef.current?.clientWidth;

    if (!width) {
      return;
    }

    let visiblleItems = 0;

    const PADDING_INPUT = 52;
    const PADDING_BLOCK = 20;
    const PADDING_TICK = 24;
    const SYMBOL_WIDTH = 6.940714;

    width -= PADDING_INPUT;

    for (const value of values) {
      const item = tags.find((item) => value === item.value);

      if (!item) {
        continue;
      }

      const itemWidth =
        PADDING_BLOCK + PADDING_TICK + SYMBOL_WIDTH * item.label.length;

      if (width - itemWidth < -20) {
        break;
      }

      width -= itemWidth;
      visiblleItems++;
    }

    if (!expanded) {
      if (width < -20) {
        setVisibleResults(visiblleItems - 1);
      } else {
        setVisibleResults(visiblleItems);
      }

      staticVisibleValue.current = visiblleItems;
    }
  }, [wrapperRef.current, values, tags]);

  React.useLayoutEffect(() => {
    const tagsInput = document.querySelector("#tags-input-id");

    if (!tagsInput) return;

    const resizeObserver = new ResizeObserver((entry) => {
      const moreElement = document.querySelector(".more-item");
      if (entry[0].contentRect.height > 44 && moreElement) {
        setVisibleResults(staticVisibleValue.current - 1);
      }
    });

    resizeObserver.observe(tagsInput);

    return () => {
      resizeObserver.unobserve(tagsInput);
    };
  }, []);

  React.useEffect(() => {
    const inputList = document.querySelector<HTMLElement>(
      "#tags-input-list-id"
    );
    const input = document.querySelector<HTMLElement>("#tags-input-id");

    if (input && inputList) {
      const inputRect = input.getBoundingClientRect();
      const inputListHeight = inputList.offsetHeight;
      const scrollHeight = document.body.scrollHeight;
      const pixelsTillBottom =
        scrollHeight - (inputRect.bottom + window.pageYOffset);

      if (pixelsTillBottom <= inputListHeight + 10) {
        if (heightLimit && values.length >= 4) {
          inputList.style.top = "-190px";
        } else {
          inputList.style.top =
            "-" + (inputListHeight + inputRect.height / 5) + "px";
        }
      }
    }
  }, [active]);

  React.useEffect(() => {
    const height = wrapperRef.current?.clientHeight;

    if (active) return;

    if (!height) return;

    if (height > 42) {
      console.log("HERE");
      setVisibleResults(staticVisibleValue.current - 1);
    }
  }, [wrapperRef.current, active]);

  return (
    <div
      className={classNames(css.Root, {
        [css.OnlyShow]: onlyShow,
      })}
      id="tags-input-id"
      ref={ref}
    >
      <div
        ref={wrapperRef}
        className={cn(
          active ? css.ActiveInputWrapper : css.InputWrapper,
          error && css.Error,
          disabled ? css.disabled : ""
        )}
        onClick={() => {
          if (!disabled) {
            if (active) {
              setInputVisible(true);
            }
            setActive(true);
          }
        }}
        id="tags-input-id"
      >
        {before && <div className={css.Before}>{before}</div>}
        {!active && placeholder && values.length === 0 && (
          <div className={cn(css.Placeholder, disabled ? css.disabled : "")}>
            {placeholder}
          </div>
        )}

        {values
          .slice(
            0,
            showOneLine
              ? visibleResults
              : active
              ? values.length
              : visibleResults
          )
          .map((item) => (
            <div
              className={css.SelectedTag}
              style={{
                ...(color && {
                  backgroundColor: color,
                }),
              }}
              key={item}
            >
              {tags.find((tag) => tag.value === item)?.label || item}
              <div
                className={css.Close}
                onClick={() => {
                  onChange?.(values.filter((val) => val !== item));

                  if (!tags.find((tag) => tag.value === item)) {
                    setNewTags([...newTags, { value: item, label: item }]);
                  }
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.5 3.5L3.5 10.5"
                    stroke={Color(color).darken(0.2).hex()}
                    strokeWidth="1.225"
                    strokeLinecap="square"
                  />
                  <path
                    d="M3.5 3.5L10.5 10.5"
                    stroke={Color(color).darken(0.2).hex()}
                    strokeWidth="1.225"
                    strokeLinecap="square"
                  />
                </svg>
              </div>
            </div>
          ))}

        {(showOneLine
          ? values.length > visibleResults
          : !active && values.length > visibleResults) && (
          <div
            className={`${css.Item} ${css.Item_More} more-item`}
            style={{
              ...(color && {
                backgroundColor: color,
              }),
            }}
          >
            Еще {values.length - visibleResults}
          </div>
        )}

        {!disabledInput &&
          active &&
          inputVisible &&
          ((max && values.length < max) || !max) && (
            <input
              ref={inputRef}
              className={css.Input}
              value={inputText}
              onChange={(e) => setInputText(e.currentTarget.value)}
              onFocus={() => setActive(true)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && inputText.length > 0) {
                  const tag = tags.find((item) => item.label === inputText);
                  if (tag) {
                    onChange?.([...values, tag.value]);
                  } else {
                    setNewTags([
                      ...newTags,
                      {
                        value: inputText,
                        label: inputText,
                      },
                    ]);
                    onChange?.([...values, inputText]);
                  }
                  setInputText("");
                }
              }}
              autoFocus
            />
          )}
      </div>
      {!isList && active && (
        <div className={css.Wrapper}>
          <div className={css.List}>
            {max && values.length >= max && (
              <div className={css.MaxTags}>
                {maxText || "Вы выбрали максимальное кол-во тегов"}
              </div>
            )}
            {((max && values.length < max) || !max) &&
              results.map((item) => (
                <div
                  className={css.Item}
                  key={item.value}
                  onClick={() => {
                    setInputText("");
                    setInputVisible(false);
                    onChange?.([...values, item.value]);
                  }}
                >
                  {item.label}
                </div>
              ))}
            {!(max && values.length >= max) &&
              results.length === 0 &&
              emptyText &&
              !disableEmpty && <div className={css.MaxTags}>{emptyText}</div>}
            {!disableAdd && results.length === 0 && !inputText && (
              <div className={css.MaxTags}>Введите новый тег</div>
            )}
            {showAddButton?.(inputText) &&
              inputText &&
              !disableAdd &&
              ((max && values.length < max) || !max) &&
              results.length === 0 && (
                <div
                  className={css.AddTag}
                  onClick={() => {
                    if (onAdd) {
                      onAdd(inputText);
                    } else {
                      setInputText("");
                      setNewTags([
                        ...newTags,
                        {
                          value: inputText,
                          label: inputText,
                        },
                      ]);
                      onChange?.([...values, inputText]);
                    }
                  }}
                >
                  + {addTagText} «{inputText}»
                </div>
              )}
          </div>
        </div>
      )}
      {isList && (
        <CSSTransition
          in={showList ? showList(inputText) : active}
          timeout={150}
          classNames={{
            enter: "",
            enterActive: "",
            exit: "",
            exitActive: "",
          }}
          unmountOnExit
        >
          <div
            className={cn(
              heightLimit
                ? `${list.ListLimit} ${classNameList}`
                : `${list.List} ${classNameList}`
            )}
            id="tags-input-list-id"
          >
            {max && values.length >= max ? (
              <div className={list.Item}>
                {maxText || "Вы выбрали максимальное кол-во тегов"}
              </div>
            ) : (
              <>
                {tags.filter((item) =>
                  item.label.toLowerCase().includes(inputText.toLowerCase())
                ).length === 0 &&
                  emptyText &&
                  !disableEmpty && (
                    <div
                      className={list.Item}
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      {emptyText}
                    </div>
                  )}
                {raisedItems &&
                  raisedItems.length > 0 &&
                  tags
                    .filter((item) =>
                      item.label.toLowerCase().includes(inputText.toLowerCase())
                    )
                    .filter((item) => raisedItems.includes(item.value))
                    .sort(
                      (a, b) =>
                        // sort by raised items
                        raisedItems.indexOf(a.value) -
                        raisedItems.indexOf(b.value)
                    )
                    .map((item, index) => (
                      <>
                        <div
                          key={`${index}_${item.value}`}
                          className={
                            values.includes(item.value)
                              ? list.ActiveItem
                              : list.Item
                          }
                          onClick={() => {
                            if (values.includes(item.value)) {
                              onChange?.(
                                values.filter((it) => it !== item.value)
                              );
                            } else {
                              onChange?.([...values, item.value]);
                            }
                            setInputText("");
                          }}
                        >
                          {tagRenderer?.(item.label, inputText) ?? item.label}
                        </div>
                        {
                          // if the last item is raised, add a separator
                          index === raisedItems.length - 1 && (
                            <div className={list.Separator} />
                          )
                        }
                      </>
                    ))}
                {tags
                  .filter((item) =>
                    item.label.toLowerCase().includes(inputText.toLowerCase())
                  )
                  .filter(
                    (item) => !raisedItems || !raisedItems.includes(item.value)
                  )
                  .map((item, index) => (
                    <div
                      key={`${index}_${item.value}`}
                      className={
                        values.includes(item.value)
                          ? list.ActiveItem
                          : list.Item
                      }
                      onClick={() => {
                        if (values.includes(item.value)) {
                          onChange?.(values.filter((it) => it !== item.value));
                        } else {
                          onChange?.([...values, item.value]);
                        }
                        setInputText("");
                      }}
                    >
                      {tagRenderer?.(item.label, inputText) ?? item.label}
                    </div>
                  ))}
              </>
            )}
            {((showAddButton?.(inputText) && !disableAdd) ||
              (inputText &&
                !disableAdd &&
                ((max && values.length < max) || !max) &&
                results.length === 0)) && (
              <div className={list.Item}>
                <div
                  className={css.AddTag}
                  onClick={() => {
                    if (onAdd) {
                      onAdd(inputText);
                    } else {
                      setInputText("");
                      setNewTags([
                        ...newTags,
                        {
                          value: inputText,
                          label: inputText,
                        },
                      ]);
                      onChange?.([...values, inputText]);
                    }
                  }}
                >
                  + {addTagText} «{inputText}»
                </div>
              </div>
            )}
          </div>
        </CSSTransition>
      )}
      {description !== undefined && (
        <div className={error ? css.DescriptionError : css.Description}>
          {description}
        </div>
      )}
    </div>
  );
};
