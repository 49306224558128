import css from "./dropdown.module.scss";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";

export const DropdownItem = (
  props: React.ComponentProps<typeof DropdownMenu.Item>
) => {
  return (
    <DropdownMenu.Item
      {...props}
      className={classNames(css.item, props.className)}
    >
      {props.children}
    </DropdownMenu.Item>
  );
};

export const DropdownContent = (
  props: React.ComponentProps<typeof DropdownMenu.Content>
) => {
  return (
    <DropdownMenu.Content className={css.content} sideOffset={25} {...props}>
      {props.children}
    </DropdownMenu.Content>
  );
};

export const DropdownArrow = (
  props: React.ComponentProps<typeof DropdownMenu.Arrow>
) => {
  return <ContentRect className={css.contentRect} {...props} />;
};

const ContentRect = (props: React.ComponentPropsWithoutRef<"svg">) => (
  <svg
    width="55"
    height="20"
    viewBox="0 0 55 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_dd_4998_150621)">
      <path d="M28 0L44 20H12L28 0Z" fill="white" />
      <path
        d="M28.3904 -0.312348L28 -0.800391L27.6096 -0.312348L11.6096 19.6877L10.9597 20.5H12H44H45.0403L44.3904 19.6877L28.3904 -0.312348Z"
        stroke="#E1E3E6"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_4998_150621"
        x="0.919922"
        y="-7.60078"
        width="54.1602"
        height="40.6008"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.254167 0 0 0 0 0.211806 0 0 0 0 0.127083 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4998_150621"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.5 0 0 0 0 0.5 0 0 0 0 0.5 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_4998_150621"
          result="effect2_dropShadow_4998_150621"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_4998_150621"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
