import React from "react";
import css from "./button-switch.module.scss";

interface ButtonSwitchProps<T> {
  items: { value: T; label: string }[];
  value: T;
  onChange: (value: T) => void;
  description?: string;
  error?: boolean;
}

type ButtonSwitchFC = <T>(
  props: ButtonSwitchProps<T>
) => React.ReactElement<ButtonSwitchProps<T>>;

export const ButtonSwitch: ButtonSwitchFC = ({
  value,
  items,
  description,
  error,
  onChange,
}) => {
  return (
    <div>
      <div className={css.Root}>
        {items.map((item, index) => (
          <div
            key={index}
            onClick={() => onChange(item.value)}
            className={item.value === value ? css.ItemActive : css.Item}
          >
            {item.label}
          </div>
        ))}
      </div>
      {description !== undefined && (
        <div className={error ? css.DescriptionError : css.Description}>
          {description}
        </div>
      )}
    </div>
  );
};
