import React from "react";
import css from "./spinner.module.scss";

export const Spinner = ({}: {}) => {
  return (
    <div className={css.Root}>
      <div className={css.Spinner} />
    </div>
  );
};
