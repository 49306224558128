import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import css from "./dropzone.module.scss";

interface DropzoneProps {
  onDrop: (files: File[]) => void;
  multiple?: boolean;
  accept?: string;
  preview?: boolean;
  error?: boolean;
  description?: string;
  responsive?: boolean;
}

export const Dropzone: React.FC<DropzoneProps> = ({
  onDrop,
  multiple,
  accept,
  preview = false,
  error,
  description,
  responsive,
  children,
}) => {
  const [files, setFiles] = useState<string[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => URL.createObjectURL(file)));
      onDrop(acceptedFiles);
    },
    multiple,
    accept,
  });

  return (
    <>
      <div
        {...getRootProps({
          className: responsive ? css.DropZoneResponsive : css.DropZone,
          style:
            preview && files.length > 0
              ? {
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundImage: `url(${files[0]})`,
                }
              : undefined,
        })}
      >
        <input {...getInputProps()} />
      </div>
      {children && <div className={css.Description}>{children}</div>}
      {description !== undefined && (
        <div className={error ? css.DescriptionError : css.DescriptionField}>
          {description}
        </div>
      )}
    </>
  );
};
