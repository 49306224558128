import React, { useEffect, useState } from "react";
import css from "./stepper.module.scss";
import { useLocalStorage } from "react-use";
import { useRouter } from "next/router";

interface StepperProps {
  active?: boolean;
  step: number;
  allSteps: (boolean | null)[];
  steps: string[];
  setStep: (step: number) => void;
}

export const Stepper = ({
  step,
  allSteps,
  steps: stepLabels,
  setStep,
  active,
}: StepperProps) => {
  const [stepLS, saveStepLS] = useLocalStorage<string | null>("step", null);
  const [steps, setSteps] = useState(allSteps);
  const router = useRouter();

  useEffect(() => {
    setSteps(allSteps);
  }, [allSteps]);

  return (
    <div className={css.Root}>
      {stepLabels.map((item, index) => {
        let stepClassName = css.Step;
        if (step === index) {
          if (steps[index] === true) {
            stepClassName = css.PreviousActiveStep;
          } else {
            stepClassName =
              css.ActiveStep + " " + (!active ? css.NoHoverActive : "");
          }
        } else if (steps[index] !== null) {
          if (steps[index] === true) {
            stepClassName =
              css.AvailableStep + " " + (!active ? css.NoHoverNoActive : "");
          } else {
            stepClassName = css.AvailableNotFilledStep;
          }
        } else if (step === index) {
          stepClassName = css.LastStep;
        }

        return (
          <div
            key={`step_${index}`}
            className={`${stepClassName}`}
            onClick={() => {
              if (steps[index] !== null && active) {
                const step = `${index}`;

                saveStepLS(step);
                setStep(index);
                router.query.step = `${+step + 1}`;
                router.push(router);
              }
            }}
          >
            <div className={css.Circle}>
              <div className={css.CircleInner}>{index + 1}</div>
            </div>
            <div className={css.Label}>{item}</div>
          </div>
        );
      })}
    </div>
  );
};
