import css from "./subtitle.module.scss";
import cn from "classnames";

type SubtitleProps = {
  children?: React.ReactNode;
  size?: "small" | "large";
  secondary?: boolean;
};

export const Subtitle = ({ children, size = "small", secondary }: SubtitleProps) => {
  return (
    <span className={cn(css.Subtitle, size === "large" && css.Large, secondary && css.Secondary)}>
      {children}
    </span>
  );
};
