import css from "./styles.module.scss";
import clsx from "clsx";
import { useEffect } from "react";

type StepperProps<T extends string> = {
  steps: {
    title: T;
    onClick: () => void;
  }[];
  currentStep: T;
  unFilledSteps: T[];
  partiallyFilledSteps: T[];
  filledSteps: T[];
  style?: React.CSSProperties;
};

export function Stepper<T extends string>({
  steps,
  currentStep,
  unFilledSteps,
  partiallyFilledSteps,
  filledSteps,
  style,
}: StepperProps<T>) {
  return (
    <div className={css.Stepper} style={style}>
      {steps.map((step) => {
        const isUnfilled = unFilledSteps?.includes(step.title);
        const isPartiallyFilled = partiallyFilledSteps?.includes(step.title);
        const isFilled = filledSteps?.includes(step.title);
        const isCurrent = currentStep === step.title;

        return (
          <div className={css.Step} key={step.title} onClick={step.onClick}>
            <div
              className={clsx(
                css.StepCircle,
                isPartiallyFilled && css.StepCirclePartial,
                isFilled && css.StepCircleFilled
              )}
            >
              {isCurrent && <div className={css.StepCircleCurrent} />}
            </div>
            <div className={css.StepTitle}>{step.title}</div>
          </div>
        );
      })}
    </div>
  );
}
