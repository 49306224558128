import Link from "next/link";
import React from "react";
import css from "./link-button.module.scss";

interface LinkProps {
  href: string;
  icon?: React.ReactNode;
}

export const LinkButton: React.FC<LinkProps> = ({ href, icon, children }) => (
  <Link href={href} passHref>
    <a className={css.Root}>
      {icon && <span className={css.Icon}>{icon}</span>}
      {children}
    </a>
  </Link>
);
