import React from "react";
import css from "./checkbox.module.scss";

interface CheckboxProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  label?: React.ReactNode;
  error?: boolean;
}

export const Checkbox = ({
  value,
  label,
  disabled = false,
  onChange,
  error,
}: CheckboxProps) => {
  return (
    <label className={css.Root}>
      <input
        className={css.Input}
        type="checkbox"
        checked={value}
        onChange={(e) => onChange(e.currentTarget.checked)}
        disabled={disabled}
      />
      <div className={error ? css.CheckboxError : css.Checkbox} />
      <div className={error ? css.LableError : css.Lable}>{label}</div>
    </label>
  );
};
