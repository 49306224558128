import { DropdownItem, DropdownContent, DropdownArrow } from "./dropdown";

const Dropdown = Object.assign(
  {},
  {
    Item: DropdownItem,
    Content: DropdownContent,
    Arrow: DropdownArrow,
  }
);

export { Dropdown };
