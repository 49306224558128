import React from "react";
import {Input, InputProps} from "./input";

export interface PhoneInputProps extends Omit<InputProps, "type" | "mask"> {
}

export const PhoneInput: React.FC<PhoneInputProps> = (props) => {
  return (
    <Input
      mask="+7 999 999 99 99"
      onPaste={(e) => {
        e.preventDefault();
        let value = e.clipboardData.getData("text").replace(/\D/g, "");
        if (value[0] === "7") {
          value = value.substring(1);
        }

        props.onChange?.(value);
      }}
      {...props}
    />
  );
};
