import css from "./tag.module.scss";
import cn from "classnames";

type TagProps = {
  children?: React.ReactNode;
  color?: string;
  skew?: boolean;
  size?: "small" | "large";
};

export const Tag = ({ children, color, skew, size = "small" }: TagProps) => {
  return (
    <div
      className={cn(css.tag, size === "large" && css.Large)}
      style={{
        ...(color && { backgroundColor: color }),
        ...(skew && {
          transform: "skew(-15deg)",
          borderBottomLeftRadius: 2,
          borderTopRightRadius: 2,
        }),
      }}
    >
      {skew ? (
        <div
          style={{
            transform: "skew(15deg)",
          }}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export const TagGrid = ({
  children,
  className,
  style,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <div className={cn(css.Grid, className)} style={style}>
      {children}
    </div>
  );
};
