import React, { forwardRef, PropsWithChildren } from "react";
import cx from "classnames";
import css from "./button.module.scss";
import {LockIcon} from "ui/button/LockIcon";

export type ButtonProps = {
  className?: string;
  icon?: React.ReactNode;
  color?:
    | "primary"
    | "primary-2"
    | "primary-3"
    | "secondary"
    | "secondary-2"
    | "secondary-3"
    | "secondary-4"
    | "secondary-5"
    | "green"
    | "orange"
    | "orange-2"
    | "orange-3";
  size?: "large" | "medium" | "small";
  outlined?: boolean;
  responsive?: boolean;
  disabled?: boolean;
  locked?: boolean;
  lockLeft?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>; //() => void;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      icon = null,
      color = "primary",
      size = "medium",
      type = "submit",
      outlined = false,
      responsive = false,
      disabled = false,
      locked = false,
      lockLeft = false,
      onClick,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        {...props}
        ref={ref}
        className={cx(
          css.Button,
          locked && css.Locked,
          color === "primary" && css.PrimaryButton,
          color === "primary-2" && css.Primary2Button,
          color === "primary-3" && css.Primary3Button,
          color === "secondary" && css.SecondaryButton,
          color === "secondary-2" && css.Secondary2Button,
          color === "secondary-3" && css.Secondary3Button,
          color === "secondary-4" && css.Secondary4Button,
          color === "secondary-5" && css.Secondary5Button,
          color === "green" && css.GreenButton,
          color === "orange" && css.OrangeButton,
          color === "orange-2" && css.Orange2Button,
          color === "orange-3" && css.Orange3Button,
          size === "large" && css.LargeButton,
          size === "medium" && css.MediumButton,
          size === "small" && css.SmallButton,
          outlined && css.OutlinedButton,
          responsive && css.ResponsiveButton,
          className
        )}
        onClick={onClick}
        type={type}
        disabled={disabled || locked}
      >
        {icon && <div className={css.Icon}>{icon}</div>}
        {children}
        {locked && <div className={`${css.LockedIconWrap} ${lockLeft && css.LockedIconWrapLeft}`}>
          <LockIcon/>
        </div>}
      </button>
    );
  }
);

Button.displayName = "Button";
