import css from "./footer.module.scss";

type FooterProps = {
  background?: string
}

export const Footer = ({background = 'inherit'}: FooterProps) => {
  return (
    <div style={{background}}>
      <div className={css.Footer}>
        <div className={css.Block}>
          <a href="http://eventica.ru/privacy" target="_blank">
            Условия использования
          </a>
          <a href="http://eventica.ru/license" target="_blank">
            Политика конфиденциальности
          </a>
        </div>
      </div>
    </div>
  );
};
