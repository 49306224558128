import css from "./description.module.scss";
import cn from "classnames";

type DescriptionProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Description = ({ children, className }: DescriptionProps) => {
  return <span className={cn(css.Description, className)}>{children}</span>;
};
