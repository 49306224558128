import React, { useEffect, useRef, useState } from "react";
import { Input } from "../inputs";
import { InputProps } from "../inputs/input";
import css from "./suggestion-input.module.scss";

interface SuggestionInputProps extends Omit<InputProps, "onSelect"> {
  suggestions: { value: null | string; label: string | React.ReactNode }[];
  onSelect?: (value: string | null) => void;
  onFocus?: () => void;
}

export const SuggestionInput: React.FC<SuggestionInputProps> = ({
  suggestions,
  onSelect,
  onFocus,
  ...inputProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const [initialValue, setInitialValue] = useState("");

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
        // onSelect?.(initialValue);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handler);
    }

    return () => document.removeEventListener("click", handler);
  }, [isOpen]);

  return (
    <div className={css.Root} ref={ref}>
      <Input
        {...inputProps}
        onFocus={() => {
          setOpen(true);
          setInitialValue(inputProps.value as string);
          onFocus && onFocus();
        }}
      />
      {isOpen && (
        <div className={css.List}>
          {suggestions.map((item, index) => (
            <div
              key={index}
              className={css.Item}
              onClick={(e) => {
                e.stopPropagation();
                onSelect?.(item.value);
                setOpen(false);
              }}
            >
              {item.label}
            </div>
          ))}
          {suggestions.length === 0 && !inputProps.value && (
            <div className={css.ItemHelp}>Начните вводить адрес</div>
          )}
          {suggestions.length === 0 && !!inputProps.value && (
            <div className={css.ItemHelp}>Адрес не найден</div>
          )}
        </div>
      )}
    </div>
  );
};
