import React, { ReactNode, useEffect } from "react";
import { Button } from "ui";
import { ButtonProps } from "ui/button/button";
import { useModalStore } from "../../../store/modal";
import css from "./dialog.module.scss";
import { useRouter } from "next/router";

export interface DialogProps {
  open?: boolean;
  header?: ReactNode;
  dialogStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  closeStyle?: React.CSSProperties;
  onClose?: () => void;
}

export const Dialog: React.FC<DialogProps> = ({
  open,
  header,
  dialogStyle,
  headerStyle,
  closeStyle,
  onClose,
  children,
}) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }

    return () => {
      document.body.classList.remove("body-overflow");
    };
  }, [open]);

  const router = useRouter();
  const modal = useModalStore();

  useEffect(() => {
    if (open) {
      modal.closeModal();
    }
  }, [router.pathname]);

  if (open === false) {
    return null;
  }

  return (
    <div className={css.Root}>
      <div className={css.Dialog} style={dialogStyle}>
        {header && (
          <div className={css.Header} style={headerStyle}>
            {header}
            {onClose && <div className={css.Close} style={closeStyle} onClick={onClose} />}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export function DialogActions({
  onSave,
  saveButtonProps,
  disableCancel,
  responsive,
}: {
  onSave?: () => void;
  saveButtonProps?: ButtonProps;
  disableCancel?: boolean;
  responsive?: boolean;
}) {
  const modal = useModalStore();

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: disableCancel ? "1fr" : "1fr 1fr",
        gap: "12px",
        width: responsive ? "100%" : 250,
      }}
    >
      {!disableCancel && (
        <Button responsive color="secondary" onClick={modal.onClose}>
          Отмена
        </Button>
      )}
      <Button
        {...saveButtonProps}
        responsive
        onClick={() => {
          onSave?.();
        }}
      >
        <span
          style={{
            fontWeight: 400,
          }}
        >
          {saveButtonProps?.children || "Сохранить"}
        </span>
      </Button>
    </div>
  );
}
