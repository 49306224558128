import css from "./picture.module.scss";
import { RiImageAddLine } from "react-icons/ri";
import cn from "classnames";
import { ReactNode } from "react";
import { X } from "react-feather";

type PictureProps = {
  photo?: string;
  size?: "small" | "large";
  hiddenBorder?: boolean;
  className?: string;
  children?: ReactNode;
  hasCloseButton?: boolean;
  onClose?: () => void;
  onError?: () => void;
};

export const Picture = ({
  photo,
  size = "small",
  hiddenBorder = false,
  className,
  children,
  hasCloseButton = false,
  onClose,
  onError,
}: PictureProps) => {
  return (
    <div
      className={cn(
        css.container,
        css[size],
        hiddenBorder && css.hiddenBorder,
        className
      )}
    >
      {photo && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className={css.picture}
          src={photo}
          // alt="picture"
          onError={onError}
        />
      )}
      {!photo && (
        <div className={css.iconContainer}>
          <RiImageAddLine className={css.icon} />
        </div>
      )}
      {children}
      {hasCloseButton && (
        <div
          style={{
            borderRadius: "100%",
            background: "#F5F6F7",
            position: "absolute",
            top: 8,
            right: 8,
            width: 20,
            height: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <X stroke={"rgba(138, 146, 153, 1)"} width={16} />
        </div>
      )}
    </div>
  );
};

function PictureGrid({ children }: { children: ReactNode }) {
  return <div className={css.Grid}>{children}</div>;
}

Picture.Grid = PictureGrid;
