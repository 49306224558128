import classNames from "classnames";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Camera } from "react-feather";
import css from "./droparea.module.scss";

type DropareaProps = {
  onDrop: (acceptedFiles: File[]) => void;
  maxFiles?: number;
  description?: string;
  className?: string;
};

function Droparea({ onDrop, maxFiles, description, className }: DropareaProps) {
  const onDropCallback = useCallback(onDrop, [onDrop]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropCallback,
    maxFiles,
  });

  return (
    <div>
      <div {...getRootProps()} className={classNames(css.Droparea, className)}>
        <input {...getInputProps()} />
        <div className={css.Empty}>
          {/* <Camera stroke="#3DC5FF" /> */}
          <ImageIcon />
          <p style={{ marginTop: "17px" }}>
            Кликните или перетащите изображение в область
          </p>
        </div>
      </div>
      {description && (
        <div className={css.Description}>
          <p>{description}</p>
        </div>
      )}
    </div>
  );
}

function ImageIcon() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5007 25V30H40.5007V33.3333H35.5007V38.3333H32.1673V33.3333H27.1673V30H32.1673V25H35.5007ZM35.514 5C36.4273 5 37.1673 5.74167 37.1673 6.655V21.6667H33.834V8.33333H7.16732V31.665L23.834 15L28.834 20V24.715L23.834 19.715L11.879 31.6667H23.834V35H5.48732C5.04867 34.9996 4.62815 34.825 4.31813 34.5147C4.00812 34.2043 3.83398 33.7836 3.83398 33.345V6.655C3.83703 6.2173 4.01215 5.79836 4.32151 5.4887C4.63086 5.17903 5.04962 5.00349 5.48732 5H35.514ZM13.834 11.6667C14.718 11.6667 15.5659 12.0179 16.191 12.643C16.8161 13.2681 17.1673 14.1159 17.1673 15C17.1673 15.8841 16.8161 16.7319 16.191 17.357C15.5659 17.9821 14.718 18.3333 13.834 18.3333C12.9499 18.3333 12.1021 17.9821 11.477 17.357C10.8518 16.7319 10.5007 15.8841 10.5007 15C10.5007 14.1159 10.8518 13.2681 11.477 12.643C12.1021 12.0179 12.9499 11.6667 13.834 11.6667Z"
        fill="#3DC5FF"
      />
    </svg>
  );
}

export { Droparea };
