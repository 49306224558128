import React, {useEffect, useRef} from "react";
import * as DefaultCropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {useGetImageByUrlLazyQuery} from "generated/types";

type Props = {
  image: Blob | string;
  onCropChange: (blob: Blob) => void;
  ratio?: number;
  className?: string;
};
const Cropper: React.FC<Props> = ({
                                    image,
                                    onCropChange,
                                    // ratio = 1280 / 478,
                                    ratio = 1280 / 459,
                                    className,
                                  }) => {
  const [getImageByUrl] = useGetImageByUrlLazyQuery();
  const getFile = async (value: string) => {
    const data = await fetch(value);
    const blob = await data.blob();

    if (blob.type.indexOf("image") === -1) {
      throw new Error("Not an image");
    }

    return blob;
  };

  const cropperRef = useRef<DefaultCropper.ReactCropperElement>(null);

  const [src, setSrc] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    async function loadImage() {
      if (typeof image === "string") {
        const loadedImage = await getImageByUrl({
          variables: {url: image},
        });
        if (!loadedImage.data?.getImageByUrl) return;

        setSrc(loadedImage.data.getImageByUrl);
      } else {
        setSrc(URL.createObjectURL(image));
      }
    }

    loadImage();
  }, [image]);

  return (
    <DefaultCropper.Cropper
      className={className}
      ref={cropperRef}
      style={{height: "100%", minHeight: 224, width: "100%"}}
      aspectRatio={ratio}
      initialAspectRatio={1}
      src={src}
      viewMode={1}
      zoomTo={0}
      zoomable={true}
      minCropBoxHeight={100}
      minCropBoxWidth={100}
      background={false}
      responsive={true}
      checkCrossOrigin={false}
      autoCropArea={1}
      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
      guides={true}
      ready={async () => {
        const imageElement = cropperRef.current;
        const cropper = imageElement?.cropper;



        if (!cropper) return;

        if (!cropper.getCroppedCanvas()) return;

        //cropper?.setCropBoxData({top: 0, left: 0, width: 500, height: 500})

        const file = await getFile(cropper.getCroppedCanvas().toDataURL());

        onCropChange(file);
      }}
      cropend={async () => {
        const imageElement = cropperRef.current;
        const cropper = imageElement?.cropper;

        if (!cropper) return;

        if (!cropper.getCroppedCanvas()) return;

        const file = await getFile(cropper.getCroppedCanvas().toDataURL());

        onCropChange(file);
      }}
    />
  );
};

export {Cropper};
