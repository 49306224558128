import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import cx from "classnames";
import css from "./select.module.scss";
import invariant from "ts-invariant";
import log = invariant.log;
import { useWindowSize } from "react-use";

interface SelectProps<T> {
  items: { value: T; label: string }[];
  value?: T;
  colorless?: boolean;
  placeholder?: string;
  error?: boolean;
  description?: string;
  fullHeight?: boolean;
  limitHeight?: boolean;
  onSelect?: (value: T) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  emptyLabel?: string;
  className?: string;
  listItemsHeight?: number;
  listItemWordWrap?: boolean;
  inputLimit?: number;
}

type SelectFC = <T>(
  props: SelectProps<T>
) => React.ReactElement<SelectProps<T>>;

export const Select: SelectFC = ({
  items,
  value,
  placeholder,
  error,
  description,
  fullHeight = false,
  limitHeight = false,
  colorless = false,
  onSelect,
  disabled = false,
  style,
  emptyLabel,
  className,
  listItemsHeight,
  listItemWordWrap,
  inputLimit,
}) => {
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  /*   Detect select list intersection with window bottom border
   */
  const [scroll, setScroll] = useState(0);
  const onScroll = useCallback(() => setScroll(Math.round(window.scrollY)), []);
  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  const selectRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize();
  const [intersection, setIntersection] = useState(false);

  const [clientHeight, setClientHeight] = useState(0);

  useEffect(() => {
    const htmlRef = document?.querySelector("html");
    htmlRef && setClientHeight(htmlRef?.clientHeight);
  }, []);

  useEffect(() => {
    const bottom = selectRef?.current?.getBoundingClientRect()?.bottom;
    const listHeight = 179;

    const isMobile = width < 768;
    const mobileMenuHeight = isMobile ? 72 : 0;

    bottom && bottom + listHeight + mobileMenuHeight >= clientHeight
      ? !intersection && setIntersection(true)
      : intersection && setIntersection(false);
  }, [scroll, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("click", clickHandler as any, true);
    return () => {
      document.removeEventListener("click", clickHandler as any);
    };
  }, [isOpen]);

  const foundLabel = items?.find((item) => item?.value === value)?.label;
  const inputValue =
    inputLimit && foundLabel && foundLabel.length >= inputLimit
      ? foundLabel?.slice(0, inputLimit) + "..."
      : foundLabel;

  return (
    <div className={cx(css.Root, className)} style={style} ref={wrapperRef}>
      <div>
        <div
          className={cx(
            isOpen ? css.OpenSelect : css.Select,
            error && css.ErrorSelect,
            colorless && css.Colorless,
            disabled && css.Disabled
          )}
          onClick={() => {
            if (!disabled) {
              setOpen(!isOpen);
            }
          }}
          ref={selectRef}
        >
          {inputValue ||
            (placeholder ? (
              <span className={css.Placeholder}>{placeholder}</span>
            ) : (
              ""
            ))}
        </div>

        <CSSTransition
          in={isOpen}
          timeout={150}
          classNames={{
            enter: css.ListEnter,
            enterActive: css.ListEnterActive,
            // exit: css.ListExit,
            // exitActive: css.ListExitActive,
            exit: "",
            exitActive: "",
          }}
          unmountOnExit
        >
          <div
            className={
              fullHeight
                ? css.ListFull
                : limitHeight
                ? !intersection
                  ? css.ListLimit
                  : css.ListLimitUp
                : css.List
            }
            style={listItemsHeight ? { height: `${listItemsHeight}px` } : {}}
          >
            {items.length === 0 && (
              <div className={css.Item}>{emptyLabel ?? "Нет данных"}</div>
            )}
            {items.map((item, index) => (
              <div
                key={`${index}_${item?.value}`}
                className={item?.value === value ? css.ActiveItem : css.Item}
                style={
                  listItemWordWrap
                    ? {
                        whiteSpace: "unset",
                        wordWrap: "break-word",
                      }
                    : undefined
                }
                onClick={() => {
                  onSelect?.(item?.value);
                  setOpen(false);
                }}
              >
                {item?.label}
              </div>
            ))}
          </div>
        </CSSTransition>
      </div>
      {description !== undefined && (
        <div className={error ? css.DescriptionError : css.Description}>
          {description}
        </div>
      )}
    </div>
  );
};
