import React, {InputHTMLAttributes} from "react";
import cx from "classnames";
import InputMask from "react-input-mask";
import {SuccessIcon} from "ui/icons";
import css from "./input.module.scss";
import classNames from "classnames";

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  success?: boolean;
  error?: boolean | string;
  description?: string;
  mask?: string;
  prefix?: string;
  onChange?: (value: string, e?: React.ChangeEvent<HTMLInputElement>) => void;
  rootClassName?: string;
  onIconClick?: () => void;
  /* Флаг для расширения функционала инпута, чтобы не сломать предыдущий функционал
  *  1. добавлено резервирование под error state
  * */
  extended?: boolean
}

export const Input: React.FC<InputProps> = (
  {
    icon,
    iconPosition = "right",
    success,
    error = false,
    mask,
    description,
    onChange,
    onBlur,
    onFocus,
    onIconClick,
    prefix,
    rootClassName,
    extended = false,
    ...props
  }) => {
  let iconNode: React.ReactNode = null;

  if (icon) {
    iconNode = icon;
  } else if (success) {
    iconNode = <SuccessIcon/>;
  }

  return (
    <div className={classNames(css.Root, rootClassName)}>
      <div
        className={cx(
          onIconClick ? css.IconClickable : css.Icon,
          iconPosition === "right" ? css.RightIcon : css.LeftIcon
        )}
        onClick={onIconClick}
      >
        {iconNode}
      </div>
      {mask ? (
        <InputMask
          mask={mask}
          maskPlaceholder="_"
          className={cx(
            error ? css.InputError : css.Input,
            iconNode && iconPosition === "right" && css.RightIconInput,
            iconNode && iconPosition === "left" && css.LeftIconInput
          )}
          onChange={(e) => {
            onChange?.(e.currentTarget.value, e);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          {...props}
        />
      ) : (
        <input
          className={cx(
            error ? css.InputError : css.Input,
            iconNode && iconPosition === "right" && css.RightIconInput,
            iconNode && iconPosition === "left" && css.LeftIconInput
          )}
          onChange={(e) => {
            onChange?.(e.currentTarget.value, e);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          {...props}
        />
      )}
      {!extended && description !== undefined && (
        <div className={error ? css.DescriptionError : css.Description}>
          {description}
        </div>
      )}
      {!extended && typeof error === "string" && (
        <div className={css.DescriptionError}>{error}</div>
      )}

      {extended
        ? !!description
          ? <div className={error ? css.DescriptionError : css.Description}> {description} </div>
          : <div className={css.Description}> &nbsp; </div>
        : null
      }


    </div>
  );
};
