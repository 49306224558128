import React from "react";
import css from "./radio-group.module.scss";

interface RadioGroupProps<T> {
  value: T;
  onChange: (value: T) => void;
  items: { value: T; label: string }[];
  disabled?: boolean;
  error?: boolean;
  description?: string;
}

type RadioGroupFC = <T>(
  props: RadioGroupProps<T>
) => React.ReactElement<RadioGroupProps<T>>;

export const RadioGroup: RadioGroupFC = ({
  value,
  items,
  disabled = false,
  onChange,
  error,
  description,
}) => {
  return (
    <div className={css.Root}>
      {items.map((item, index) => (
        <label key={index} className={css.Label}>
          <input
            className={css.Input}
            type="radio"
            checked={value === item.value}
            onChange={(e) => onChange(item.value)}
            disabled={disabled}
          />
          <div className={css.Radio} />
          <div className={css.LabelText}>{item.label}</div>
        </label>
      ))}

      {description && (
        <div className={error ? css.DescriptionError : css.Description}>
          {description}
        </div>
      )}
    </div>
  );
};
