import { gql } from "@apollo/client";
import { GraphqlErrorType } from "application/graphql/graphql-types";

export interface LogoutAccountMutation {
  logoutAccount:
    | {
        __typename: "LogoutAccount";
      }
    | GraphqlErrorType;
}

export interface LogoutAccountMutationVars {}

const LOGOUT_ACCOUNT = gql`
  mutation LogoutAccount {
    logoutAccount {
      __typename
      ... on LogoutAccountResponse {
        __typename
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default LOGOUT_ACCOUNT;
