import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { RiEyeLine } from "react-icons/ri";
import { Input } from "ui";
import { EyeIcon } from "ui/icons";
import { InputProps } from "ui/inputs/input";

interface PasswordInputProps
  extends Omit<InputProps, "type" | "icon" | "onIconClick"> {}

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  return (
    <Input
      type={isPasswordVisible ? "text" : "password"}
      icon={
        !isPasswordVisible ? (
          <AiOutlineEye
            color="rgba(182, 187, 191, 1)"
            style={{
              width: 18,
              height: 18,
            }}
          />
        ) : (
          <AiOutlineEyeInvisible
            color="rgba(182, 187, 191, 1)"
            style={{
              width: 18,
              height: 18,
            }}
          />
        )
      }
      onIconClick={() => setPasswordVisible(!isPasswordVisible)}
      {...props}
    />
  );
};
