import React, { useRef, useState } from "react";
import css from "./tooltip.module.scss";
import * as DefaultTooltip from "@radix-ui/react-tooltip";
import { useWindowSize } from "react-use";
import { PopperContentProps } from "@radix-ui/react-tooltip";

interface TooltipInterfcae {
  className?: string;
  open?: boolean;
  tooltip: React.ReactNode;
  width?: number;
  children: React.ReactNode;
  side?: "top" | "right" | "bottom" | "left";
  align?: "start" | "center" | "end";
  alignOffset?: number;
}

export const Tooltip: React.FC<TooltipInterfcae> = ({
  className = "",
  tooltip,
  width,
  open: openDefault,
  children,
  side = "top",
  align,
  alignOffset,
}) => {
  const [open, setOpen] = useState(openDefault);
  const windowWidth = useWindowSize().width;
  const isMobile = windowWidth < 768;
  const container = useRef<HTMLDivElement | null>(null);

  return (
    <DefaultTooltip.Provider delayDuration={0}>
      <DefaultTooltip.Root
        defaultOpen={openDefault}
        open={open}
        onOpenChange={(open) => {
          if (!isMobile) {
            setOpen(open);
          }
        }}
      >
        <DefaultTooltip.Trigger
          asChild
          onClick={() => {
            if (isMobile) {
              if (!open) {
                setOpen(true);
              }
            }
          }}
        >
          <div className={className}>{children}</div>
        </DefaultTooltip.Trigger>
        <DefaultTooltip.Content
          side={side ? side : isMobile ? "left" : undefined}
          sideOffset={5}
          align={align ?? "center"}
          alignOffset={alignOffset ?? 0}
          onPointerDownOutside={() => {
            if (isMobile) {
              if (open) {
                setOpen(false);
              }
            }
          }}
          className={css.Tooltip}
        >
          {tooltip}
          <DefaultTooltip.Arrow fill="#45494D" className={css.Arrow} />
        </DefaultTooltip.Content>
      </DefaultTooltip.Root>
    </DefaultTooltip.Provider>
  );
};
