import {Title} from "./title";
import {Subtitle} from "./subtitle";
import {Text} from "./text";
import {Label} from "./label";
import {Description} from "./description";
import {Warning} from "./warning";
import {Edit} from "./edit";
import {Progress} from "./progress";
import {Droparea} from "./droparea";
import {ColorPicker} from "./color-picker";
import {Card} from "./card";
import {Footer} from "./footer";
import {Model} from "./model"

const Profile = Object.assign(
  {},
  {
    Title,
    Text,
    Label,
    Subtitle,
    Description,
    Warning,
    Edit,
    Progress,
    Droparea,
    ColorPicker,
    Card,
    Footer,
    Model,
  }
);

export {Profile};
