import s from './Pagination.module.scss'
import {default as ReactPagination} from "react-js-pagination";

type Props = {
  defaultPage: number,
  totalPage: number,
  pageRange: number
  onPageChange: (pageNumber: number) => void,
}

const PrevPageIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29 20L23 26L29 32" stroke="#222426" strokeWidth="1.4" strokeLinecap="square"/>
    </svg>
  )
};

const NextPageIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23 20L29 26L23 32" stroke="#222426" strokeWidth="1.4" strokeLinecap="square"/>
    </svg>
  )
}

export function Pagination(
  {
    defaultPage,
    totalPage,
    pageRange,
    onPageChange
  }: Props) {

  return (
    <div className={s.Pagination}>
      <ReactPagination
        activePage={defaultPage}
        itemsCountPerPage={1}
        totalItemsCount={totalPage}
        pageRangeDisplayed={pageRange}
        onChange={onPageChange}
        firstPageText={''}
        lastPageText={''}
        prevPageText={<PrevPageIcon/>}
        nextPageText={<NextPageIcon/>}
      />
    </div>

  )
}