import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import css from "./progress.module.scss";
import Color from "color";
import classNames from "classnames";

function Progress({
  value = 0,
  label,
  color = "#FFCA2A",
  size = "medium",
}: {
  value?: number;
  label?: string;
  color?: string;
  size?: "small" | "medium";
}) {
  const rgba = Color(color)
    .alpha(value / 100)
    .rgb()
    .string();

  return (
    <div
      className={classNames(css.Root, {
        [css.RootSmall]: size === "small",
      })}
    >
      <CircularProgressbar
        value={value}
        className={css.Progress}
        styles={{
          path: {
            // Path color
            stroke: rgba,
            strokeWidth: 6,
          },
          trail: {
            // Trail color
            stroke: "#D9D9D9",
            strokeWidth: 6,
          },
          text: {
            // Text color
            fill: "#45494C",
            // Text size
            fontSize: 32,
            fontWeight: 500,
            transform: "translate(0, 5px)",
          },
        }}
        text={`${value}%`}
      />
      {label && <div className={css.Label}>{label}</div>}
    </div>
  );
}

export { Progress };
