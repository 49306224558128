import Head from "next/head";
import Link from "next/link";
import React from "react";
import Visible from "ui/visible";
import css from "./signup-layout.module.scss";

export const SignupLayout: React.FC = ({ children }) => {
  return (
    <>
      <Head>
        <title>Eventica - Вход</title>
        <meta name="description" content="" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={css.Root}>
        <div className={css.Background}>
          <div className={css.Border}>
            <Link href={"/"} passHref>
              <div className={css.Logo} />
            </Link>

            <div className={css.Children}>
              <div className={css.Text}>
                <div>
                  {/* <div className={css.Label}>
                    КОЛЛАБОРАЦИИ
                    <br />
                    НАЧИНАЮТСЯ ЗДЕСЬ
                  </div>
                  <div className={css.Description}>
                    Сервис, объединяющий
                    <br />
                    бренды и площадки
                  </div> */}
                  <div className={css.Label}>
                    ПЛАТФОРМА
                    <br />
                    ПАРТНЕРСКОГО МАРКЕТИНГА
                  </div>
                  <div className={css.Description}>
                    Больше правильных партнеров, 
                    <br />
                    меньше затрат
                  </div>
                </div>
              </div>

              <Visible desktop>
                <div className={css.Panel}>{children}</div>
              </Visible>
            </div>
          </div>
        </div>
        <Visible className={css.Panel} mobile tablet>
          {children}
        </Visible>
      </div>
    </>
  );
};
