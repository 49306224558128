import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import css from "./avatar-upload.module.scss";

interface AvatarUpload {
  preview?: string;
  onChange: (file: File | null) => void;
}

export const AvatarUpload = ({
  preview: previewDefault,
  onChange,
}: AvatarUpload) => {
  const [preview, setPreview] = useState<string | null>(previewDefault || null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      onChange(acceptedFiles[0] || null);
      setPreview(URL.createObjectURL(acceptedFiles[0]));
    },
    accept: "image/jpeg, image/png",
  });

  return (
    <div {...getRootProps({ className: css.Root })}>
      <input {...getInputProps()} />
      <div
        className={css.Avatar}
        style={{
          backgroundImage: `url("${preview}")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <div className={css.Text}>Загрузить изображение</div>
    </div>
  );
};
