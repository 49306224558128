import classNames from "classnames";
import css from "./trapezoid.module.scss";

export const Trapezoid = ({
  color = "#E1E9F0",
  type = "small",
}: {
  color?: string;
  type?: "small" | "large";
}) => {
  return (
    <div className={css.container}>
      <div
        className={classNames({
          [css.trapezoid]: type === "small",
          [css.large]: type === "large",
        })}
        style={
          type === "small"
            ? {
                borderBottom: `8rem solid ${color}`,
              }
            : {
                backgroundColor: color,
              }
        }
      ></div>
    </div>
  );
};
