import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AddEventStep1 = {
  __typename?: "AddEventStep1";
  event: ClientEventStep1;
};

export type AddEventStep1Input = {
  address?: InputMaybe<Scalars["String"]>;
  area?: InputMaybe<Scalars["String"]>;
  category: Scalars["ID"];
  city?: InputMaybe<Scalars["ID"]>;
  dates: Array<Array<Scalars["String"]>>;
  isLocationUnknown?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  offline: Scalars["Boolean"];
  online: Scalars["Boolean"];
  themes?: InputMaybe<Array<Scalars["String"]>>;
  times?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  website?: InputMaybe<Scalars["String"]>;
};

export type AddEventStep1Response = AddEventStep1 | Error;

export type AddEventStep2 = {
  __typename?: "AddEventStep2";
  event: ClientEventStep2;
};

export type AddEventStep2Input = {
  crop?: InputMaybe<CropData>;
  fullDescription: Scalars["String"];
  image: Scalars["String"];
  shortDescription: Scalars["String"];
  sourceImage?: InputMaybe<Scalars["String"]>;
};

export type AddEventStep2Response = AddEventStep2 | Error;

export type AddEventStep3 = {
  __typename?: "AddEventStep3";
  event?: Maybe<Scalars["ID"]>;
};

export type AddEventStep3Input = {
  ageStatements?: InputMaybe<AgeInput>;
  eventInterest?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<Scalars["ID"]>>>>
  >;
  free?: InputMaybe<Scalars["Boolean"]>;
  genderStatements?: InputMaybe<GenderInput>;
  model?: InputMaybe<ModelInput>;
  peopleAmountFrom?: InputMaybe<Scalars["String"]>;
  peopleAmountTo?: InputMaybe<Scalars["String"]>;
  priceFrom?: InputMaybe<Scalars["String"]>;
  priceTo?: InputMaybe<Scalars["String"]>;
};

export type AddEventStep3Response = AddEventStep3 | Error;

export type AddEventStep4 = {
  __typename?: "AddEventStep4";
  event?: Maybe<Scalars["ID"]>;
};

export type AddEventStep4ConditionInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
};

export type AddEventStep4Input = {
  addPackages: Scalars["Boolean"];
  allCategories: Scalars["Boolean"];
  draft?: InputMaybe<Scalars["Boolean"]>;
  packages: Array<AddEventStep4PacakgesInput>;
  sponsorCategories: Array<Scalars["ID"]>;
};

export type AddEventStep4PacakgesInput = {
  barter: Scalars["Boolean"];
  conditions: Array<AddEventStep4ConditionInput>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  price: Scalars["String"];
};

export type AddEventStep4Response = AddEventStep4 | Error;

export type AddEventWithoutSteps = {
  __typename?: "AddEventWithoutSteps";
  event?: Maybe<ClientEventWithoutSteps>;
};

export type AddEventWithoutStepsConditionInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
};

export type AddEventWithoutStepsInput = {
  addPackages?: InputMaybe<Scalars["Boolean"]>;
  address?: InputMaybe<Scalars["String"]>;
  ageStatements?: InputMaybe<AgeInput>;
  allCategories?: InputMaybe<Scalars["Boolean"]>;
  area?: InputMaybe<Scalars["String"]>;
  category: Scalars["ID"];
  city?: InputMaybe<Scalars["ID"]>;
  crop?: InputMaybe<CropData>;
  dates: Array<InputMaybe<Array<InputMaybe<Scalars["String"]>>>>;
  eventInterest?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  free?: InputMaybe<Scalars["Boolean"]>;
  fullDescription: Scalars["String"];
  genderStatements?: InputMaybe<GenderInput>;
  image?: InputMaybe<Scalars["String"]>;
  isLocationUnknown?: InputMaybe<Scalars["Boolean"]>;
  model?: InputMaybe<ModelInput>;
  name: Scalars["String"];
  offline: Scalars["Boolean"];
  online: Scalars["Boolean"];
  packages?: InputMaybe<Array<AddEventWithoutStepsPacakgesInput>>;
  peopleAmountFrom?: InputMaybe<Scalars["String"]>;
  peopleAmountTo?: InputMaybe<Scalars["String"]>;
  priceFrom?: InputMaybe<Scalars["String"]>;
  priceTo?: InputMaybe<Scalars["String"]>;
  shortDescription: Scalars["String"];
  sourceImage?: InputMaybe<Scalars["String"]>;
  sponsorCategories?: InputMaybe<Array<Scalars["ID"]>>;
  themes?: InputMaybe<Array<Scalars["String"]>>;
  times?: InputMaybe<Array<Scalars["String"]>>;
  website?: InputMaybe<Scalars["String"]>;
};

export type AddEventWithoutStepsPacakgesInput = {
  barter: Scalars["Boolean"];
  conditions: Array<AddEventWithoutStepsConditionInput>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  price: Scalars["String"];
};

export type AddEventWithoutStepsResponse = AddEventWithoutSteps | Error;

export type AddPartner = {
  __typename?: "AddPartner";
  partner: Scalars["Boolean"];
};

export type AddPartnerInput = {
  image?: InputMaybe<Scalars["Upload"]>;
  imageUrl?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type AddPartnerResponse = AddPartner | Error;

export type AgeFilterInput = {
  age00_18?: InputMaybe<Scalars["Boolean"]>;
  age18_24?: InputMaybe<Scalars["Boolean"]>;
  age25_34?: InputMaybe<Scalars["Boolean"]>;
  age35_44?: InputMaybe<Scalars["Boolean"]>;
  age45_54?: InputMaybe<Scalars["Boolean"]>;
  age55_00?: InputMaybe<Scalars["Boolean"]>;
};

export type AgeInput = {
  age00_18?: InputMaybe<Scalars["Boolean"]>;
  age18_24?: InputMaybe<Scalars["Boolean"]>;
  age25_34?: InputMaybe<Scalars["Boolean"]>;
  age35_44?: InputMaybe<Scalars["Boolean"]>;
  age45_54?: InputMaybe<Scalars["Boolean"]>;
  age55_00?: InputMaybe<Scalars["Boolean"]>;
};

export type AgencyClient = {
  __typename?: "AgencyClient";
  cityId: Scalars["ID"];
  clients: Scalars["String"];
  description: Scalars["String"];
  image: Scalars["String"];
  name: Scalars["String"];
};

export type ApproveApplicationResponse = ClientApplication | Error;

export type ApproveApplicationVars = {
  forWho: ApproveSideEnum;
  id: Scalars["Int"];
  recipientId: Scalars["Int"];
  senderId: Scalars["Int"];
};

export enum ApproveSideEnum {
  ApprovedByRecipient = "approvedByRecipient",
  ApprovedBySender = "approvedBySender",
}

export type Audience = {
  __typename?: "Audience";
  ageSegments: Scalars["String"];
  clientId: Scalars["ID"];
  gender: Scalars["String"];
  interests?: Maybe<Array<Interests>>;
};

export type Brand = {
  __typename?: "Brand";
  id: Scalars["ID"];
  src: Scalars["String"];
};

export type BrandClient = {
  __typename?: "BrandClient";
  brand: Scalars["String"];
  categoryId: Scalars["ID"];
  cityId: Scalars["ID"];
  description: Scalars["String"];
  image: Scalars["String"];
  name: Scalars["String"];
  targetAudienceId: Scalars["ID"];
};

export type Case = {
  __typename?: "Case";
  id: Scalars["ID"];
  name: Scalars["String"];
  src: Scalars["String"];
  text: Scalars["String"];
};

export type ChangePassword = {
  __typename?: "ChangePassword";
  client: Client;
};

export type ChangePasswordInput = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type ChangePasswordResponse = ChangePassword | Error;

export type Channel = {
  __typename?: "Channel";
  audience?: Maybe<Scalars["Int"]>;
  clientId?: Maybe<Scalars["ID"]>;
  createdAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  type: ChannelType;
  updatedAt: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type ChannelResource = {
  __typename?: "ChannelResource";
  audience: Scalars["Int"];
  channelId: Scalars["ID"];
  name: Scalars["String"];
};

export type ChannelResourceInput = {
  audience?: InputMaybe<Scalars["Int"]>;
  channelId?: InputMaybe<Scalars["ID"]>;
  link?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export enum ChannelType {
  Custom = "custom",
  Social = "social",
}

export type CheckApplication = {
  __typename?: "CheckApplication";
  checked?: Maybe<Scalars["Boolean"]>;
};

export type CheckApplicationResponse = CheckApplication | Error;

export type CheckFreeAddress = {
  __typename?: "CheckFreeAddress";
  free: Scalars["Boolean"];
};

export type CheckFreeAddressInput = {
  address: Scalars["String"];
};

export type CheckFreeAddressResponse = CheckFreeAddress | Error;

export type CityEnum = {
  __typename?: "CityEnum";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Client = {
  __typename?: "Client";
  active?: Maybe<ClientStatus>;
  audience?: Maybe<Audience>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  email: Scalars["String"];
  firstName?: Maybe<Scalars["String"]>;
  firstRegistration: Scalars["Boolean"];
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
  profile?: Maybe<Profile>;
  published: Scalars["Boolean"];
  tariff?: Maybe<Tariff>;
  type: ClientTypeEnum;
};

export enum ClientAppRequestEnum {
  Incoming = "INCOMING",
  Outgoing = "OUTGOING",
}

export enum ClientAppStatusEnum {
  Approved = "APPROVED",
  Created = "CREATED",
  Deal = "DEAL",
  NoDeal = "NO_DEAL",
  Read = "READ",
  Reject = "REJECT",
}

export type ClientApplication = {
  __typename?: "ClientApplication";
  approvedByRecipient: Scalars["Boolean"];
  approvedBySender: Scalars["Boolean"];
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  recipient: ClientEntity;
  recipientId: Scalars["Int"];
  sender: ClientEntity;
  senderId: Scalars["Int"];
  status: ClientAppStatusEnum;
  text: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type ClientApplicationEntity = {
  __typename?: "ClientApplicationEntity";
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  newByRecipient: Scalars["Boolean"];
  newBySender: Scalars["Boolean"];
  recipient: ClientEntity;
  sender: ClientEntity;
  status: ClientAppStatusEnum;
  updatedAt: Scalars["String"];
};

export type ClientApplicationInput = {
  recipientId: Scalars["Int"];
  text: Scalars["String"];
};

export type ClientAudience = {
  __typename?: "ClientAudience";
  ageSegments: Scalars["String"];
  clientId: Scalars["ID"];
  gender: Scalars["String"];
  interests: Array<ClientInterest>;
};

export type ClientCategory = {
  __typename?: "ClientCategory";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ClientCategoryEnum = {
  __typename?: "ClientCategoryEnum";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ClientCity = {
  __typename?: "ClientCity";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ClientDeleteEvent = {
  __typename?: "ClientDeleteEvent";
  name?: Maybe<Scalars["String"]>;
};

export type ClientEntity = {
  __typename?: "ClientEntity";
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["ID"];
  lastName: Scalars["String"];
  phone: Scalars["String"];
  position: Scalars["String"];
  profile: ClientProfileEntity;
};

export type ClientEvent = {
  __typename?: "ClientEvent";
  address?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  categoryId?: Maybe<Scalars["ID"]>;
  city?: Maybe<Scalars["String"]>;
  cityId?: Maybe<Scalars["ID"]>;
  crop?: Maybe<CropDataType>;
  dates: Array<Array<Scalars["String"]>>;
  favorite?: Maybe<Scalars["Boolean"]>;
  free?: Maybe<Scalars["Boolean"]>;
  fullDescription?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  isLocationUnknown?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  online?: Maybe<Scalars["Boolean"]>;
  peopleAmountFrom?: Maybe<Scalars["Int"]>;
  peopleAmountTo?: Maybe<Scalars["Int"]>;
  priceFrom?: Maybe<Scalars["Float"]>;
  priceTo?: Maybe<Scalars["Float"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  sourceImage?: Maybe<Scalars["String"]>;
  spot?: Maybe<Scalars["String"]>;
  spotClient?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  targetAudienceId?: Maybe<Scalars["ID"]>;
  themes: Array<Scalars["ID"]>;
  time?: Maybe<Scalars["String"]>;
};

export type ClientEvent1 = {
  __typename?: "ClientEvent1";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ClientEventStep1 = {
  __typename?: "ClientEventStep1";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ClientEventStep2 = {
  __typename?: "ClientEventStep2";
  id?: Maybe<Scalars["ID"]>;
  message?: Maybe<Scalars["String"]>;
};

export type ClientEventStep4 = {
  __typename?: "ClientEventStep4";
  name?: Maybe<Scalars["String"]>;
};

export type ClientEventWithoutSteps = {
  __typename?: "ClientEventWithoutSteps";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ClientInterest = {
  __typename?: "ClientInterest";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ClientLowProfile = {
  __typename?: "ClientLowProfile";
  address?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ClientMakeDraftEvent = {
  __typename?: "ClientMakeDraftEvent";
  name?: Maybe<Scalars["String"]>;
};

export type ClientMakeModerationEvent = {
  __typename?: "ClientMakeModerationEvent";
  name?: Maybe<Scalars["String"]>;
};

export type ClientMakePublicEvent = {
  __typename?: "ClientMakePublicEvent";
  name?: Maybe<Scalars["String"]>;
};

export type ClientPackage = {
  __typename?: "ClientPackage";
  barter?: Maybe<Scalars["Boolean"]>;
  conditions: Array<PackageCondition>;
  id: Scalars["ID"];
  name: Scalars["String"];
  price: Scalars["String"];
};

export type ClientPage = {
  __typename?: "ClientPage";
  items: Array<Maybe<Client>>;
  totalCount: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type ClientPartner = {
  __typename?: "ClientPartner";
  clientId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  logo: Scalars["String"];
  name: Scalars["String"];
};

export type ClientPartnership = {
  __typename?: "ClientPartnership";
  categories: Array<ClientCategory>;
  clientId: Scalars["ID"];
  interested_in_channels: Array<Channel>;
  possibilities_channels: Array<Channel>;
};

export type ClientProfile = {
  __typename?: "ClientProfile";
  address: Scalars["String"];
  categories: Array<ClientCategory>;
  clientId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  logo?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
};

export type ClientProfileEntity = {
  __typename?: "ClientProfileEntity";
  address?: Maybe<Scalars["String"]>;
  logo: Scalars["String"];
  name: Scalars["String"];
};

export type ClientRemoveFromPublication = {
  __typename?: "ClientRemoveFromPublication";
  name?: Maybe<Scalars["String"]>;
};

export enum ClientStatus {
  Active = "ACTIVE",
  Blocked = "BLOCKED",
  Pending = "PENDING",
}

export enum ClientTypeEnum {
  Agency = "AGENCY",
  Brand = "BRAND",
  Spot = "SPOT",
}

export type ClientValueEnum = {
  __typename?: "ClientValueEnum";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ContactLink = {
  __typename?: "ContactLink";
  clientId: Scalars["ID"];
  dictionary: DictContact;
  dictionaryId: Scalars["ID"];
  id: Scalars["ID"];
  link: Scalars["String"];
};

export type CreateApplicationLong = {
  __typename?: "CreateApplicationLong";
  id?: Maybe<Scalars["Int"]>;
};

export type CreateApplicationLongInput = {
  budgetFrom?: InputMaybe<Scalars["String"]>;
  budgetTo?: InputMaybe<Scalars["String"]>;
  companyName?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  projectName?: InputMaybe<Scalars["String"]>;
  telegram?: InputMaybe<Scalars["String"]>;
  term?: InputMaybe<Scalars["DateTime"]>;
  typeId?: InputMaybe<Scalars["Int"]>;
  userName?: InputMaybe<Scalars["String"]>;
};

export type CreateApplicationLongResponse = CreateApplicationLong | Error;

export type CreateApplicationShort = {
  __typename?: "CreateApplicationShort";
  id?: Maybe<Scalars["Int"]>;
};

export type CreateApplicationShortInput = {
  companyName: Scalars["String"];
  email: Scalars["String"];
  phone: Scalars["String"];
  userName: Scalars["String"];
};

export type CreateApplicationShortResponse = CreateApplicationShort | Error;

export type CreateClientApplicationResult = {
  __typename?: "CreateClientApplicationResult";
  message?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type CreateContactLink = {
  __typename?: "CreateContactLink";
  contact: ContactLink;
};

export type CreateContactLinkInput = {
  dictionaryId: Scalars["ID"];
  link: Scalars["String"];
};

export type CreateContactLinkResponse = CreateContactLink | Error;

export type CreateEventApplicationResult = {
  __typename?: "CreateEventApplicationResult";
  message?: Maybe<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type CreateFreelanceJob = {
  __typename?: "CreateFreelanceJob";
  job: FreelanceJob;
};

export type CreateFreelanceJobInput = {
  active?: InputMaybe<Scalars["Boolean"]>;
  category?: InputMaybe<Scalars["ID"]>;
  description: Scalars["String"];
  files: Array<Scalars["Upload"]>;
  finishDate: Scalars["String"];
  name: Scalars["String"];
};

export type CreateFreelanceJobResponse = CreateFreelanceJob | Error;

export type CreateLocation = {
  __typename?: "CreateLocation";
  location: Location;
};

export type CreateLocationInput = {
  address?: InputMaybe<Scalars["String"]>;
  cities: Array<Scalars["ID"]>;
  countries: Array<Scalars["ID"]>;
};

export type CreateLocationResponse = CreateLocation | Error;

export type CreateStyling = {
  __typename?: "CreateStyling";
  styling: Styling;
};

export type CreateStylingInput = {
  color?: InputMaybe<Scalars["String"]>;
  cover?: InputMaybe<Scalars["String"]>;
};

export type CreateStylingResponse = CreateStyling | Error;

export type CropData = {
  height?: InputMaybe<Scalars["Int"]>;
  left?: InputMaybe<Scalars["Int"]>;
  top?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type CropDataType = {
  __typename?: "CropDataType";
  height?: Maybe<Scalars["Int"]>;
  left?: Maybe<Scalars["Int"]>;
  top?: Maybe<Scalars["Int"]>;
  width?: Maybe<Scalars["Int"]>;
};

export type Data = {
  __typename?: "Data";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export enum DateSortEnum {
  Asc = "asc",
  Desc = "desc",
}

export type DeleteContactLink = {
  __typename?: "DeleteContactLink";
  contact: ContactLink;
};

export type DeleteContactLinkInput = {
  id: Scalars["ID"];
};

export type DeleteContactLinkResponse = DeleteContactLink | Error;

export type DeleteEvent = {
  __typename?: "DeleteEvent";
  event?: Maybe<ClientDeleteEvent>;
};

export type DeleteEventResponse = DeleteEvent | Error;

export type DictCity = {
  __typename?: "DictCity";
  countryId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type DictContact = {
  __typename?: "DictContact";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type DictCountry = {
  __typename?: "DictCountry";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type DirectChat = {
  __typename?: "DirectChat";
  date: Scalars["String"];
  id: Scalars["ID"];
  message: Scalars["String"];
  name: Scalars["String"];
};

export type DirectFile = {
  __typename?: "DirectFile";
  id: Scalars["ID"];
  name: Scalars["String"];
  url: Scalars["String"];
};

export type DirectMessage = {
  __typename?: "DirectMessage";
  date: Scalars["String"];
  file?: Maybe<DirectFile>;
  from?: Maybe<DirectMessageFrom>;
  id: Scalars["ID"];
  message: Scalars["String"];
};

export type DirectMessageFrom = {
  __typename?: "DirectMessageFrom";
  id: Scalars["ID"];
  name: Scalars["String"];
  status: Scalars["String"];
};

export type DirectOpponent = {
  __typename?: "DirectOpponent";
  id: Scalars["ID"];
  name: Scalars["String"];
  status: Scalars["String"];
};

export type Error = {
  __typename?: "Error";
  error: Scalars["String"];
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type Event = {
  __typename?: "Event";
  address?: Maybe<Scalars["String"]>;
  ageStatements?: Maybe<Scalars["String"]>;
  area?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  categoryId?: Maybe<Scalars["ID"]>;
  city?: Maybe<Scalars["String"]>;
  cityId?: Maybe<Scalars["ID"]>;
  clientId: Scalars["ID"];
  dates: Array<Array<Scalars["String"]>>;
  expired: Scalars["Boolean"];
  favorite: Scalars["Boolean"];
  free?: Maybe<Scalars["Boolean"]>;
  fullDescription?: Maybe<Scalars["String"]>;
  genderStatements?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  interests?: Maybe<Scalars["String"]>;
  isLocationUnknown?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  offline?: Maybe<Scalars["Boolean"]>;
  online?: Maybe<Scalars["Boolean"]>;
  peopleAmountFrom?: Maybe<Scalars["Int"]>;
  peopleAmountTo?: Maybe<Scalars["Int"]>;
  priceDescription?: Maybe<Scalars["String"]>;
  priceFrom?: Maybe<Scalars["Float"]>;
  priceTo?: Maybe<Scalars["Float"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  spot?: Maybe<Scalars["String"]>;
  spotClient?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  targetAudience?: Maybe<Scalars["ID"]>;
  themes: Array<Scalars["ID"]>;
  time?: Maybe<Array<Maybe<Scalars["String"]>>>;
  typeAudience?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type EventApplication = {
  __typename?: "EventApplication";
  approvedByRecipient: Scalars["Boolean"];
  approvedBySender: Scalars["Boolean"];
  createdAt: Scalars["String"];
  id: Scalars["ID"];
  recipient: ClientEntity;
  recipientId: Scalars["Int"];
  sender: ClientEntity;
  senderId: Scalars["Int"];
  status: ClientAppStatusEnum;
  updatedAt: Scalars["String"];
};

export type EventApplicationInput = {
  eventId: Scalars["Int"];
  eventPackageId?: InputMaybe<Scalars["Int"]>;
  recipientId: Scalars["Int"];
  text?: InputMaybe<Scalars["String"]>;
};

export type EventCategoryEnum = {
  __typename?: "EventCategoryEnum";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type EventInterestsEnum = {
  __typename?: "EventInterestsEnum";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type EventPartner = {
  __typename?: "EventPartner";
  id: Scalars["ID"];
  image: Scalars["String"];
  name: Scalars["String"];
};

export enum EventStatusEnum {
  Active = "ACTIVE",
  Declined = "DECLINED",
  Deleted = "DELETED",
  Draft = "DRAFT",
  Moderating = "MODERATING",
  NotActive = "NOT_ACTIVE",
}

export type EventStep = {
  __typename?: "EventStep";
  filled: Scalars["Boolean"];
  verified: Scalars["Boolean"];
};

export type FreelanceCategory = {
  __typename?: "FreelanceCategory";
  icon: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type FreelanceJob = {
  __typename?: "FreelanceJob";
  active: Scalars["Boolean"];
  category: FreelanceCategory;
  clientId: Scalars["ID"];
  description: Scalars["String"];
  finishDate: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export enum GenderFilterEnum {
  Any = "any",
  Female = "female",
  Male = "male",
}

export type GenderInput = {
  female?: InputMaybe<Scalars["Boolean"]>;
  male?: InputMaybe<Scalars["Boolean"]>;
};

export type GetAgencyProfile = {
  __typename?: "GetAgencyProfile";
  profile: AgencyClient;
};

export type GetBrandProfile = {
  __typename?: "GetBrandProfile";
  profile: BrandClient;
};

export type GetBrands = {
  __typename?: "GetBrands";
  brands?: Maybe<Array<Maybe<Brand>>>;
};

export type GetBrandsResponse = Error | GetBrands;

export type GetCases = {
  __typename?: "GetCases";
  cases?: Maybe<Array<Maybe<Case>>>;
};

export type GetCasesResponse = Error | GetCases;

export type GetChannels = {
  __typename?: "GetChannels";
  channels: Array<Channel>;
};

export type GetChannelsInput = {
  address?: InputMaybe<Scalars["ID"]>;
};

export type GetChannelsResponse = Error | GetChannels;

export type GetCities = {
  __typename?: "GetCities";
  cities: Array<ClientCity>;
};

export type GetCitiesResponse = Error | GetCities | GetDictCities;

export type GetClientApplicationByPkVars = {
  id: Scalars["Int"];
  recipientId: Scalars["Int"];
  senderId: Scalars["Int"];
};

export type GetClientApplicationVars = {
  recipient_id: Scalars["Int"];
  sender_id?: InputMaybe<Scalars["Int"]>;
};

export type GetClientApplicationsByPkResponse = {
  __typename?: "GetClientApplicationsByPkResponse";
  items?: Maybe<Array<Maybe<ClientApplicationEntity>>>;
  totalCount: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type GetClientApplicationsByPkVars = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  request: ClientAppRequestEnum;
  statuses?: InputMaybe<Array<InputMaybe<ClientAppStatusEnum>>>;
};

export type GetClientCategories = {
  __typename?: "GetClientCategories";
  categories: Array<ClientCategory>;
};

export type GetClientCategoriesResponse = Error | GetClientCategories;

export type GetClientChats = {
  __typename?: "GetClientChats";
  chats: Array<DirectChat>;
};

export type GetClientChatsResponse = Error | GetClientChats;

export type GetClientEvents = {
  __typename?: "GetClientEvents";
  events: Array<ClientEvent>;
};

export type GetClientEventsResponse = Error | GetClientEvents;

export type GetClientFreelanceJobs = {
  __typename?: "GetClientFreelanceJobs";
  jobs: Array<FreelanceJob>;
};

export type GetClientFreelanceJobsResponse = Error | GetClientFreelanceJobs;

export type GetClientInterests = {
  __typename?: "GetClientInterests";
  interests: Array<ClientInterest>;
};

export type GetClientInterestsResponse = Error | GetClientInterests;

export type GetClientMessages = {
  __typename?: "GetClientMessages";
  messages: Array<DirectMessage>;
  opponent: DirectOpponent;
};

export type GetClientMessagesResponse = Error | GetClientMessages;

export type GetClientsInput = {
  age?: InputMaybe<AgeFilterInput>;
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gender?: InputMaybe<GenderFilterEnum>;
  interests?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  model?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  random?: InputMaybe<Scalars["Boolean"]>;
  randomSeed?: InputMaybe<Scalars["Int"]>;
  sortBy?: InputMaybe<SortEnum>;
};

export type GetContactDict = {
  __typename?: "GetContactDict";
  contacts: Array<DictContact>;
};

export type GetContactDictResponse = Error | GetContactDict;

export type GetContactLinks = {
  __typename?: "GetContactLinks";
  contacts: Array<ContactLink>;
};

export type GetContactLinksInput = {
  address: Scalars["String"];
};

export type GetContactLinksResponse = Error | GetContactLinks;

export type GetCountries = {
  __typename?: "GetCountries";
  countries: Array<DictCountry>;
};

export type GetCountriesResponse = Error | GetCountries;

export type GetDictCities = {
  __typename?: "GetDictCities";
  cities: Array<DictCity>;
};

export type GetDictCitiesVars = {
  cityId?: InputMaybe<Scalars["ID"]>;
  countryId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type GetEvent = {
  __typename?: "GetEvent";
  event: Event;
  packages: Array<ClientPackage>;
  partners: Array<EventPartner>;
  profile?: Maybe<ClientLowProfile>;
  sponsorCategories: Array<SponsorCategory>;
};

export type GetEventApplicationVars = {
  recipient_id: Scalars["Int"];
  sender_id?: InputMaybe<Scalars["Int"]>;
};

export type GetEventCategories = {
  __typename?: "GetEventCategories";
  categories: Array<EventCategoryEnum>;
};

export type GetEventCategoriesResponse = Error | GetEventCategories;

export type GetEventInterests = {
  __typename?: "GetEventInterests";
  interests: Array<EventInterestsEnum>;
};

export type GetEventInterestsResponse = Error | GetEventInterests;

export type GetEventPackages = {
  __typename?: "GetEventPackages";
  packages: Array<ClientPackage>;
};

export type GetEventPackagesResponse = Error | GetEventPackages;

export type GetEventResponse = Error | GetEvent;

export type GetEventStatusResponse = {
  __typename?: "GetEventStatusResponse";
  status?: Maybe<EventStatusEnum>;
};

export type GetEventSteps = {
  __typename?: "GetEventSteps";
  steps: Array<EventStep>;
};

export type GetEventStepsResponse = Error | GetEventSteps;

export type GetEvents = {
  __typename?: "GetEvents";
  events: Array<Event>;
  totalCount: Scalars["Int"];
  totalPages: Scalars["Int"];
};

export type GetEventsInput = {
  clientId?: InputMaybe<Scalars["ID"]>;
  dateSort?: InputMaybe<DateSortEnum>;
  limit: Scalars["Int"];
  offset?: InputMaybe<Scalars["Int"]>;
};

export type GetEventsResponse = Error | GetEvents;

export type GetFreelanceCategories = {
  __typename?: "GetFreelanceCategories";
  categories: Array<FreelanceCategory>;
};

export type GetFreelanceCategoriesResponse = Error | GetFreelanceCategories;

export type GetIsNewAppCountResponse = {
  __typename?: "GetIsNewAppCountResponse";
  incomingCount: Scalars["Int"];
  outgoingCount: Scalars["Int"];
};

export type GetLocation = {
  __typename?: "GetLocation";
  location?: Maybe<Location>;
};

export type GetLocationResponse = Error | GetLocation;

export type GetMyClient = {
  __typename?: "GetMyClient";
  client?: Maybe<Client>;
};

export type GetMyClientAudience = {
  __typename?: "GetMyClientAudience";
  clientAudience?: Maybe<ClientAudience>;
};

export type GetMyClientAudienceInput = {
  address: Scalars["String"];
};

export type GetMyClientInput = {
  address?: InputMaybe<Scalars["String"]>;
};

export type GetMyClientPartnership = {
  __typename?: "GetMyClientPartnership";
  clientPartnership?: Maybe<ClientPartnership>;
};

export type GetMyClientPartnershipInput = {
  address: Scalars["String"];
};

export type GetMyClientProfile = {
  __typename?: "GetMyClientProfile";
  clientProfile?: Maybe<ClientProfile>;
};

export type GetMyClientProfileInput = {
  address: Scalars["String"];
};

export type GetPartners = {
  __typename?: "GetPartners";
  partners: Array<EventPartner>;
};

export type GetPartnersResponse = Error | GetPartners;

export type GetPeopleAmounts = {
  __typename?: "GetPeopleAmounts";
  categories: Array<PeopleAmountEnum>;
};

export type GetPeopleAmountsResponse = Error | GetPeopleAmounts;

export type GetProfileResponse =
  | Error
  | GetAgencyProfile
  | GetBrandProfile
  | GetSpotProfile;

export type GetSponsorCategories = {
  __typename?: "GetSponsorCategories";
  categories: Array<SponsorCategoryEnum>;
};

export type GetSponsorCategoriesResponse = Error | GetSponsorCategories;

export type GetSpotClient = {
  __typename?: "GetSpotClient";
  client: Client;
  info: SpotClient;
};

export type GetSpotClientResponse = Error | GetSpotClient;

export type GetSpotProfile = {
  __typename?: "GetSpotProfile";
  profile: SpotClient;
};

export type GetStyling = {
  __typename?: "GetStyling";
  styling?: Maybe<Styling>;
};

export type GetStylingResponse = Error | GetStyling;

export type GetStylingVars = {
  address: Scalars["String"];
};

export type GetTargetAudiences = {
  __typename?: "GetTargetAudiences";
  targets: Array<TargetAudience>;
};

export type GetTargetAudiencesResponse = Error | GetTargetAudiences;

export type GetThemeTags = {
  __typename?: "GetThemeTags";
  tags: Array<ThemeTagEnum>;
};

export type GetThemeTagsResponse = Error | GetThemeTags;

export type GetTypes = {
  __typename?: "GetTypes";
  types?: Maybe<Array<Maybe<Type>>>;
};

export type GetTypesResponse = Error | GetTypes;

export type GetUpdateEventDescriptionInfo = {
  __typename?: "GetUpdateEventDescriptionInfo";
  crop?: Maybe<CropDataType>;
  fullDescription?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
  sourceImage?: Maybe<Scalars["String"]>;
};

export type GetUpdateEventDescriptionInfoResponse =
  | Error
  | GetUpdateEventDescriptionInfo;

export type GetUpdateEventInfo = {
  __typename?: "GetUpdateEventInfo";
  categories?: Maybe<Array<Maybe<Data>>>;
  cities?: Maybe<Array<Maybe<Data>>>;
  event?: Maybe<UpdateEventInfo1>;
  tags?: Maybe<Array<Maybe<Data>>>;
};

export type GetUpdateEventInfoResponse = Error | GetUpdateEventInfo;

export type GetUpdateEventSponsorsInfo = {
  __typename?: "GetUpdateEventSponsorsInfo";
  addPackages?: Maybe<Scalars["Boolean"]>;
  allCategories?: Maybe<Scalars["Boolean"]>;
  categories?: Maybe<Array<Maybe<Data>>>;
  packages: Array<Maybe<ClientPackage>>;
  sponsorCategories: Array<Maybe<Scalars["String"]>>;
};

export type GetUpdateEventSponsorsInfoResponse =
  | Error
  | GetUpdateEventSponsorsInfo;

export type GetUpdateTargetsInfo = {
  __typename?: "GetUpdateTargetsInfo";
  interests?: Maybe<Array<Maybe<InterestsData>>>;
  target: UpdateTargetsInfo1;
};

export type GetUpdateTargetsInfoResponse = Error | GetUpdateTargetsInfo;

export type GetWebsiteInfo = {
  __typename?: "GetWebsiteInfo";
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Interests = {
  __typename?: "Interests";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type InterestsData = {
  __typename?: "InterestsData";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type Location = {
  __typename?: "Location";
  address?: Maybe<Scalars["String"]>;
  cities: Array<DictCity>;
  countries: Array<DictCountry>;
  id: Scalars["ID"];
};

export type LocationInput = {
  address: Scalars["String"];
};

export type LogoutAccount = {
  __typename?: "LogoutAccount";
  client: Client;
};

export type LogoutAccountResponse = Error | LogoutAccount;

export type Mailing = {
  __typename?: "Mailing";
  id: Scalars["ID"];
  status: Scalars["Boolean"];
  title: Scalars["String"];
};

export type MailingStatus = {
  __typename?: "MailingStatus";
  status: Scalars["Boolean"];
};

export type MakeDraftEvent = {
  __typename?: "MakeDraftEvent";
  event?: Maybe<ClientMakeDraftEvent>;
};

export type MakeDraftEventResponse = Error | MakeDraftEvent;

export type MakeModerationEvent = {
  __typename?: "MakeModerationEvent";
  event?: Maybe<ClientMakeModerationEvent>;
};

export type MakeModerationEventResponse = Error | MakeModerationEvent;

export type MakePublicEvent = {
  __typename?: "MakePublicEvent";
  event?: Maybe<ClientMakePublicEvent>;
};

export type MakePublicEventResponse = Error | MakePublicEvent;

export type Mutation = {
  __typename?: "Mutation";
  addEventStep1?: Maybe<AddEventStep1Response>;
  addEventStep2?: Maybe<AddEventStep2Response>;
  addEventStep3?: Maybe<AddEventStep3Response>;
  addEventStep4?: Maybe<AddEventStep4Response>;
  addEventWithoutSteps?: Maybe<AddEventWithoutStepsResponse>;
  addPartner?: Maybe<AddPartnerResponse>;
  approveApplication?: Maybe<ApproveApplicationResponse>;
  changePassword?: Maybe<ChangePasswordResponse>;
  checkApplication?: Maybe<CheckApplicationResponse>;
  createApplicationLong?: Maybe<CreateApplicationLongResponse>;
  createApplicationShort?: Maybe<CreateApplicationShortResponse>;
  createClientApplication: CreateClientApplicationResult;
  createContactLink?: Maybe<CreateContactLinkResponse>;
  createEventApplication: CreateEventApplicationResult;
  createFreelanceJob?: Maybe<CreateFreelanceJobResponse>;
  createLocation?: Maybe<CreateLocationResponse>;
  createStyling?: Maybe<CreateStylingResponse>;
  deleteContactLink?: Maybe<DeleteContactLinkResponse>;
  deleteEvent?: Maybe<DeleteEventResponse>;
  logoutAccount?: Maybe<LogoutAccountResponse>;
  makeDraftEvent?: Maybe<MakeDraftEventResponse>;
  makeModerationEvent?: Maybe<MakeModerationEventResponse>;
  makePublicEvent?: Maybe<MakePublicEventResponse>;
  publishClient?: Maybe<PublishClientResponse>;
  refreshToken?: Maybe<RefreshTokenResponse>;
  removeAccount?: Maybe<RemoveAccountResponse>;
  removeFromPublication?: Maybe<RemoveFromPublicationResponse>;
  reportByError?: Maybe<ReportByErrorResponse>;
  reportBySpam?: Maybe<ReportBySpamResponse>;
  requestTariff?: Maybe<RequestTariffResponse>;
  requestTariffProlong?: Maybe<RequestTariffProlongResponse>;
  restoreClientPassword?: Maybe<RestoreClientPasswordResponse>;
  root?: Maybe<Scalars["String"]>;
  sendChatMessage?: Maybe<SendChatMessageResponse>;
  sendMessageToSpot?: Maybe<SendMessageToSpotResponse>;
  setRestoreClientPassword?: Maybe<SetRestoreClientPasswordResponse>;
  setSignupClientPassword?: Maybe<SetSignupClientPasswordResponse>;
  signinClient?: Maybe<SigninClientResponse>;
  signupClient?: Maybe<SignupClientResponse>;
  toggleMailingList?: Maybe<ToggleMailingList>;
  updateApplicationStatusByPk?: Maybe<UpdateApplicationStatusByPkResponse>;
  updateBrandClient?: Maybe<UpdateBrandClientResponse>;
  updateChannelResources: UpdateChannelResourcesResponse;
  updateClientAudience?: Maybe<UpdateClientAudienceResponse>;
  updateClientPartnership?: Maybe<UpdateClientPartnershipResponse>;
  updateClientProfile?: Maybe<UpdateClientProfileResponse>;
  updateContactLink?: Maybe<UpdateContactLinkResponse>;
  updateEvent1?: Maybe<UpdateEvent1Response>;
  updateEvent2?: Maybe<UpdateEvent2Response>;
  updateEvent3?: Maybe<UpdateEvent3Response>;
  updateEvent4?: Maybe<UpdateEvent4Response>;
  updateFirstRegistration?: Maybe<UpdateFirstRegistrationResponse>;
  updateLocation?: Maybe<UpdateLocationResponse>;
  updateStyling?: Maybe<UpdateStylingResponse>;
  uploadFile?: Maybe<UploadFileResponse>;
  verifyStep1SignupClient?: Maybe<VerifyStep1SignupClientResponse>;
};

export type MutationAddEventStep1Args = {
  input: AddEventStep1Input;
};

export type MutationAddEventStep2Args = {
  id: Scalars["ID"];
  input: AddEventStep2Input;
};

export type MutationAddEventStep3Args = {
  id: Scalars["ID"];
  input: AddEventStep3Input;
};

export type MutationAddEventStep4Args = {
  id: Scalars["ID"];
  input: AddEventStep4Input;
};

export type MutationAddEventWithoutStepsArgs = {
  input: AddEventWithoutStepsInput;
};

export type MutationAddPartnerArgs = {
  input: AddPartnerInput;
};

export type MutationApproveApplicationArgs = {
  input?: InputMaybe<ApproveApplicationVars>;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCheckApplicationArgs = {
  id: Scalars["Int"];
};

export type MutationCreateApplicationLongArgs = {
  input: CreateApplicationLongInput;
};

export type MutationCreateApplicationShortArgs = {
  input: CreateApplicationShortInput;
};

export type MutationCreateClientApplicationArgs = {
  input: ClientApplicationInput;
};

export type MutationCreateContactLinkArgs = {
  input: CreateContactLinkInput;
};

export type MutationCreateEventApplicationArgs = {
  input: EventApplicationInput;
};

export type MutationCreateFreelanceJobArgs = {
  input: CreateFreelanceJobInput;
};

export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};

export type MutationCreateStylingArgs = {
  input: CreateStylingInput;
};

export type MutationDeleteContactLinkArgs = {
  input: DeleteContactLinkInput;
};

export type MutationDeleteEventArgs = {
  id: Scalars["ID"];
};

export type MutationMakeDraftEventArgs = {
  id: Scalars["ID"];
};

export type MutationMakeModerationEventArgs = {
  id: Scalars["ID"];
};

export type MutationMakePublicEventArgs = {
  id: Scalars["ID"];
};

export type MutationPublishClientArgs = {
  published: Scalars["Boolean"];
};

export type MutationRefreshTokenArgs = {
  token: Scalars["String"];
};

export type MutationRemoveFromPublicationArgs = {
  id: Scalars["ID"];
};

export type MutationReportByErrorArgs = {
  input: ReportByErrorVars;
};

export type MutationReportBySpamArgs = {
  input: ReportBySpamVars;
};

export type MutationRequestTariffArgs = {
  input: RequestTariffInput;
};

export type MutationRequestTariffProlongArgs = {
  input: RequestTariffProlongInput;
};

export type MutationRestoreClientPasswordArgs = {
  email: Scalars["String"];
};

export type MutationSendChatMessageArgs = {
  chat: Scalars["ID"];
  input: SendChatMessageInput;
};

export type MutationSendMessageToSpotArgs = {
  event: Scalars["ID"];
};

export type MutationSetRestoreClientPasswordArgs = {
  input: SetRestoreClientPasswordInput;
  token: Scalars["String"];
};

export type MutationSetSignupClientPasswordArgs = {
  input: SetSignupClientPasswordInput;
  token: Scalars["String"];
};

export type MutationSigninClientArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationSignupClientArgs = {
  input: SignupClientInput;
};

export type MutationToggleMailingListArgs = {
  input: ToggleMailingListInput;
};

export type MutationUpdateApplicationStatusByPkArgs = {
  input: UpdateApplicationStatusByPkVars;
};

export type MutationUpdateBrandClientArgs = {
  input: UpdateBrandClientInput;
};

export type MutationUpdateChannelResourcesArgs = {
  input: UpdateChannelResourcesInput;
};

export type MutationUpdateClientAudienceArgs = {
  input: UpdateClientAudienceInput;
};

export type MutationUpdateClientPartnershipArgs = {
  partnership: UpdateClientPartnershipInput;
};

export type MutationUpdateClientProfileArgs = {
  input: UpdateClientProfileInput;
};

export type MutationUpdateContactLinkArgs = {
  input: UpdateContactLinkInput;
};

export type MutationUpdateEvent1Args = {
  id: Scalars["ID"];
  input: UpdateEvent1Input;
  submitForModeration?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateEvent2Args = {
  id: Scalars["ID"];
  input: UpdateEvent2Input;
  submitForModeration?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateEvent3Args = {
  id: Scalars["ID"];
  input: UpdateEvent3Input;
  submitForModeration?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateEvent4Args = {
  id: Scalars["ID"];
  input: UpdateEvent4Input;
  submitForModeration?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateFirstRegistrationArgs = {
  input: UpdateFirstRegistrationInput;
};

export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};

export type MutationUpdateStylingArgs = {
  input: UpdateStylingInput;
};

export type MutationUploadFileArgs = {
  file: Scalars["Upload"];
};

export type MutationVerifyStep1SignupClientArgs = {
  input: VerifyStep1SignupClientInput;
};

export type PackageCondition = {
  __typename?: "PackageCondition";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Partner = {
  __typename?: "Partner";
  id: Scalars["ID"];
  image: Scalars["String"];
  name: Scalars["String"];
};

export type PeopleAmountEnum = {
  __typename?: "PeopleAmountEnum";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type PressRelease = {
  __typename?: "PressRelease";
  cover?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  link?: Maybe<Scalars["String"]>;
  short_description: Scalars["String"];
  title: Scalars["String"];
};

export type Profile = {
  __typename?: "Profile";
  address?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<ClientCategory>>;
  clientId: Scalars["ID"];
  logo?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  shortDescription?: Maybe<Scalars["String"]>;
};

export type PublishClientResponse = {
  __typename?: "PublishClientResponse";
  success: Scalars["Boolean"];
};

export type Query = {
  __typename?: "Query";
  canSentApp?: Maybe<Scalars["Boolean"]>;
  checkFreeAddress?: Maybe<CheckFreeAddressResponse>;
  getApplicationCount: Scalars["Int"];
  getApplicationStatuses: Array<Maybe<Scalars["String"]>>;
  getBrands?: Maybe<GetBrandsResponse>;
  getCases?: Maybe<GetCasesResponse>;
  getChannels?: Maybe<GetChannelsResponse>;
  getCities?: Maybe<GetCitiesResponse>;
  getClientApplication?: Maybe<ClientApplication>;
  getClientApplicationByPk?: Maybe<ClientApplication>;
  getClientApplicationsByPk?: Maybe<GetClientApplicationsByPkResponse>;
  getClientCategories?: Maybe<GetClientCategoriesResponse>;
  getClientChats?: Maybe<GetClientChatsResponse>;
  getClientEvents?: Maybe<GetClientEventsResponse>;
  getClientFreelanceJobs?: Maybe<GetClientFreelanceJobsResponse>;
  getClientInterests?: Maybe<GetClientInterestsResponse>;
  getClientMessages?: Maybe<GetClientMessagesResponse>;
  getClients: ClientPage;
  getContactDict?: Maybe<GetContactDictResponse>;
  getContactLinks?: Maybe<GetContactLinksResponse>;
  getCountries?: Maybe<GetCountriesResponse>;
  getDictCities?: Maybe<GetCitiesResponse>;
  getEvent?: Maybe<GetEventResponse>;
  getEventApplication?: Maybe<EventApplication>;
  getEventCategories?: Maybe<GetEventCategoriesResponse>;
  getEventInterests?: Maybe<GetEventInterestsResponse>;
  getEventPackages?: Maybe<GetEventPackagesResponse>;
  getEventStatus?: Maybe<GetEventStatusResponse>;
  getEventSteps?: Maybe<GetEventStepsResponse>;
  getEvents?: Maybe<GetEventsResponse>;
  getFirstRegistration?: Maybe<Scalars["Boolean"]>;
  getFreelanceCategories?: Maybe<GetFreelanceCategoriesResponse>;
  getImageByUrl?: Maybe<Scalars["String"]>;
  getIsNewAppCount: GetIsNewAppCountResponse;
  getLocation?: Maybe<GetLocationResponse>;
  getMailingStatus: MailingStatus;
  getMyClient?: Maybe<GetMyClient>;
  getMyClientAudience?: Maybe<GetMyClientAudience>;
  getMyClientPartnership?: Maybe<GetMyClientPartnership>;
  getMyClientProfile?: Maybe<GetMyClientProfile>;
  getPartners?: Maybe<GetPartnersResponse>;
  getPeopleAmounts?: Maybe<GetPeopleAmountsResponse>;
  getProfile?: Maybe<GetProfileResponse>;
  getSponsorCategories?: Maybe<GetSponsorCategoriesResponse>;
  getSpotClient?: Maybe<GetSpotClientResponse>;
  getStyling?: Maybe<GetStylingResponse>;
  getTargetAudiences?: Maybe<GetTargetAudiencesResponse>;
  getThemeTags?: Maybe<GetThemeTagsResponse>;
  getTypes?: Maybe<GetTypesResponse>;
  getUpdateEventDescriptionInfo?: Maybe<GetUpdateEventDescriptionInfoResponse>;
  getUpdateEventInfo?: Maybe<GetUpdateEventInfoResponse>;
  getUpdateEventSponsorsInfo?: Maybe<GetUpdateEventSponsorsInfoResponse>;
  getUpdateTargetsInfo?: Maybe<GetUpdateTargetsInfoResponse>;
  getWebsiteInfo?: Maybe<GetWebsiteInfo>;
  root?: Maybe<Scalars["String"]>;
};

export type QueryCanSentAppArgs = {
  recipientId: Scalars["Int"];
  senderId: Scalars["Int"];
};

export type QueryCheckFreeAddressArgs = {
  input: CheckFreeAddressInput;
};

export type QueryGetChannelsArgs = {
  input?: InputMaybe<GetChannelsInput>;
};

export type QueryGetClientApplicationArgs = {
  input: GetClientApplicationVars;
};

export type QueryGetClientApplicationByPkArgs = {
  input: GetClientApplicationByPkVars;
};

export type QueryGetClientApplicationsByPkArgs = {
  input: GetClientApplicationsByPkVars;
};

export type QueryGetClientEventsArgs = {
  filter?: InputMaybe<Scalars["String"]>;
};

export type QueryGetClientMessagesArgs = {
  chat: Scalars["ID"];
};

export type QueryGetClientsArgs = {
  input?: InputMaybe<GetClientsInput>;
};

export type QueryGetContactLinksArgs = {
  input?: InputMaybe<GetContactLinksInput>;
};

export type QueryGetCountriesArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetDictCitiesArgs = {
  input?: InputMaybe<GetDictCitiesVars>;
};

export type QueryGetEventArgs = {
  id: Scalars["ID"];
};

export type QueryGetEventApplicationArgs = {
  input: GetEventApplicationVars;
};

export type QueryGetEventPackagesArgs = {
  id: Scalars["ID"];
};

export type QueryGetEventStatusArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetEventStepsArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetEventsArgs = {
  input?: InputMaybe<GetEventsInput>;
};

export type QueryGetImageByUrlArgs = {
  url: Scalars["String"];
};

export type QueryGetLocationArgs = {
  input?: InputMaybe<LocationInput>;
};

export type QueryGetMailingStatusArgs = {
  id: Scalars["ID"];
};

export type QueryGetMyClientArgs = {
  input?: InputMaybe<GetMyClientInput>;
};

export type QueryGetMyClientAudienceArgs = {
  input?: InputMaybe<GetMyClientAudienceInput>;
};

export type QueryGetMyClientPartnershipArgs = {
  input?: InputMaybe<GetMyClientPartnershipInput>;
};

export type QueryGetMyClientProfileArgs = {
  input?: InputMaybe<GetMyClientProfileInput>;
};

export type QueryGetPartnersArgs = {
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryGetStylingArgs = {
  input?: InputMaybe<GetStylingVars>;
};

export type QueryGetUpdateEventDescriptionInfoArgs = {
  id: Scalars["ID"];
};

export type QueryGetUpdateEventInfoArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetUpdateEventSponsorsInfoArgs = {
  id: Scalars["ID"];
};

export type QueryGetUpdateTargetsInfoArgs = {
  id: Scalars["ID"];
};

export type QueryGetWebsiteInfoArgs = {
  url: Scalars["String"];
};

export type RefreshToken = {
  __typename?: "RefreshToken";
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type RefreshTokenResponse = Error | RefreshToken;

export type RemoveAccount = {
  __typename?: "RemoveAccount";
  client: Client;
};

export type RemoveAccountResponse = Error | RemoveAccount;

export type RemoveFromPublication = {
  __typename?: "RemoveFromPublication";
  event?: Maybe<ClientRemoveFromPublication>;
};

export type RemoveFromPublicationResponse = Error | RemoveFromPublication;

export type ReportByErrorResponse = ClientApplication | Error;

export type ReportByErrorVars = {
  id: Scalars["Int"];
  recipientId: Scalars["Int"];
  senderId: Scalars["Int"];
};

export type ReportBySpamResponse = ClientApplication | Error;

export type ReportBySpamVars = {
  id: Scalars["Int"];
};

export type RequestTariff = {
  __typename?: "RequestTariff";
  tariff: Tariff;
};

export type RequestTariffInput = {
  assistant_id?: InputMaybe<Scalars["Int"]>;
  clientId: Scalars["ID"];
  request: TariffRequestEnum;
  type: TariffTypeEnum;
};

export type RequestTariffProlongInput = {
  clientId: Scalars["ID"];
  request: TariffRequestEnum;
  type: TariffTypeEnum;
};

export type RequestTariffProlongResponse = Error | RequestTariff;

export type RequestTariffResponse = Error | RequestTariff;

export type RestoreClientPassword = {
  __typename?: "RestoreClientPassword";
  success: Scalars["Boolean"];
};

export type RestoreClientPasswordResponse = Error | RestoreClientPassword;

export type SendChatMessage = {
  __typename?: "SendChatMessage";
  message: DirectMessage;
};

export type SendChatMessageInput = {
  message: Scalars["String"];
};

export type SendChatMessageResponse = Error | SendChatMessage;

export type SendMessageToSpot = {
  __typename?: "SendMessageToSpot";
  chat: DirectChat;
};

export type SendMessageToSpotResponse = Error | SendMessageToSpot;

export type SetRestoreClientPassword = {
  __typename?: "SetRestoreClientPassword";
  client?: Maybe<Client>;
};

export type SetRestoreClientPasswordInput = {
  password: Scalars["String"];
  repeatPassword: Scalars["String"];
};

export type SetRestoreClientPasswordResponse = Error | SetRestoreClientPassword;

export type SetSignupClientPassword = {
  __typename?: "SetSignupClientPassword";
  client?: Maybe<Client>;
};

export type SetSignupClientPasswordInput = {
  password: Scalars["String"];
  repeatPassword: Scalars["String"];
};

export type SetSignupClientPasswordResponse = Error | SetSignupClientPassword;

export type SigninClient = {
  __typename?: "SigninClient";
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type SigninClientResponse = Error | SigninClient;

export type SignupClient = {
  __typename?: "SignupClient";
  client?: Maybe<Client>;
};

export type SignupClientInput = {
  agreement: Scalars["Boolean"];
  description: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  name: Scalars["String"];
  phone: Scalars["String"];
  position: Scalars["String"];
  subscription: Scalars["Boolean"];
  type: ClientTypeEnum;
};

export type SignupClientResponse = Error | SignupClient;

export enum SortEnum {
  Any = "any",
  ByAudience = "byAudience",
  ByChannels = "byChannels",
}

export type SponsorCategory = {
  __typename?: "SponsorCategory";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type SponsorCategoryEnum = {
  __typename?: "SponsorCategoryEnum";
  icon: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type SpotClient = {
  __typename?: "SpotClient";
  categoryId: Scalars["ID"];
  cityId: Scalars["ID"];
  client_id: Scalars["ID"];
  image: Scalars["String"];
  name: Scalars["String"];
};

export type Styling = {
  __typename?: "Styling";
  color?: Maybe<Scalars["String"]>;
  cover?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type Subscription = {
  __typename?: "Subscription";
  root?: Maybe<Scalars["String"]>;
};

export type TargetAudience = {
  __typename?: "TargetAudience";
  description: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type Tariff = {
  __typename?: "Tariff";
  assistant?: Maybe<Scalars["String"]>;
  assistantLink?: Maybe<Scalars["String"]>;
  assistant_id?: Maybe<Scalars["String"]>;
  availableRequests?: Maybe<Scalars["Int"]>;
  clientId: Scalars["ID"];
  createdAt?: Maybe<Scalars["DateTime"]>;
  expiredAt?: Maybe<Scalars["DateTime"]>;
  outgoingRequests?: Maybe<Scalars["Int"]>;
  paid?: Maybe<TariffPaymentEnum>;
  request?: Maybe<TariffRequestEnum>;
  startedAt?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<TariffStatusEnum>;
  type?: Maybe<TariffTypeEnum>;
};

export enum TariffPaymentEnum {
  NoPaid = "NO_PAID",
  OnPending = "ON_PENDING",
  Paid = "PAID",
}

export enum TariffRequestEnum {
  Active = "ACTIVE",
  NoActive = "NO_ACTIVE",
  NoRequest = "NO_REQUEST",
  OneMonth = "ONE_MONTH",
  ThreeMonth = "THREE_MONTH",
  Year = "YEAR",
}

export enum TariffStatusEnum {
  Active = "ACTIVE",
  NoActive = "NO_ACTIVE",
  Request = "REQUEST",
}

export enum TariffTypeEnum {
  NoTariff = "NO_TARIFF",
  OneMonth = "ONE_MONTH",
  ThreeMonth = "THREE_MONTH",
  Year = "YEAR",
}

export type ThemeTagEnum = {
  __typename?: "ThemeTagEnum";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type ToggleMailingList = {
  __typename?: "ToggleMailingList";
  mailing: Mailing;
};

export type ToggleMailingListInput = {
  id: Scalars["ID"];
};

export type Type = {
  __typename?: "Type";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type UpdateApplicationStatusByPkResponse = ClientApplication | Error;

export type UpdateApplicationStatusByPkVars = {
  id: Scalars["Int"];
  recipientId: Scalars["Int"];
  senderId: Scalars["Int"];
  status: ClientAppStatusEnum;
};

export type UpdateBrandClient = {
  __typename?: "UpdateBrandClient";
  client: Client;
};

export type UpdateBrandClientInput = {
  brand: Scalars["String"];
  category?: InputMaybe<Scalars["ID"]>;
  city?: InputMaybe<Scalars["ID"]>;
  description: Scalars["String"];
  image?: InputMaybe<Scalars["Upload"]>;
  name: Scalars["String"];
  targetAudience?: InputMaybe<Scalars["ID"]>;
};

export type UpdateBrandClientResponse = Error | UpdateBrandClient;

export type UpdateChannelResources = {
  __typename?: "UpdateChannelResources";
  channelResources: Array<ChannelResource>;
};

export type UpdateChannelResourcesInput = {
  channelResources: Array<ChannelResourceInput>;
};

export type UpdateChannelResourcesResponse = Error | UpdateChannelResources;

export type UpdateClientAudience = {
  __typename?: "UpdateClientAudience";
  clientAudience: ClientAudience;
};

export type UpdateClientAudienceInput = {
  ageSegments?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Scalars["String"]>;
  interests: Array<Scalars["ID"]>;
};

export type UpdateClientAudienceResponse = Error | UpdateClientAudience;

export type UpdateClientPartnership = {
  __typename?: "UpdateClientPartnership";
  partnership: ClientPartnership;
};

export type UpdateClientPartnershipInput = {
  categories: Array<Scalars["Int"]>;
  interestedIn: Array<Scalars["Int"]>;
  possibilities: Array<Scalars["Int"]>;
};

export type UpdateClientPartnershipResponse = Error | UpdateClientPartnership;

export type UpdateClientProfile = {
  __typename?: "UpdateClientProfile";
  client: Client;
};

export type UpdateClientProfileInput = {
  address?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<Array<Scalars["Int"]>>;
  description?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["Upload"]>;
  model?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  shortDescription?: InputMaybe<Scalars["String"]>;
};

export type UpdateClientProfileResponse = Error | UpdateClientProfile;

export type UpdateContactLink = {
  __typename?: "UpdateContactLink";
  contact: ContactLink;
};

export type UpdateContactLinkInput = {
  dictionaryId: Scalars["ID"];
  id: Scalars["ID"];
  link: Scalars["String"];
};

export type UpdateContactLinkResponse = Error | UpdateContactLink;

export type UpdateEvent1 = {
  __typename?: "UpdateEvent1";
  event: ClientEvent1;
};

export type UpdateEvent1Input = {
  address?: InputMaybe<Scalars["String"]>;
  area?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["ID"]>;
  city?: InputMaybe<Scalars["ID"]>;
  dates?: InputMaybe<Array<Array<Scalars["String"]>>>;
  isLocationUnknown?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  offline?: InputMaybe<Scalars["Boolean"]>;
  online?: InputMaybe<Scalars["Boolean"]>;
  themes?: InputMaybe<Array<Scalars["String"]>>;
  times?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  website?: InputMaybe<Scalars["String"]>;
};

export type UpdateEvent1Response = Error | UpdateEvent1;

export type UpdateEvent2 = {
  __typename?: "UpdateEvent2";
  event: ClientEvent;
};

export type UpdateEvent2Input = {
  crop?: InputMaybe<CropData>;
  fullDescription?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<Scalars["String"]>;
  shortDescription?: InputMaybe<Scalars["String"]>;
  sourceImage?: InputMaybe<Scalars["String"]>;
};

export type UpdateEvent2Response = Error | UpdateEvent2;

export type UpdateEvent3 = {
  __typename?: "UpdateEvent3";
  event?: Maybe<UpdateEvent3Payload>;
};

export type UpdateEvent3Input = {
  ageStatements?: InputMaybe<AgeInput>;
  eventInterest?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<Scalars["String"]>>>>
  >;
  free?: InputMaybe<Scalars["Boolean"]>;
  genderStatements?: InputMaybe<GenderInput>;
  model?: InputMaybe<ModelInput>;
  onlyPeopleAmountFrom?: InputMaybe<Scalars["Boolean"]>;
  peopleAmountFrom?: InputMaybe<Scalars["String"]>;
  peopleAmountTo?: InputMaybe<Scalars["String"]>;
  priceDescription?: InputMaybe<Scalars["String"]>;
  priceFrom?: InputMaybe<Scalars["String"]>;
  priceTo?: InputMaybe<Scalars["String"]>;
  targetAudience?: InputMaybe<Scalars["ID"]>;
};

export type UpdateEvent3Payload = {
  __typename?: "UpdateEvent3Payload";
  id?: Maybe<Scalars["ID"]>;
};

export type UpdateEvent3Response = Error | UpdateEvent3;

export type UpdateEvent4 = {
  __typename?: "UpdateEvent4";
  event?: Maybe<ClientEvent>;
};

export type UpdateEvent4ConditionInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
};

export type UpdateEvent4Input = {
  addPackages: Scalars["Boolean"];
  allCategories: Scalars["Boolean"];
  packages?: InputMaybe<Array<UpdateEvent4PacakgesInput>>;
  sponsorCategories?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdateEvent4PacakgesInput = {
  barter: Scalars["Boolean"];
  conditions: Array<UpdateEvent4ConditionInput>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  price: Scalars["String"];
};

export type UpdateEvent4Response = Error | UpdateEvent4;

export type UpdateEventInfo = {
  __typename?: "UpdateEventInfo";
  addPackages?: Maybe<Scalars["Boolean"]>;
  address?: Maybe<Scalars["String"]>;
  allCategories?: Maybe<Scalars["Boolean"]>;
  categoryId?: Maybe<Scalars["ID"]>;
  cityId?: Maybe<Scalars["ID"]>;
  dates: Array<Array<Scalars["String"]>>;
  fullDescription?: Maybe<Scalars["String"]>;
  image?: Maybe<Scalars["String"]>;
  isLocationUnknown?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  online?: Maybe<Scalars["Boolean"]>;
  partners: Array<Partner>;
  shortDescription?: Maybe<Scalars["String"]>;
  spot?: Maybe<Scalars["String"]>;
  themes: Array<Scalars["ID"]>;
  time?: Maybe<Scalars["String"]>;
  times: Array<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type UpdateEventInfo1 = {
  __typename?: "UpdateEventInfo1";
  address?: Maybe<Scalars["String"]>;
  area?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  categoryId: Scalars["Int"];
  city?: Maybe<Scalars["String"]>;
  cityId?: Maybe<Scalars["Int"]>;
  dates?: Maybe<Array<Maybe<Array<Maybe<Scalars["DateTime"]>>>>>;
  isLocationUnknown?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  offline?: Maybe<Scalars["Boolean"]>;
  online?: Maybe<Scalars["Boolean"]>;
  themes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  times?: Maybe<Array<Maybe<Scalars["String"]>>>;
  website?: Maybe<Scalars["String"]>;
};

export type UpdateFirstRegistration = {
  __typename?: "UpdateFirstRegistration";
  client: Client;
};

export type UpdateFirstRegistrationInput = {
  firstRegistration: Scalars["Boolean"];
};

export type UpdateFirstRegistrationResponse = Error | UpdateFirstRegistration;

export type UpdateLocation = {
  __typename?: "UpdateLocation";
  location: Location;
};

export type UpdateLocationInput = {
  address?: InputMaybe<Scalars["String"]>;
  cities: Array<Scalars["ID"]>;
  countries: Array<Scalars["ID"]>;
};

export type UpdateLocationResponse = Error | UpdateLocation;

export type UpdateStyling = {
  __typename?: "UpdateStyling";
  styling: Styling;
};

export type UpdateStylingInput = {
  color?: InputMaybe<Scalars["String"]>;
  cover?: InputMaybe<Scalars["String"]>;
};

export type UpdateStylingResponse = Error | UpdateStyling;

export type UpdateTargetsInfo = {
  __typename?: "UpdateTargetsInfo";
  free?: Maybe<Scalars["Boolean"]>;
  peopleAmountFrom?: Maybe<Scalars["Int"]>;
  peopleAmountTo?: Maybe<Scalars["Int"]>;
  priceDescription?: Maybe<Scalars["String"]>;
  priceFrom?: Maybe<Scalars["Float"]>;
  priceTo?: Maybe<Scalars["Float"]>;
  targetAudienceId?: Maybe<Scalars["ID"]>;
};

export type UpdateTargetsInfo1 = {
  __typename?: "UpdateTargetsInfo1";
  ageStatements?: Maybe<Scalars["String"]>;
  eventInterest?: Maybe<Array<Maybe<InterestsData>>>;
  free?: Maybe<Scalars["Boolean"]>;
  genderStatements?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  onlyPeopleAmountFrom?: Maybe<Scalars["Boolean"]>;
  peopleAmountFrom?: Maybe<Scalars["String"]>;
  peopleAmountTo?: Maybe<Scalars["String"]>;
  priceDescription?: Maybe<Scalars["String"]>;
  priceFrom?: Maybe<Scalars["String"]>;
  priceTo?: Maybe<Scalars["String"]>;
};

export type UploadFileClient = {
  __typename?: "UploadFileClient";
  fileName: Scalars["String"];
};

export type UploadFileResponse = Error | UploadFileClient;

export type VerifyStep1SignupClient = {
  __typename?: "VerifyStep1SignupClient";
  result: Scalars["Boolean"];
};

export type VerifyStep1SignupClientInput = {
  agreement: Scalars["Boolean"];
  description: Scalars["String"];
  name: Scalars["String"];
  type: ClientTypeEnum;
};

export type VerifyStep1SignupClientResponse = Error | VerifyStep1SignupClient;

export type Warning = {
  __typename?: "Warning";
  key: Scalars["String"];
  warning: Scalars["String"];
};

export type ModelInput = {
  b2b?: InputMaybe<Scalars["Boolean"]>;
  b2c?: InputMaybe<Scalars["Boolean"]>;
};

export type AddEventStep1MutationVariables = Exact<{
  input: AddEventStep1Input;
}>;

export type AddEventStep1Mutation = {
  __typename?: "Mutation";
  addEventStep1?:
    | {
        __typename?: "AddEventStep1";
        event: {
          __typename?: "ClientEventStep1";
          id?: string | null;
          name?: string | null;
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type AddEventStep2MutationVariables = Exact<{
  addEventStep2Id: Scalars["ID"];
  input: AddEventStep2Input;
}>;

export type AddEventStep2Mutation = {
  __typename?: "Mutation";
  addEventStep2?:
    | {
        __typename?: "AddEventStep2";
        event: {
          __typename?: "ClientEventStep2";
          id?: string | null;
          message?: string | null;
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type AddEventStep3MutationVariables = Exact<{
  addEventStep3Id: Scalars["ID"];
  input: AddEventStep3Input;
}>;

export type AddEventStep3Mutation = {
  __typename?: "Mutation";
  addEventStep3?:
    | { __typename?: "AddEventStep3"; event?: string | null }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type AddEventStep4MutationVariables = Exact<{
  addEventStep4Id: Scalars["ID"];
  input: AddEventStep4Input;
}>;

export type AddEventStep4Mutation = {
  __typename?: "Mutation";
  addEventStep4?:
    | { __typename?: "AddEventStep4"; event?: string | null }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type ApproveApplicationMutationVariables = Exact<{
  input?: InputMaybe<ApproveApplicationVars>;
}>;

export type ApproveApplicationMutation = {
  __typename?: "Mutation";
  approveApplication?:
    | {
        __typename?: "ClientApplication";
        id: string;
        senderId: number;
        recipientId: number;
        createdAt: string;
        updatedAt: string;
        status: ClientAppStatusEnum;
        approvedByRecipient: boolean;
        approvedBySender: boolean;
        text: string;
        sender: {
          __typename?: "ClientEntity";
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          phone: string;
          position: string;
          profile: {
            __typename?: "ClientProfileEntity";
            logo: string;
            name: string;
          };
        };
        recipient: {
          __typename?: "ClientEntity";
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          phone: string;
          position: string;
          profile: {
            __typename?: "ClientProfileEntity";
            logo: string;
            name: string;
          };
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword?:
    | {
        __typename?: "ChangePassword";
        client: {
          __typename?: "Client";
          id: string;
          type: ClientTypeEnum;
          email: string;
          name: string;
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type CreateApplicationShortMutationVariables = Exact<{
  input: CreateApplicationShortInput;
}>;

export type CreateApplicationShortMutation = {
  __typename?: "Mutation";
  createApplicationShort?:
    | { __typename?: "CreateApplicationShort"; id?: number | null }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type CreateClientApplicationMutationVariables = Exact<{
  input: ClientApplicationInput;
}>;

export type CreateClientApplicationMutation = {
  __typename?: "Mutation";
  createClientApplication: {
    __typename?: "CreateClientApplicationResult";
    success: boolean;
    message?: string | null;
  };
};

export type CreateContactLinkMutationVariables = Exact<{
  input: CreateContactLinkInput;
}>;

export type CreateContactLinkMutation = {
  __typename?: "Mutation";
  createContactLink?:
    | {
        __typename?: "CreateContactLink";
        contact: {
          __typename?: "ContactLink";
          id: string;
          clientId: string;
          dictionaryId: string;
          link: string;
          dictionary: { __typename?: "DictContact"; id: string; name: string };
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type CreateEventApplicationMutationVariables = Exact<{
  input: EventApplicationInput;
}>;

export type CreateEventApplicationMutation = {
  __typename?: "Mutation";
  createEventApplication: {
    __typename?: "CreateEventApplicationResult";
    message?: string | null;
    success: boolean;
  };
};

export type CreateLocationMutationVariables = Exact<{
  input: CreateLocationInput;
}>;

export type CreateLocationMutation = {
  __typename?: "Mutation";
  createLocation?:
    | {
        __typename?: "CreateLocation";
        location: {
          __typename?: "Location";
          id: string;
          address?: string | null;
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type CreateStylingMutationVariables = Exact<{
  input: CreateStylingInput;
}>;

export type CreateStylingMutation = {
  __typename?: "Mutation";
  createStyling?:
    | {
        __typename?: "CreateStyling";
        styling: {
          __typename?: "Styling";
          id: string;
          color?: string | null;
          cover?: string | null;
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type DeleteContactLinkMutationVariables = Exact<{
  input: DeleteContactLinkInput;
}>;

export type DeleteContactLinkMutation = {
  __typename?: "Mutation";
  deleteContactLink?:
    | {
        __typename?: "DeleteContactLink";
        contact: {
          __typename?: "ContactLink";
          id: string;
          clientId: string;
          dictionaryId: string;
          link: string;
          dictionary: { __typename?: "DictContact"; id: string; name: string };
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type DeleteEventMutationVariables = Exact<{
  deleteEventId: Scalars["ID"];
}>;

export type DeleteEventMutation = {
  __typename?: "Mutation";
  deleteEvent?:
    | {
        __typename?: "DeleteEvent";
        event?: {
          __typename?: "ClientDeleteEvent";
          name?: string | null;
        } | null;
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type MakeDraftEventMutationVariables = Exact<{
  makeDraftEventId: Scalars["ID"];
}>;

export type MakeDraftEventMutation = {
  __typename?: "Mutation";
  makeDraftEvent?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "MakeDraftEvent";
        event?: {
          __typename?: "ClientMakeDraftEvent";
          name?: string | null;
        } | null;
      }
    | null;
};

export type MakePublicEventMutationVariables = Exact<{
  makePublicEventId: Scalars["ID"];
}>;

export type MakePublicEventMutation = {
  __typename?: "Mutation";
  makePublicEvent?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "MakePublicEvent";
        event?: {
          __typename?: "ClientMakePublicEvent";
          name?: string | null;
        } | null;
      }
    | null;
};

export type PublishClientMutationVariables = Exact<{
  published: Scalars["Boolean"];
}>;

export type PublishClientMutation = {
  __typename?: "Mutation";
  publishClient?: {
    __typename?: "PublishClientResponse";
    success: boolean;
  } | null;
};

export type RemoveFromPublicationMutationVariables = Exact<{
  removeFromPublicationId: Scalars["ID"];
}>;

export type RemoveFromPublicationMutation = {
  __typename?: "Mutation";
  removeFromPublication?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "RemoveFromPublication";
        event?: {
          __typename?: "ClientRemoveFromPublication";
          name?: string | null;
        } | null;
      }
    | null;
};

export type ReportByErrorMutationVariables = Exact<{
  input: ReportByErrorVars;
}>;

export type ReportByErrorMutation = {
  __typename?: "Mutation";
  reportByError?:
    | {
        __typename?: "ClientApplication";
        id: string;
        approvedByRecipient: boolean;
        approvedBySender: boolean;
        createdAt: string;
        recipientId: number;
        senderId: number;
        status: ClientAppStatusEnum;
        text: string;
        recipient: {
          __typename?: "ClientEntity";
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          phone: string;
          position: string;
          profile: {
            __typename?: "ClientProfileEntity";
            logo: string;
            name: string;
          };
        };
        sender: {
          __typename?: "ClientEntity";
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          phone: string;
          position: string;
          profile: {
            __typename?: "ClientProfileEntity";
            logo: string;
            name: string;
          };
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type ReportBySpamMutationVariables = Exact<{
  input: ReportBySpamVars;
}>;

export type ReportBySpamMutation = {
  __typename?: "Mutation";
  reportBySpam?:
    | {
        __typename?: "ClientApplication";
        id: string;
        approvedByRecipient: boolean;
        approvedBySender: boolean;
        createdAt: string;
        recipientId: number;
        senderId: number;
        status: ClientAppStatusEnum;
        text: string;
        recipient: {
          __typename?: "ClientEntity";
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          phone: string;
          position: string;
          profile: {
            __typename?: "ClientProfileEntity";
            logo: string;
            name: string;
          };
        };
        sender: {
          __typename?: "ClientEntity";
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          phone: string;
          position: string;
          profile: {
            __typename?: "ClientProfileEntity";
            logo: string;
            name: string;
          };
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type RequestTariffMutationVariables = Exact<{
  input: RequestTariffInput;
}>;

export type RequestTariffMutation = {
  __typename?: "Mutation";
  requestTariff?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "RequestTariff";
        tariff: {
          __typename?: "Tariff";
          clientId: string;
          startedAt?: any | null;
          expiredAt?: any | null;
          outgoingRequests?: number | null;
          availableRequests?: number | null;
          type?: TariffTypeEnum | null;
          request?: TariffRequestEnum | null;
          assistant?: string | null;
          assistantLink?: string | null;
          paid?: TariffPaymentEnum | null;
          status?: TariffStatusEnum | null;
          createdAt?: any | null;
        };
      }
    | null;
};

export type RequestTariffProlongMutationVariables = Exact<{
  input: RequestTariffProlongInput;
}>;

export type RequestTariffProlongMutation = {
  __typename?: "Mutation";
  requestTariffProlong?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "RequestTariff";
        tariff: {
          __typename?: "Tariff";
          clientId: string;
          startedAt?: any | null;
          expiredAt?: any | null;
          outgoingRequests?: number | null;
          availableRequests?: number | null;
          type?: TariffTypeEnum | null;
          request?: TariffRequestEnum | null;
          assistant?: string | null;
          assistantLink?: string | null;
          paid?: TariffPaymentEnum | null;
          status?: TariffStatusEnum | null;
          createdAt?: any | null;
        };
      }
    | null;
};

export type ToggleMailingListMutationVariables = Exact<{
  input: ToggleMailingListInput;
}>;

export type ToggleMailingListMutation = {
  __typename?: "Mutation";
  toggleMailingList?: {
    __typename?: "ToggleMailingList";
    mailing: {
      __typename?: "Mailing";
      id: string;
      title: string;
      status: boolean;
    };
  } | null;
};

export type UpdateApplicationStatusByPkMutationVariables = Exact<{
  input: UpdateApplicationStatusByPkVars;
}>;

export type UpdateApplicationStatusByPkMutation = {
  __typename?: "Mutation";
  updateApplicationStatusByPk?:
    | {
        __typename?: "ClientApplication";
        id: string;
        senderId: number;
        recipientId: number;
        createdAt: string;
        updatedAt: string;
        status: ClientAppStatusEnum;
        approvedByRecipient: boolean;
        approvedBySender: boolean;
        text: string;
        sender: {
          __typename?: "ClientEntity";
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          phone: string;
          position: string;
          profile: {
            __typename?: "ClientProfileEntity";
            logo: string;
            name: string;
          };
        };
        recipient: {
          __typename?: "ClientEntity";
          email: string;
          firstName: string;
          id: string;
          lastName: string;
          phone: string;
          position: string;
          profile: {
            __typename?: "ClientProfileEntity";
            logo: string;
            name: string;
          };
        };
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type UpdateChannelResourcesMutationVariables = Exact<{
  input: UpdateChannelResourcesInput;
}>;

export type UpdateChannelResourcesMutation = {
  __typename?: "Mutation";
  updateChannelResources:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateChannelResources";
        channelResources: Array<{
          __typename?: "ChannelResource";
          channelId: string;
          name: string;
          audience: number;
        }>;
      };
};

export type UpdateClientAudienceMutationVariables = Exact<{
  input: UpdateClientAudienceInput;
}>;

export type UpdateClientAudienceMutation = {
  __typename?: "Mutation";
  updateClientAudience?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateClientAudience";
        clientAudience: {
          __typename?: "ClientAudience";
          clientId: string;
          gender: string;
          ageSegments: string;
          interests: Array<{
            __typename?: "ClientInterest";
            id: string;
            name: string;
          }>;
        };
      }
    | null;
};

export type UpdateClientPartnershipMutationVariables = Exact<{
  partnership: UpdateClientPartnershipInput;
}>;

export type UpdateClientPartnershipMutation = {
  __typename?: "Mutation";
  updateClientPartnership?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateClientPartnership";
        partnership: {
          __typename?: "ClientPartnership";
          clientId: string;
          categories: Array<{
            __typename?: "ClientCategory";
            id: string;
            name: string;
          }>;
          interested_in_channels: Array<{
            __typename?: "Channel";
            id: string;
            type: ChannelType;
            name: string;
            clientId?: string | null;
            deletedAt?: string | null;
            createdAt: string;
            updatedAt: string;
            value?: string | null;
            audience?: number | null;
          }>;
          possibilities_channels: Array<{
            __typename?: "Channel";
            id: string;
            type: ChannelType;
            name: string;
            clientId?: string | null;
            deletedAt?: string | null;
            createdAt: string;
            updatedAt: string;
            value?: string | null;
            audience?: number | null;
          }>;
        };
      }
    | null;
};

export type UpdateClientProfileMutationVariables = Exact<{
  input: UpdateClientProfileInput;
}>;

export type UpdateClientProfileMutation = {
  __typename?: "Mutation";
  updateClientProfile?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateClientProfile";
        client: {
          __typename?: "Client";
          id: string;
          type: ClientTypeEnum;
          email: string;
          name: string;
        };
      }
    | null;
};

export type UpdateContactLinkMutationVariables = Exact<{
  input: UpdateContactLinkInput;
}>;

export type UpdateContactLinkMutation = {
  __typename?: "Mutation";
  updateContactLink?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateContactLink";
        contact: {
          __typename?: "ContactLink";
          id: string;
          clientId: string;
          dictionaryId: string;
          link: string;
          dictionary: { __typename?: "DictContact"; id: string; name: string };
        };
      }
    | null;
};

export type UpdateEvent1MutationVariables = Exact<{
  updateEvent1Id: Scalars["ID"];
  input: UpdateEvent1Input;
}>;

export type UpdateEvent1Mutation = {
  __typename?: "Mutation";
  updateEvent1?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateEvent1";
        event: {
          __typename?: "ClientEvent1";
          id?: string | null;
          name?: string | null;
        };
      }
    | null;
};

export type UpdateEvent2MutationVariables = Exact<{
  updateEvent2Id: Scalars["ID"];
  input: UpdateEvent2Input;
}>;

export type UpdateEvent2Mutation = {
  __typename?: "Mutation";
  updateEvent2?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateEvent2";
        event: {
          __typename?: "ClientEvent";
          id: string;
          name?: string | null;
          categoryId?: string | null;
          category?: string | null;
          cityId?: string | null;
          city?: string | null;
          themes: Array<string>;
          address?: string | null;
          dates: Array<Array<string>>;
          time?: string | null;
          spot?: string | null;
          image?: string | null;
          online?: boolean | null;
          isLocationUnknown?: boolean | null;
          shortDescription?: string | null;
          fullDescription?: string | null;
          spotClient?: string | null;
          peopleAmountFrom?: number | null;
          peopleAmountTo?: number | null;
          targetAudienceId?: string | null;
          free?: boolean | null;
          priceFrom?: number | null;
          priceTo?: number | null;
          favorite?: boolean | null;
          status: string;
        };
      }
    | null;
};

export type UpdateEvent3MutationVariables = Exact<{
  updateEvent3Id: Scalars["ID"];
  input: UpdateEvent3Input;
}>;

export type UpdateEvent3Mutation = {
  __typename?: "Mutation";
  updateEvent3?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateEvent3";
        event?: {
          __typename?: "UpdateEvent3Payload";
          id?: string | null;
        } | null;
      }
    | null;
};

export type UpdateEvent4MutationVariables = Exact<{
  updateEvent4Id: Scalars["ID"];
  input: UpdateEvent4Input;
}>;

export type UpdateEvent4Mutation = {
  __typename?: "Mutation";
  updateEvent4?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateEvent4";
        event?: {
          __typename?: "ClientEvent";
          id: string;
          name?: string | null;
          categoryId?: string | null;
          category?: string | null;
          cityId?: string | null;
          city?: string | null;
          themes: Array<string>;
          address?: string | null;
          dates: Array<Array<string>>;
          time?: string | null;
          spot?: string | null;
          image?: string | null;
          online?: boolean | null;
          isLocationUnknown?: boolean | null;
          shortDescription?: string | null;
          fullDescription?: string | null;
          spotClient?: string | null;
          peopleAmountFrom?: number | null;
          peopleAmountTo?: number | null;
          targetAudienceId?: string | null;
          free?: boolean | null;
          priceFrom?: number | null;
          priceTo?: number | null;
          favorite?: boolean | null;
          status: string;
        } | null;
      }
    | null;
};

export type UpdateFirstRegistrationMutationVariables = Exact<{
  input: UpdateFirstRegistrationInput;
}>;

export type UpdateFirstRegistrationMutation = {
  __typename?: "Mutation";
  updateFirstRegistration?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateFirstRegistration";
        client: { __typename?: "Client"; firstRegistration: boolean };
      }
    | null;
};

export type UpdateLocationMutationVariables = Exact<{
  input: UpdateLocationInput;
}>;

export type UpdateLocationMutation = {
  __typename?: "Mutation";
  updateLocation?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateLocation";
        location: {
          __typename?: "Location";
          id: string;
          address?: string | null;
        };
      }
    | null;
};

export type UpdateStylingMutationVariables = Exact<{
  input: UpdateStylingInput;
}>;

export type UpdateStylingMutation = {
  __typename?: "Mutation";
  updateStyling?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "UpdateStyling";
        styling: {
          __typename?: "Styling";
          id: string;
          color?: string | null;
          cover?: string | null;
        };
      }
    | null;
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars["Upload"];
}>;

export type UploadFileMutation = {
  __typename?: "Mutation";
  uploadFile?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | { __typename?: "UploadFileClient"; fileName: string }
    | null;
};

export type AddEventWithoutStepsMutationVariables = Exact<{
  input: AddEventWithoutStepsInput;
}>;

export type AddEventWithoutStepsMutation = {
  __typename?: "Mutation";
  addEventWithoutSteps?:
    | {
        __typename?: "AddEventWithoutSteps";
        event?: {
          __typename?: "ClientEventWithoutSteps";
          id?: string | null;
          name?: string | null;
        } | null;
      }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type CanSentAppQueryVariables = Exact<{
  recipientId: Scalars["Int"];
  senderId: Scalars["Int"];
}>;

export type CanSentAppQuery = {
  __typename?: "Query";
  canSentApp?: boolean | null;
};

export type CheckApplicationMutationVariables = Exact<{
  checkApplicationId: Scalars["Int"];
}>;

export type CheckApplicationMutation = {
  __typename?: "Mutation";
  checkApplication?:
    | { __typename?: "CheckApplication"; checked?: boolean | null }
    | { __typename?: "Error" }
    | null;
};

export type CheckFreeAddressQueryVariables = Exact<{
  input: CheckFreeAddressInput;
}>;

export type CheckFreeAddressQuery = {
  __typename?: "Query";
  checkFreeAddress?:
    | { __typename?: "CheckFreeAddress"; free: boolean }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type CreateApplicationLongMutationVariables = Exact<{
  input: CreateApplicationLongInput;
}>;

export type CreateApplicationLongMutation = {
  __typename?: "Mutation";
  createApplicationLong?:
    | { __typename?: "CreateApplicationLong"; id?: number | null }
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | null;
};

export type GetApplicationCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetApplicationCountQuery = {
  __typename?: "Query";
  getApplicationCount: number;
};

export type GetApplicationStatusesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetApplicationStatusesQuery = {
  __typename?: "Query";
  getApplicationStatuses: Array<string | null>;
};

export type GetBrandsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBrandsQuery = {
  __typename?: "Query";
  getBrands?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetBrands";
        brands?: Array<{
          __typename?: "Brand";
          id: string;
          src: string;
        } | null> | null;
      }
    | null;
};

export type GetCasesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCasesQuery = {
  __typename?: "Query";
  getCases?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetCases";
        cases?: Array<{
          __typename?: "Case";
          id: string;
          name: string;
          text: string;
          src: string;
        } | null> | null;
      }
    | null;
};

export type GetChannelsQueryVariables = Exact<{
  input?: InputMaybe<GetChannelsInput>;
}>;

export type GetChannelsQuery = {
  __typename?: "Query";
  getChannels?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetChannels";
        channels: Array<{
          __typename?: "Channel";
          id: string;
          type: ChannelType;
          name: string;
          clientId?: string | null;
          deletedAt?: string | null;
          createdAt: string;
          updatedAt: string;
          value?: string | null;
          audience?: number | null;
        }>;
      }
    | null;
};

export type GetCitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCitiesQuery = {
  __typename?: "Query";
  getCities?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetCities";
        cities: Array<{ __typename?: "ClientCity"; id: string; name: string }>;
      }
    | {
        __typename?: "GetDictCities";
        cities: Array<{
          __typename?: "DictCity";
          id: string;
          name: string;
          countryId?: string | null;
        }>;
      }
    | null;
};

export type ClientQueryVariables = Exact<{
  input?: InputMaybe<GetMyClientInput>;
}>;

export type ClientQuery = {
  __typename?: "Query";
  getMyClient?: {
    __typename?: "GetMyClient";
    client?: {
      __typename?: "Client";
      id: string;
      type: ClientTypeEnum;
      email: string;
      name: string;
      published: boolean;
    } | null;
  } | null;
};

export type GetClientApplicationQueryVariables = Exact<{
  input: GetClientApplicationVars;
}>;

export type GetClientApplicationQuery = {
  __typename?: "Query";
  getClientApplication?: {
    __typename?: "ClientApplication";
    senderId: number;
    recipientId: number;
    createdAt: string;
    id: string;
  } | null;
};

export type GetClientApplicationByPkQueryVariables = Exact<{
  input: GetClientApplicationByPkVars;
}>;

export type GetClientApplicationByPkQuery = {
  __typename?: "Query";
  getClientApplicationByPk?: {
    __typename?: "ClientApplication";
    id: string;
    approvedByRecipient: boolean;
    approvedBySender: boolean;
    createdAt: string;
    recipientId: number;
    senderId: number;
    status: ClientAppStatusEnum;
    text: string;
    updatedAt: string;
    recipient: {
      __typename?: "ClientEntity";
      email: string;
      firstName: string;
      id: string;
      lastName: string;
      phone: string;
      position: string;
      profile: {
        __typename?: "ClientProfileEntity";
        logo: string;
        name: string;
        address?: string | null;
      };
    };
    sender: {
      __typename?: "ClientEntity";
      email: string;
      firstName: string;
      id: string;
      lastName: string;
      phone: string;
      position: string;
      profile: {
        __typename?: "ClientProfileEntity";
        logo: string;
        name: string;
        address?: string | null;
      };
    };
  } | null;
};

export type GetClientApplicationsByPkQueryVariables = Exact<{
  input: GetClientApplicationsByPkVars;
}>;

export type GetClientApplicationsByPkQuery = {
  __typename?: "Query";
  getClientApplicationsByPk?: {
    __typename?: "GetClientApplicationsByPkResponse";
    totalCount: number;
    totalPages: number;
    items?: Array<{
      __typename?: "ClientApplicationEntity";
      id: string;
      createdAt: string;
      newByRecipient: boolean;
      newBySender: boolean;
      status: ClientAppStatusEnum;
      updatedAt: string;
      recipient: {
        __typename?: "ClientEntity";
        email: string;
        firstName: string;
        id: string;
        lastName: string;
        phone: string;
        position: string;
        profile: {
          __typename?: "ClientProfileEntity";
          logo: string;
          name: string;
        };
      };
      sender: {
        __typename?: "ClientEntity";
        email: string;
        firstName: string;
        id: string;
        lastName: string;
        phone: string;
        position: string;
        profile: {
          __typename?: "ClientProfileEntity";
          logo: string;
          name: string;
        };
      };
    } | null> | null;
  } | null;
};

export type GetClientCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientCategoriesQuery = {
  __typename?: "Query";
  getClientCategories?:
    | { __typename?: "Error" }
    | {
        __typename?: "GetClientCategories";
        categories: Array<{
          __typename?: "ClientCategory";
          id: string;
          name: string;
        }>;
      }
    | null;
};

export type GetClientInterestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetClientInterestsQuery = {
  __typename?: "Query";
  getClientInterests?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetClientInterests";
        interests: Array<{
          __typename?: "ClientInterest";
          id: string;
          name: string;
        }>;
      }
    | null;
};

export type GetClientsQueryVariables = Exact<{
  input?: InputMaybe<GetClientsInput>;
}>;

export type GetClientsQuery = {
  __typename?: "Query";
  getClients: {
    __typename?: "ClientPage";
    totalPages: number;
    totalCount: number;
    items: Array<{
      __typename?: "Client";
      id: string;
      type: ClientTypeEnum;
      email: string;
      name: string;
      published: boolean;
      firstRegistration: boolean;
      tariff?: {
        __typename?: "Tariff";
        clientId: string;
        startedAt?: any | null;
        expiredAt?: any | null;
        outgoingRequests?: number | null;
        availableRequests?: number | null;
        type?: TariffTypeEnum | null;
        request?: TariffRequestEnum | null;
        assistant?: string | null;
        assistantLink?: string | null;
        paid?: TariffPaymentEnum | null;
        status?: TariffStatusEnum | null;
        createdAt?: any | null;
      } | null;
      profile?: {
        __typename?: "Profile";
        clientId: string;
        name?: string | null;
        shortDescription?: string | null;
        logo?: string | null;
        address?: string | null;
        categories?: Array<{
          __typename?: "ClientCategory";
          id: string;
          name: string;
        }> | null;
      } | null;
      audience?: {
        __typename?: "Audience";
        clientId: string;
        gender: string;
        ageSegments: string;
        interests?: Array<{
          __typename?: "Interests";
          id: string;
          name?: string | null;
        }> | null;
      } | null;
      channels?: Array<{
        __typename?: "Channel";
        id: string;
        type: ChannelType;
        name: string;
        value?: string | null;
        audience?: number | null;
        clientId?: string | null;
        deletedAt?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
    } | null>;
  };
};

export type GetContactDictQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactDictQuery = {
  __typename?: "Query";
  getContactDict?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetContactDict";
        contacts: Array<{
          __typename?: "DictContact";
          id: string;
          name: string;
        }>;
      }
    | null;
};

export type GetContactLinksQueryVariables = Exact<{
  input?: InputMaybe<GetContactLinksInput>;
}>;

export type GetContactLinksQuery = {
  __typename?: "Query";
  getContactLinks?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetContactLinks";
        contacts: Array<{
          __typename?: "ContactLink";
          id: string;
          clientId: string;
          dictionaryId: string;
          link: string;
          dictionary: { __typename?: "DictContact"; id: string; name: string };
        }>;
      }
    | null;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: "Query";
  getCountries?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetCountries";
        countries: Array<{
          __typename?: "DictCountry";
          id: string;
          name: string;
        }>;
      }
    | null;
};

export type GetDictCitiesQueryVariables = Exact<{
  input?: InputMaybe<GetDictCitiesVars>;
}>;

export type GetDictCitiesQuery = {
  __typename?: "Query";
  getDictCities?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | { __typename?: "GetCities" }
    | {
        __typename?: "GetDictCities";
        cities: Array<{
          __typename?: "DictCity";
          id: string;
          name: string;
          countryId?: string | null;
        }>;
      }
    | null;
};

export type GetEventQueryVariables = Exact<{
  getEventId: Scalars["ID"];
}>;

export type GetEventQuery = {
  __typename?: "Query";
  getEvent?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetEvent";
        event: {
          __typename?: "Event";
          id: string;
          clientId: string;
          name?: string | null;
          categoryId?: string | null;
          category?: string | null;
          cityId?: string | null;
          city?: string | null;
          themes: Array<string>;
          address?: string | null;
          dates: Array<Array<string>>;
          time?: Array<string | null> | null;
          spot?: string | null;
          image?: string | null;
          online?: boolean | null;
          offline?: boolean | null;
          isLocationUnknown?: boolean | null;
          shortDescription?: string | null;
          fullDescription?: string | null;
          website?: string | null;
          spotClient?: string | null;
          typeAudience?: string | null;
          targetAudience?: string | null;
          peopleAmountTo?: number | null;
          peopleAmountFrom?: number | null;
          free?: boolean | null;
          priceFrom?: number | null;
          priceTo?: number | null;
          priceDescription?: string | null;
          status: string;
          favorite: boolean;
          ageStatements?: string | null;
          genderStatements?: string | null;
          interests?: string | null;
          area?: string | null;
        };
        sponsorCategories: Array<{
          __typename?: "SponsorCategory";
          id: string;
          name: string;
        }>;
        packages: Array<{
          __typename?: "ClientPackage";
          id: string;
          name: string;
          price: string;
          barter?: boolean | null;
          conditions: Array<{
            __typename?: "PackageCondition";
            id: string;
            name: string;
          }>;
        }>;
        partners: Array<{
          __typename?: "EventPartner";
          id: string;
          image: string;
          name: string;
        }>;
        profile?: {
          __typename?: "ClientLowProfile";
          name?: string | null;
          logo?: string | null;
          address?: string | null;
        } | null;
      }
    | null;
};

export type GetEventApplicationQueryVariables = Exact<{
  input: GetEventApplicationVars;
}>;

export type GetEventApplicationQuery = {
  __typename?: "Query";
  getEventApplication?: {
    __typename?: "EventApplication";
    senderId: number;
    recipientId: number;
    createdAt: string;
    id: string;
    updatedAt: string;
    status: ClientAppStatusEnum;
    approvedByRecipient: boolean;
    approvedBySender: boolean;
    sender: {
      __typename?: "ClientEntity";
      id: string;
      firstName: string;
      lastName: string;
      position: string;
      phone: string;
      email: string;
      profile: {
        __typename?: "ClientProfileEntity";
        name: string;
        logo: string;
        address?: string | null;
      };
    };
    recipient: {
      __typename?: "ClientEntity";
      id: string;
      firstName: string;
      lastName: string;
      position: string;
      phone: string;
      email: string;
      profile: {
        __typename?: "ClientProfileEntity";
        name: string;
        logo: string;
        address?: string | null;
      };
    };
  } | null;
};

export type GetEventCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEventCategoriesQuery = {
  __typename?: "Query";
  getEventCategories?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetEventCategories";
        categories: Array<{
          __typename?: "EventCategoryEnum";
          id: string;
          name: string;
        }>;
      }
    | null;
};

export type GetEventInterestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEventInterestsQuery = {
  __typename?: "Query";
  getEventInterests?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetEventInterests";
        interests: Array<{
          __typename?: "EventInterestsEnum";
          id: string;
          name: string;
        }>;
      }
    | null;
};

export type GetEventStatusQueryVariables = Exact<{
  getEventStatusId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetEventStatusQuery = {
  __typename?: "Query";
  getEventStatus?: {
    __typename?: "GetEventStatusResponse";
    status?: EventStatusEnum | null;
  } | null;
};

export type GetEventStepsQueryVariables = Exact<{
  getEventStepsId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetEventStepsQuery = {
  __typename?: "Query";
  getEventSteps?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetEventSteps";
        steps: Array<{
          __typename?: "EventStep";
          filled: boolean;
          verified: boolean;
        }>;
      }
    | null;
};

export type GetEventsQueryVariables = Exact<{
  input?: InputMaybe<GetEventsInput>;
}>;

export type GetEventsQuery = {
  __typename?: "Query";
  getEvents?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetEvents";
        totalCount: number;
        totalPages: number;
        events: Array<{
          __typename?: "Event";
          id: string;
          clientId: string;
          name?: string | null;
          categoryId?: string | null;
          category?: string | null;
          cityId?: string | null;
          city?: string | null;
          themes: Array<string>;
          address?: string | null;
          dates: Array<Array<string>>;
          time?: Array<string | null> | null;
          spot?: string | null;
          image?: string | null;
          online?: boolean | null;
          offline?: boolean | null;
          isLocationUnknown?: boolean | null;
          shortDescription?: string | null;
          fullDescription?: string | null;
          website?: string | null;
          spotClient?: string | null;
          typeAudience?: string | null;
          targetAudience?: string | null;
          peopleAmountTo?: number | null;
          peopleAmountFrom?: number | null;
          free?: boolean | null;
          priceFrom?: number | null;
          priceTo?: number | null;
          priceDescription?: string | null;
          status: string;
          favorite: boolean;
          ageStatements?: string | null;
          genderStatements?: string | null;
          interests?: string | null;
          area?: string | null;
          expired: boolean;
        }>;
      }
    | null;
};

export type GetFirstRegistrationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetFirstRegistrationQuery = {
  __typename?: "Query";
  getFirstRegistration?: boolean | null;
};

export type GetImageByUrlQueryVariables = Exact<{
  url: Scalars["String"];
}>;

export type GetImageByUrlQuery = {
  __typename?: "Query";
  getImageByUrl?: string | null;
};

export type GetIsNewAppCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetIsNewAppCountQuery = {
  __typename?: "Query";
  getIsNewAppCount: {
    __typename?: "GetIsNewAppCountResponse";
    outgoingCount: number;
    incomingCount: number;
  };
};

export type GetLocationQueryVariables = Exact<{
  input?: InputMaybe<LocationInput>;
}>;

export type GetLocationQuery = {
  __typename?: "Query";
  getLocation?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetLocation";
        location?: {
          __typename?: "Location";
          id: string;
          address?: string | null;
          countries: Array<{
            __typename?: "DictCountry";
            id: string;
            name: string;
          }>;
          cities: Array<{
            __typename?: "DictCity";
            id: string;
            name: string;
            countryId?: string | null;
          }>;
        } | null;
      }
    | null;
};

export type GetMailingStatusQueryVariables = Exact<{
  getMailingStatusId: Scalars["ID"];
}>;

export type GetMailingStatusQuery = {
  __typename?: "Query";
  getMailingStatus: { __typename?: "MailingStatus"; status: boolean };
};

export type GetMyClientQueryVariables = Exact<{
  input?: InputMaybe<GetMyClientInput>;
}>;

export type GetMyClientQuery = {
  __typename?: "Query";
  getMyClient?: {
    __typename?: "GetMyClient";
    client?: {
      __typename?: "Client";
      id: string;
      type: ClientTypeEnum;
      firstName?: string | null;
      lastName?: string | null;
      position?: string | null;
      email: string;
      name: string;
      phone?: string | null;
      published: boolean;
      firstRegistration: boolean;
      active?: ClientStatus | null;
      tariff?: {
        __typename?: "Tariff";
        clientId: string;
        startedAt?: any | null;
        expiredAt?: any | null;
        outgoingRequests?: number | null;
        availableRequests?: number | null;
        type?: TariffTypeEnum | null;
        request?: TariffRequestEnum | null;
        assistant?: string | null;
        assistantLink?: string | null;
        paid?: TariffPaymentEnum | null;
        status?: TariffStatusEnum | null;
        createdAt?: any | null;
      } | null;
      profile?: {
        __typename?: "Profile";
        clientId: string;
        name?: string | null;
        shortDescription?: string | null;
        logo?: string | null;
        address?: string | null;
        categories?: Array<{
          __typename?: "ClientCategory";
          id: string;
          name: string;
        }> | null;
      } | null;
      audience?: {
        __typename?: "Audience";
        clientId: string;
        gender: string;
        ageSegments: string;
        interests?: Array<{
          __typename?: "Interests";
          id: string;
          name?: string | null;
        }> | null;
      } | null;
      channels?: Array<{
        __typename?: "Channel";
        id: string;
        type: ChannelType;
        name: string;
        value?: string | null;
        audience?: number | null;
        clientId?: string | null;
        deletedAt?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
    } | null;
  } | null;
};

export type ClientAudienceQueryVariables = Exact<{
  input?: InputMaybe<GetMyClientAudienceInput>;
}>;

export type ClientAudienceQuery = {
  __typename?: "Query";
  getMyClientAudience?: {
    __typename?: "GetMyClientAudience";
    clientAudience?: {
      __typename?: "ClientAudience";
      clientId: string;
      gender: string;
      ageSegments: string;
      interests: Array<{
        __typename?: "ClientInterest";
        id: string;
        name: string;
      }>;
    } | null;
  } | null;
};

export type GetMyClientPartnershipQueryVariables = Exact<{
  input?: InputMaybe<GetMyClientPartnershipInput>;
}>;

export type GetMyClientPartnershipQuery = {
  __typename?: "Query";
  getMyClientPartnership?: {
    __typename?: "GetMyClientPartnership";
    clientPartnership?: {
      __typename?: "ClientPartnership";
      clientId: string;
      categories: Array<{
        __typename?: "ClientCategory";
        id: string;
        name: string;
      }>;
      interested_in_channels: Array<{
        __typename?: "Channel";
        id: string;
        type: ChannelType;
        name: string;
        clientId?: string | null;
        deletedAt?: string | null;
        createdAt: string;
        updatedAt: string;
        value?: string | null;
        audience?: number | null;
      }>;
      possibilities_channels: Array<{
        __typename?: "Channel";
        id: string;
        type: ChannelType;
        name: string;
        clientId?: string | null;
        deletedAt?: string | null;
        createdAt: string;
        updatedAt: string;
        value?: string | null;
        audience?: number | null;
      }>;
    } | null;
  } | null;
};

export type ClientProfileQueryVariables = Exact<{
  input?: InputMaybe<GetMyClientProfileInput>;
}>;

export type ClientProfileQuery = {
  __typename?: "Query";
  getMyClientProfile?: {
    __typename?: "GetMyClientProfile";
    clientProfile?: {
      __typename?: "ClientProfile";
      clientId: string;
      name?: string | null;
      address: string;
      model?: string | null;
      logo?: string | null;
      shortDescription?: string | null;
      description?: string | null;
      categories: Array<{
        __typename?: "ClientCategory";
        id: string;
        name: string;
      }>;
    } | null;
  } | null;
};

export type GetSponsorCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSponsorCategoriesQuery = {
  __typename?: "Query";
  getSponsorCategories?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetSponsorCategories";
        categories: Array<{
          __typename?: "SponsorCategoryEnum";
          id: string;
          name: string;
        }>;
      }
    | null;
};

export type GetStylingQueryVariables = Exact<{
  input?: InputMaybe<GetStylingVars>;
}>;

export type GetStylingQuery = {
  __typename?: "Query";
  getStyling?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetStyling";
        styling?: {
          __typename?: "Styling";
          id: string;
          color?: string | null;
          cover?: string | null;
        } | null;
      }
    | null;
};

export type GetThemeTagsQueryVariables = Exact<{ [key: string]: never }>;

export type GetThemeTagsQuery = {
  __typename?: "Query";
  getThemeTags?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetThemeTags";
        tags: Array<{ __typename?: "ThemeTagEnum"; id: string; name: string }>;
      }
    | null;
};

export type GetTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTypesQuery = {
  __typename?: "Query";
  getTypes?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetTypes";
        types?: Array<{
          __typename?: "Type";
          id: string;
          name: string;
        } | null> | null;
      }
    | null;
};

export type GetUpdateEventDescriptionInfoQueryVariables = Exact<{
  getUpdateEventDescriptionInfoId: Scalars["ID"];
}>;

export type GetUpdateEventDescriptionInfoQuery = {
  __typename?: "Query";
  getUpdateEventDescriptionInfo?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetUpdateEventDescriptionInfo";
        shortDescription?: string | null;
        fullDescription?: string | null;
        image?: string | null;
      }
    | null;
};

export type GetUpdateEventInfoQueryVariables = Exact<{
  getUpdateEventInfoId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetUpdateEventInfoQuery = {
  __typename?: "Query";
  getUpdateEventInfo?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetUpdateEventInfo";
        cities?: Array<{
          __typename?: "Data";
          id?: string | null;
          name?: string | null;
        } | null> | null;
        categories?: Array<{
          __typename?: "Data";
          id?: string | null;
          name?: string | null;
        } | null> | null;
        tags?: Array<{
          __typename?: "Data";
          id?: string | null;
          name?: string | null;
        } | null> | null;
        event?: {
          __typename?: "UpdateEventInfo1";
          name?: string | null;
          website?: string | null;
          online?: boolean | null;
          offline?: boolean | null;
          isLocationUnknown?: boolean | null;
          area?: string | null;
          address?: string | null;
          themes?: Array<string | null> | null;
          category?: string | null;
          dates?: Array<Array<any | null> | null> | null;
          times?: Array<string | null> | null;
          city?: string | null;
          categoryId: number;
          cityId?: number | null;
        } | null;
      }
    | null;
};

export type GetUpdateEventSponsorsInfoQueryVariables = Exact<{
  getUpdateEventSponsorsInfoId: Scalars["ID"];
}>;

export type GetUpdateEventSponsorsInfoQuery = {
  __typename?: "Query";
  getUpdateEventSponsorsInfo?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetUpdateEventSponsorsInfo";
        sponsorCategories: Array<string | null>;
        allCategories?: boolean | null;
        addPackages?: boolean | null;
        packages: Array<{
          __typename?: "ClientPackage";
          id: string;
          name: string;
          price: string;
          barter?: boolean | null;
          conditions: Array<{
            __typename?: "PackageCondition";
            id: string;
            name: string;
          }>;
        } | null>;
        categories?: Array<{
          __typename?: "Data";
          id?: string | null;
          name?: string | null;
        } | null> | null;
      }
    | null;
};

export type GetUpdateTargetsInfoQueryVariables = Exact<{
  getUpdateTargetsInfoId: Scalars["ID"];
}>;

export type GetUpdateTargetsInfoQuery = {
  __typename?: "Query";
  getUpdateTargetsInfo?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "GetUpdateTargetsInfo";
        target: {
          __typename?: "UpdateTargetsInfo1";
          peopleAmountFrom?: string | null;
          onlyPeopleAmountFrom?: boolean | null;
          peopleAmountTo?: string | null;
          priceFrom?: string | null;
          priceTo?: string | null;
          model?: string | null;
          ageStatements?: string | null;
          genderStatements?: string | null;
          free?: boolean | null;
          priceDescription?: string | null;
          eventInterest?: Array<{
            __typename?: "InterestsData";
            id?: string | null;
            name?: string | null;
          } | null> | null;
        };
        interests?: Array<{
          __typename?: "InterestsData";
          id?: string | null;
          name?: string | null;
        } | null> | null;
      }
    | null;
};

export type MakeModerationEventMutationVariables = Exact<{
  makeModerationEventId: Scalars["ID"];
}>;

export type MakeModerationEventMutation = {
  __typename?: "Mutation";
  makeModerationEvent?:
    | {
        __typename?: "Error";
        error: string;
        warnings?: Array<{
          __typename?: "Warning";
          key: string;
          warning: string;
        } | null> | null;
      }
    | {
        __typename?: "MakeModerationEvent";
        event?: {
          __typename?: "ClientMakeModerationEvent";
          name?: string | null;
        } | null;
      }
    | null;
};

export const AddEventStep1Document = gql`
  mutation AddEventStep1($input: AddEventStep1Input!) {
    addEventStep1(input: $input) {
      ... on AddEventStep1 {
        event {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type AddEventStep1MutationFn = Apollo.MutationFunction<
  AddEventStep1Mutation,
  AddEventStep1MutationVariables
>;

/**
 * __useAddEventStep1Mutation__
 *
 * To run a mutation, you first call `useAddEventStep1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventStep1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventStep1Mutation, { data, loading, error }] = useAddEventStep1Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventStep1Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEventStep1Mutation,
    AddEventStep1MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEventStep1Mutation,
    AddEventStep1MutationVariables
  >(AddEventStep1Document, options);
}
export type AddEventStep1MutationHookResult = ReturnType<
  typeof useAddEventStep1Mutation
>;
export type AddEventStep1MutationResult =
  Apollo.MutationResult<AddEventStep1Mutation>;
export type AddEventStep1MutationOptions = Apollo.BaseMutationOptions<
  AddEventStep1Mutation,
  AddEventStep1MutationVariables
>;
export const AddEventStep2Document = gql`
  mutation AddEventStep2($addEventStep2Id: ID!, $input: AddEventStep2Input!) {
    addEventStep2(id: $addEventStep2Id, input: $input) {
      ... on AddEventStep2 {
        event {
          id
          message
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type AddEventStep2MutationFn = Apollo.MutationFunction<
  AddEventStep2Mutation,
  AddEventStep2MutationVariables
>;

/**
 * __useAddEventStep2Mutation__
 *
 * To run a mutation, you first call `useAddEventStep2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventStep2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventStep2Mutation, { data, loading, error }] = useAddEventStep2Mutation({
 *   variables: {
 *      addEventStep2Id: // value for 'addEventStep2Id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventStep2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEventStep2Mutation,
    AddEventStep2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEventStep2Mutation,
    AddEventStep2MutationVariables
  >(AddEventStep2Document, options);
}
export type AddEventStep2MutationHookResult = ReturnType<
  typeof useAddEventStep2Mutation
>;
export type AddEventStep2MutationResult =
  Apollo.MutationResult<AddEventStep2Mutation>;
export type AddEventStep2MutationOptions = Apollo.BaseMutationOptions<
  AddEventStep2Mutation,
  AddEventStep2MutationVariables
>;
export const AddEventStep3Document = gql`
  mutation AddEventStep3($addEventStep3Id: ID!, $input: AddEventStep3Input!) {
    addEventStep3(id: $addEventStep3Id, input: $input) {
      ... on AddEventStep3 {
        event
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type AddEventStep3MutationFn = Apollo.MutationFunction<
  AddEventStep3Mutation,
  AddEventStep3MutationVariables
>;

/**
 * __useAddEventStep3Mutation__
 *
 * To run a mutation, you first call `useAddEventStep3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventStep3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventStep3Mutation, { data, loading, error }] = useAddEventStep3Mutation({
 *   variables: {
 *      addEventStep3Id: // value for 'addEventStep3Id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventStep3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEventStep3Mutation,
    AddEventStep3MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEventStep3Mutation,
    AddEventStep3MutationVariables
  >(AddEventStep3Document, options);
}
export type AddEventStep3MutationHookResult = ReturnType<
  typeof useAddEventStep3Mutation
>;
export type AddEventStep3MutationResult =
  Apollo.MutationResult<AddEventStep3Mutation>;
export type AddEventStep3MutationOptions = Apollo.BaseMutationOptions<
  AddEventStep3Mutation,
  AddEventStep3MutationVariables
>;
export const AddEventStep4Document = gql`
  mutation AddEventStep4($addEventStep4Id: ID!, $input: AddEventStep4Input!) {
    addEventStep4(id: $addEventStep4Id, input: $input) {
      ... on AddEventStep4 {
        event
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type AddEventStep4MutationFn = Apollo.MutationFunction<
  AddEventStep4Mutation,
  AddEventStep4MutationVariables
>;

/**
 * __useAddEventStep4Mutation__
 *
 * To run a mutation, you first call `useAddEventStep4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventStep4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventStep4Mutation, { data, loading, error }] = useAddEventStep4Mutation({
 *   variables: {
 *      addEventStep4Id: // value for 'addEventStep4Id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventStep4Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEventStep4Mutation,
    AddEventStep4MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEventStep4Mutation,
    AddEventStep4MutationVariables
  >(AddEventStep4Document, options);
}
export type AddEventStep4MutationHookResult = ReturnType<
  typeof useAddEventStep4Mutation
>;
export type AddEventStep4MutationResult =
  Apollo.MutationResult<AddEventStep4Mutation>;
export type AddEventStep4MutationOptions = Apollo.BaseMutationOptions<
  AddEventStep4Mutation,
  AddEventStep4MutationVariables
>;
export const ApproveApplicationDocument = gql`
  mutation ApproveApplication($input: ApproveApplicationVars) {
    approveApplication(input: $input) {
      ... on ClientApplication {
        id
        senderId
        recipientId
        createdAt
        updatedAt
        status
        approvedByRecipient
        approvedBySender
        text
        sender {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
        recipient {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type ApproveApplicationMutationFn = Apollo.MutationFunction<
  ApproveApplicationMutation,
  ApproveApplicationMutationVariables
>;

/**
 * __useApproveApplicationMutation__
 *
 * To run a mutation, you first call `useApproveApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveApplicationMutation, { data, loading, error }] = useApproveApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveApplicationMutation,
    ApproveApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveApplicationMutation,
    ApproveApplicationMutationVariables
  >(ApproveApplicationDocument, options);
}
export type ApproveApplicationMutationHookResult = ReturnType<
  typeof useApproveApplicationMutation
>;
export type ApproveApplicationMutationResult =
  Apollo.MutationResult<ApproveApplicationMutation>;
export type ApproveApplicationMutationOptions = Apollo.BaseMutationOptions<
  ApproveApplicationMutation,
  ApproveApplicationMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      ... on ChangePassword {
        client {
          id
          type
          email
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const CreateApplicationShortDocument = gql`
  mutation CreateApplicationShort($input: CreateApplicationShortInput!) {
    createApplicationShort(input: $input) {
      ... on CreateApplicationShort {
        id
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type CreateApplicationShortMutationFn = Apollo.MutationFunction<
  CreateApplicationShortMutation,
  CreateApplicationShortMutationVariables
>;

/**
 * __useCreateApplicationShortMutation__
 *
 * To run a mutation, you first call `useCreateApplicationShortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationShortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationShortMutation, { data, loading, error }] = useCreateApplicationShortMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationShortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationShortMutation,
    CreateApplicationShortMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationShortMutation,
    CreateApplicationShortMutationVariables
  >(CreateApplicationShortDocument, options);
}
export type CreateApplicationShortMutationHookResult = ReturnType<
  typeof useCreateApplicationShortMutation
>;
export type CreateApplicationShortMutationResult =
  Apollo.MutationResult<CreateApplicationShortMutation>;
export type CreateApplicationShortMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationShortMutation,
  CreateApplicationShortMutationVariables
>;
export const CreateClientApplicationDocument = gql`
  mutation CreateClientApplication($input: ClientApplicationInput!) {
    createClientApplication(input: $input) {
      success
      message
    }
  }
`;
export type CreateClientApplicationMutationFn = Apollo.MutationFunction<
  CreateClientApplicationMutation,
  CreateClientApplicationMutationVariables
>;

/**
 * __useCreateClientApplicationMutation__
 *
 * To run a mutation, you first call `useCreateClientApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientApplicationMutation, { data, loading, error }] = useCreateClientApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientApplicationMutation,
    CreateClientApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientApplicationMutation,
    CreateClientApplicationMutationVariables
  >(CreateClientApplicationDocument, options);
}
export type CreateClientApplicationMutationHookResult = ReturnType<
  typeof useCreateClientApplicationMutation
>;
export type CreateClientApplicationMutationResult =
  Apollo.MutationResult<CreateClientApplicationMutation>;
export type CreateClientApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateClientApplicationMutation,
  CreateClientApplicationMutationVariables
>;
export const CreateContactLinkDocument = gql`
  mutation CreateContactLink($input: CreateContactLinkInput!) {
    createContactLink(input: $input) {
      ... on CreateContactLink {
        contact {
          id
          clientId
          dictionaryId
          link
          dictionary {
            id
            name
          }
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type CreateContactLinkMutationFn = Apollo.MutationFunction<
  CreateContactLinkMutation,
  CreateContactLinkMutationVariables
>;

/**
 * __useCreateContactLinkMutation__
 *
 * To run a mutation, you first call `useCreateContactLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactLinkMutation, { data, loading, error }] = useCreateContactLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContactLinkMutation,
    CreateContactLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateContactLinkMutation,
    CreateContactLinkMutationVariables
  >(CreateContactLinkDocument, options);
}
export type CreateContactLinkMutationHookResult = ReturnType<
  typeof useCreateContactLinkMutation
>;
export type CreateContactLinkMutationResult =
  Apollo.MutationResult<CreateContactLinkMutation>;
export type CreateContactLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateContactLinkMutation,
  CreateContactLinkMutationVariables
>;
export const CreateEventApplicationDocument = gql`
  mutation CreateEventApplication($input: EventApplicationInput!) {
    createEventApplication(input: $input) {
      message
      success
    }
  }
`;
export type CreateEventApplicationMutationFn = Apollo.MutationFunction<
  CreateEventApplicationMutation,
  CreateEventApplicationMutationVariables
>;

/**
 * __useCreateEventApplicationMutation__
 *
 * To run a mutation, you first call `useCreateEventApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventApplicationMutation, { data, loading, error }] = useCreateEventApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventApplicationMutation,
    CreateEventApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEventApplicationMutation,
    CreateEventApplicationMutationVariables
  >(CreateEventApplicationDocument, options);
}
export type CreateEventApplicationMutationHookResult = ReturnType<
  typeof useCreateEventApplicationMutation
>;
export type CreateEventApplicationMutationResult =
  Apollo.MutationResult<CreateEventApplicationMutation>;
export type CreateEventApplicationMutationOptions = Apollo.BaseMutationOptions<
  CreateEventApplicationMutation,
  CreateEventApplicationMutationVariables
>;
export const CreateLocationDocument = gql`
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
      ... on CreateLocation {
        location {
          id
          address
        }
      }
    }
  }
`;
export type CreateLocationMutationFn = Apollo.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >(CreateLocationDocument, options);
}
export type CreateLocationMutationHookResult = ReturnType<
  typeof useCreateLocationMutation
>;
export type CreateLocationMutationResult =
  Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export const CreateStylingDocument = gql`
  mutation CreateStyling($input: CreateStylingInput!) {
    createStyling(input: $input) {
      ... on CreateStyling {
        styling {
          id
          color
          cover
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type CreateStylingMutationFn = Apollo.MutationFunction<
  CreateStylingMutation,
  CreateStylingMutationVariables
>;

/**
 * __useCreateStylingMutation__
 *
 * To run a mutation, you first call `useCreateStylingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStylingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStylingMutation, { data, loading, error }] = useCreateStylingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStylingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStylingMutation,
    CreateStylingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStylingMutation,
    CreateStylingMutationVariables
  >(CreateStylingDocument, options);
}
export type CreateStylingMutationHookResult = ReturnType<
  typeof useCreateStylingMutation
>;
export type CreateStylingMutationResult =
  Apollo.MutationResult<CreateStylingMutation>;
export type CreateStylingMutationOptions = Apollo.BaseMutationOptions<
  CreateStylingMutation,
  CreateStylingMutationVariables
>;
export const DeleteContactLinkDocument = gql`
  mutation DeleteContactLink($input: DeleteContactLinkInput!) {
    deleteContactLink(input: $input) {
      ... on DeleteContactLink {
        contact {
          id
          clientId
          dictionaryId
          link
          dictionary {
            id
            name
          }
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type DeleteContactLinkMutationFn = Apollo.MutationFunction<
  DeleteContactLinkMutation,
  DeleteContactLinkMutationVariables
>;

/**
 * __useDeleteContactLinkMutation__
 *
 * To run a mutation, you first call `useDeleteContactLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactLinkMutation, { data, loading, error }] = useDeleteContactLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteContactLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteContactLinkMutation,
    DeleteContactLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteContactLinkMutation,
    DeleteContactLinkMutationVariables
  >(DeleteContactLinkDocument, options);
}
export type DeleteContactLinkMutationHookResult = ReturnType<
  typeof useDeleteContactLinkMutation
>;
export type DeleteContactLinkMutationResult =
  Apollo.MutationResult<DeleteContactLinkMutation>;
export type DeleteContactLinkMutationOptions = Apollo.BaseMutationOptions<
  DeleteContactLinkMutation,
  DeleteContactLinkMutationVariables
>;
export const DeleteEventDocument = gql`
  mutation DeleteEvent($deleteEventId: ID!) {
    deleteEvent(id: $deleteEventId) {
      ... on DeleteEvent {
        event {
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type DeleteEventMutationFn = Apollo.MutationFunction<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      deleteEventId: // value for 'deleteEventId'
 *   },
 * });
 */
export function useDeleteEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEventMutation,
    DeleteEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(
    DeleteEventDocument,
    options
  );
}
export type DeleteEventMutationHookResult = ReturnType<
  typeof useDeleteEventMutation
>;
export type DeleteEventMutationResult =
  Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventMutation,
  DeleteEventMutationVariables
>;
export const MakeDraftEventDocument = gql`
  mutation MakeDraftEvent($makeDraftEventId: ID!) {
    makeDraftEvent(id: $makeDraftEventId) {
      ... on MakeDraftEvent {
        event {
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type MakeDraftEventMutationFn = Apollo.MutationFunction<
  MakeDraftEventMutation,
  MakeDraftEventMutationVariables
>;

/**
 * __useMakeDraftEventMutation__
 *
 * To run a mutation, you first call `useMakeDraftEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeDraftEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeDraftEventMutation, { data, loading, error }] = useMakeDraftEventMutation({
 *   variables: {
 *      makeDraftEventId: // value for 'makeDraftEventId'
 *   },
 * });
 */
export function useMakeDraftEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeDraftEventMutation,
    MakeDraftEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeDraftEventMutation,
    MakeDraftEventMutationVariables
  >(MakeDraftEventDocument, options);
}
export type MakeDraftEventMutationHookResult = ReturnType<
  typeof useMakeDraftEventMutation
>;
export type MakeDraftEventMutationResult =
  Apollo.MutationResult<MakeDraftEventMutation>;
export type MakeDraftEventMutationOptions = Apollo.BaseMutationOptions<
  MakeDraftEventMutation,
  MakeDraftEventMutationVariables
>;
export const MakePublicEventDocument = gql`
  mutation MakePublicEvent($makePublicEventId: ID!) {
    makePublicEvent(id: $makePublicEventId) {
      ... on MakePublicEvent {
        event {
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type MakePublicEventMutationFn = Apollo.MutationFunction<
  MakePublicEventMutation,
  MakePublicEventMutationVariables
>;

/**
 * __useMakePublicEventMutation__
 *
 * To run a mutation, you first call `useMakePublicEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakePublicEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makePublicEventMutation, { data, loading, error }] = useMakePublicEventMutation({
 *   variables: {
 *      makePublicEventId: // value for 'makePublicEventId'
 *   },
 * });
 */
export function useMakePublicEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakePublicEventMutation,
    MakePublicEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakePublicEventMutation,
    MakePublicEventMutationVariables
  >(MakePublicEventDocument, options);
}
export type MakePublicEventMutationHookResult = ReturnType<
  typeof useMakePublicEventMutation
>;
export type MakePublicEventMutationResult =
  Apollo.MutationResult<MakePublicEventMutation>;
export type MakePublicEventMutationOptions = Apollo.BaseMutationOptions<
  MakePublicEventMutation,
  MakePublicEventMutationVariables
>;
export const PublishClientDocument = gql`
  mutation PublishClient($published: Boolean!) {
    publishClient(published: $published) {
      success
    }
  }
`;
export type PublishClientMutationFn = Apollo.MutationFunction<
  PublishClientMutation,
  PublishClientMutationVariables
>;

/**
 * __usePublishClientMutation__
 *
 * To run a mutation, you first call `usePublishClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishClientMutation, { data, loading, error }] = usePublishClientMutation({
 *   variables: {
 *      published: // value for 'published'
 *   },
 * });
 */
export function usePublishClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishClientMutation,
    PublishClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishClientMutation,
    PublishClientMutationVariables
  >(PublishClientDocument, options);
}
export type PublishClientMutationHookResult = ReturnType<
  typeof usePublishClientMutation
>;
export type PublishClientMutationResult =
  Apollo.MutationResult<PublishClientMutation>;
export type PublishClientMutationOptions = Apollo.BaseMutationOptions<
  PublishClientMutation,
  PublishClientMutationVariables
>;
export const RemoveFromPublicationDocument = gql`
  mutation RemoveFromPublication($removeFromPublicationId: ID!) {
    removeFromPublication(id: $removeFromPublicationId) {
      ... on RemoveFromPublication {
        event {
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type RemoveFromPublicationMutationFn = Apollo.MutationFunction<
  RemoveFromPublicationMutation,
  RemoveFromPublicationMutationVariables
>;

/**
 * __useRemoveFromPublicationMutation__
 *
 * To run a mutation, you first call `useRemoveFromPublicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromPublicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromPublicationMutation, { data, loading, error }] = useRemoveFromPublicationMutation({
 *   variables: {
 *      removeFromPublicationId: // value for 'removeFromPublicationId'
 *   },
 * });
 */
export function useRemoveFromPublicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromPublicationMutation,
    RemoveFromPublicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveFromPublicationMutation,
    RemoveFromPublicationMutationVariables
  >(RemoveFromPublicationDocument, options);
}
export type RemoveFromPublicationMutationHookResult = ReturnType<
  typeof useRemoveFromPublicationMutation
>;
export type RemoveFromPublicationMutationResult =
  Apollo.MutationResult<RemoveFromPublicationMutation>;
export type RemoveFromPublicationMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromPublicationMutation,
  RemoveFromPublicationMutationVariables
>;
export const ReportByErrorDocument = gql`
  mutation ReportByError($input: ReportByErrorVars!) {
    reportByError(input: $input) {
      ... on ClientApplication {
        id
        approvedByRecipient
        approvedBySender
        createdAt
        recipient {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
        recipientId
        sender {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
        senderId
        status
        text
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type ReportByErrorMutationFn = Apollo.MutationFunction<
  ReportByErrorMutation,
  ReportByErrorMutationVariables
>;

/**
 * __useReportByErrorMutation__
 *
 * To run a mutation, you first call `useReportByErrorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportByErrorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportByErrorMutation, { data, loading, error }] = useReportByErrorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportByErrorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportByErrorMutation,
    ReportByErrorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReportByErrorMutation,
    ReportByErrorMutationVariables
  >(ReportByErrorDocument, options);
}
export type ReportByErrorMutationHookResult = ReturnType<
  typeof useReportByErrorMutation
>;
export type ReportByErrorMutationResult =
  Apollo.MutationResult<ReportByErrorMutation>;
export type ReportByErrorMutationOptions = Apollo.BaseMutationOptions<
  ReportByErrorMutation,
  ReportByErrorMutationVariables
>;
export const ReportBySpamDocument = gql`
  mutation ReportBySpam($input: ReportBySpamVars!) {
    reportBySpam(input: $input) {
      ... on ClientApplication {
        id
        approvedByRecipient
        approvedBySender
        createdAt
        recipient {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
        recipientId
        sender {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
        senderId
        status
        text
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type ReportBySpamMutationFn = Apollo.MutationFunction<
  ReportBySpamMutation,
  ReportBySpamMutationVariables
>;

/**
 * __useReportBySpamMutation__
 *
 * To run a mutation, you first call `useReportBySpamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportBySpamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportBySpamMutation, { data, loading, error }] = useReportBySpamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportBySpamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportBySpamMutation,
    ReportBySpamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReportBySpamMutation,
    ReportBySpamMutationVariables
  >(ReportBySpamDocument, options);
}
export type ReportBySpamMutationHookResult = ReturnType<
  typeof useReportBySpamMutation
>;
export type ReportBySpamMutationResult =
  Apollo.MutationResult<ReportBySpamMutation>;
export type ReportBySpamMutationOptions = Apollo.BaseMutationOptions<
  ReportBySpamMutation,
  ReportBySpamMutationVariables
>;
export const RequestTariffDocument = gql`
  mutation RequestTariff($input: RequestTariffInput!) {
    requestTariff(input: $input) {
      ... on RequestTariff {
        tariff {
          clientId
          startedAt
          expiredAt
          outgoingRequests
          availableRequests
          type
          request
          assistant
          assistantLink
          paid
          status
          createdAt
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type RequestTariffMutationFn = Apollo.MutationFunction<
  RequestTariffMutation,
  RequestTariffMutationVariables
>;

/**
 * __useRequestTariffMutation__
 *
 * To run a mutation, you first call `useRequestTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTariffMutation, { data, loading, error }] = useRequestTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestTariffMutation,
    RequestTariffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestTariffMutation,
    RequestTariffMutationVariables
  >(RequestTariffDocument, options);
}
export type RequestTariffMutationHookResult = ReturnType<
  typeof useRequestTariffMutation
>;
export type RequestTariffMutationResult =
  Apollo.MutationResult<RequestTariffMutation>;
export type RequestTariffMutationOptions = Apollo.BaseMutationOptions<
  RequestTariffMutation,
  RequestTariffMutationVariables
>;
export const RequestTariffProlongDocument = gql`
  mutation RequestTariffProlong($input: RequestTariffProlongInput!) {
    requestTariffProlong(input: $input) {
      ... on RequestTariff {
        tariff {
          clientId
          startedAt
          expiredAt
          outgoingRequests
          availableRequests
          type
          request
          assistant
          assistantLink
          paid
          status
          createdAt
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type RequestTariffProlongMutationFn = Apollo.MutationFunction<
  RequestTariffProlongMutation,
  RequestTariffProlongMutationVariables
>;

/**
 * __useRequestTariffProlongMutation__
 *
 * To run a mutation, you first call `useRequestTariffProlongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTariffProlongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTariffProlongMutation, { data, loading, error }] = useRequestTariffProlongMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestTariffProlongMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestTariffProlongMutation,
    RequestTariffProlongMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestTariffProlongMutation,
    RequestTariffProlongMutationVariables
  >(RequestTariffProlongDocument, options);
}
export type RequestTariffProlongMutationHookResult = ReturnType<
  typeof useRequestTariffProlongMutation
>;
export type RequestTariffProlongMutationResult =
  Apollo.MutationResult<RequestTariffProlongMutation>;
export type RequestTariffProlongMutationOptions = Apollo.BaseMutationOptions<
  RequestTariffProlongMutation,
  RequestTariffProlongMutationVariables
>;
export const ToggleMailingListDocument = gql`
  mutation ToggleMailingList($input: ToggleMailingListInput!) {
    toggleMailingList(input: $input) {
      mailing {
        id
        title
        status
      }
    }
  }
`;
export type ToggleMailingListMutationFn = Apollo.MutationFunction<
  ToggleMailingListMutation,
  ToggleMailingListMutationVariables
>;

/**
 * __useToggleMailingListMutation__
 *
 * To run a mutation, you first call `useToggleMailingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMailingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMailingListMutation, { data, loading, error }] = useToggleMailingListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleMailingListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleMailingListMutation,
    ToggleMailingListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ToggleMailingListMutation,
    ToggleMailingListMutationVariables
  >(ToggleMailingListDocument, options);
}
export type ToggleMailingListMutationHookResult = ReturnType<
  typeof useToggleMailingListMutation
>;
export type ToggleMailingListMutationResult =
  Apollo.MutationResult<ToggleMailingListMutation>;
export type ToggleMailingListMutationOptions = Apollo.BaseMutationOptions<
  ToggleMailingListMutation,
  ToggleMailingListMutationVariables
>;
export const UpdateApplicationStatusByPkDocument = gql`
  mutation UpdateApplicationStatusByPk(
    $input: UpdateApplicationStatusByPkVars!
  ) {
    updateApplicationStatusByPk(input: $input) {
      ... on ClientApplication {
        id
        senderId
        recipientId
        createdAt
        updatedAt
        status
        approvedByRecipient
        approvedBySender
        text
        sender {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
        recipient {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateApplicationStatusByPkMutationFn = Apollo.MutationFunction<
  UpdateApplicationStatusByPkMutation,
  UpdateApplicationStatusByPkMutationVariables
>;

/**
 * __useUpdateApplicationStatusByPkMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationStatusByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationStatusByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationStatusByPkMutation, { data, loading, error }] = useUpdateApplicationStatusByPkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApplicationStatusByPkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateApplicationStatusByPkMutation,
    UpdateApplicationStatusByPkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateApplicationStatusByPkMutation,
    UpdateApplicationStatusByPkMutationVariables
  >(UpdateApplicationStatusByPkDocument, options);
}
export type UpdateApplicationStatusByPkMutationHookResult = ReturnType<
  typeof useUpdateApplicationStatusByPkMutation
>;
export type UpdateApplicationStatusByPkMutationResult =
  Apollo.MutationResult<UpdateApplicationStatusByPkMutation>;
export type UpdateApplicationStatusByPkMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateApplicationStatusByPkMutation,
    UpdateApplicationStatusByPkMutationVariables
  >;
export const UpdateChannelResourcesDocument = gql`
  mutation UpdateChannelResources($input: UpdateChannelResourcesInput!) {
    updateChannelResources(input: $input) {
      ... on UpdateChannelResources {
        channelResources {
          channelId
          name
          audience
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateChannelResourcesMutationFn = Apollo.MutationFunction<
  UpdateChannelResourcesMutation,
  UpdateChannelResourcesMutationVariables
>;

/**
 * __useUpdateChannelResourcesMutation__
 *
 * To run a mutation, you first call `useUpdateChannelResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelResourcesMutation, { data, loading, error }] = useUpdateChannelResourcesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelResourcesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChannelResourcesMutation,
    UpdateChannelResourcesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChannelResourcesMutation,
    UpdateChannelResourcesMutationVariables
  >(UpdateChannelResourcesDocument, options);
}
export type UpdateChannelResourcesMutationHookResult = ReturnType<
  typeof useUpdateChannelResourcesMutation
>;
export type UpdateChannelResourcesMutationResult =
  Apollo.MutationResult<UpdateChannelResourcesMutation>;
export type UpdateChannelResourcesMutationOptions = Apollo.BaseMutationOptions<
  UpdateChannelResourcesMutation,
  UpdateChannelResourcesMutationVariables
>;
export const UpdateClientAudienceDocument = gql`
  mutation UpdateClientAudience($input: UpdateClientAudienceInput!) {
    updateClientAudience(input: $input) {
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
      ... on UpdateClientAudience {
        clientAudience {
          clientId
          gender
          ageSegments
          interests {
            id
            name
          }
        }
      }
    }
  }
`;
export type UpdateClientAudienceMutationFn = Apollo.MutationFunction<
  UpdateClientAudienceMutation,
  UpdateClientAudienceMutationVariables
>;

/**
 * __useUpdateClientAudienceMutation__
 *
 * To run a mutation, you first call `useUpdateClientAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientAudienceMutation, { data, loading, error }] = useUpdateClientAudienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientAudienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientAudienceMutation,
    UpdateClientAudienceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientAudienceMutation,
    UpdateClientAudienceMutationVariables
  >(UpdateClientAudienceDocument, options);
}
export type UpdateClientAudienceMutationHookResult = ReturnType<
  typeof useUpdateClientAudienceMutation
>;
export type UpdateClientAudienceMutationResult =
  Apollo.MutationResult<UpdateClientAudienceMutation>;
export type UpdateClientAudienceMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientAudienceMutation,
  UpdateClientAudienceMutationVariables
>;
export const UpdateClientPartnershipDocument = gql`
  mutation UpdateClientPartnership(
    $partnership: UpdateClientPartnershipInput!
  ) {
    updateClientPartnership(partnership: $partnership) {
      ... on UpdateClientPartnership {
        partnership {
          clientId
          categories {
            id
            name
          }
          interested_in_channels {
            id
            type
            name
            clientId
            deletedAt
            createdAt
            updatedAt
            value
            audience
          }
          possibilities_channels {
            id
            type
            name
            clientId
            deletedAt
            createdAt
            updatedAt
            value
            audience
          }
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateClientPartnershipMutationFn = Apollo.MutationFunction<
  UpdateClientPartnershipMutation,
  UpdateClientPartnershipMutationVariables
>;

/**
 * __useUpdateClientPartnershipMutation__
 *
 * To run a mutation, you first call `useUpdateClientPartnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientPartnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientPartnershipMutation, { data, loading, error }] = useUpdateClientPartnershipMutation({
 *   variables: {
 *      partnership: // value for 'partnership'
 *   },
 * });
 */
export function useUpdateClientPartnershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientPartnershipMutation,
    UpdateClientPartnershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientPartnershipMutation,
    UpdateClientPartnershipMutationVariables
  >(UpdateClientPartnershipDocument, options);
}
export type UpdateClientPartnershipMutationHookResult = ReturnType<
  typeof useUpdateClientPartnershipMutation
>;
export type UpdateClientPartnershipMutationResult =
  Apollo.MutationResult<UpdateClientPartnershipMutation>;
export type UpdateClientPartnershipMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientPartnershipMutation,
  UpdateClientPartnershipMutationVariables
>;
export const UpdateClientProfileDocument = gql`
  mutation UpdateClientProfile($input: UpdateClientProfileInput!) {
    updateClientProfile(input: $input) {
      ... on UpdateClientProfile {
        client {
          id
          type
          email
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateClientProfileMutationFn = Apollo.MutationFunction<
  UpdateClientProfileMutation,
  UpdateClientProfileMutationVariables
>;

/**
 * __useUpdateClientProfileMutation__
 *
 * To run a mutation, you first call `useUpdateClientProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientProfileMutation, { data, loading, error }] = useUpdateClientProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientProfileMutation,
    UpdateClientProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientProfileMutation,
    UpdateClientProfileMutationVariables
  >(UpdateClientProfileDocument, options);
}
export type UpdateClientProfileMutationHookResult = ReturnType<
  typeof useUpdateClientProfileMutation
>;
export type UpdateClientProfileMutationResult =
  Apollo.MutationResult<UpdateClientProfileMutation>;
export type UpdateClientProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientProfileMutation,
  UpdateClientProfileMutationVariables
>;
export const UpdateContactLinkDocument = gql`
  mutation UpdateContactLink($input: UpdateContactLinkInput!) {
    updateContactLink(input: $input) {
      ... on UpdateContactLink {
        contact {
          id
          clientId
          dictionaryId
          link
          dictionary {
            id
            name
          }
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateContactLinkMutationFn = Apollo.MutationFunction<
  UpdateContactLinkMutation,
  UpdateContactLinkMutationVariables
>;

/**
 * __useUpdateContactLinkMutation__
 *
 * To run a mutation, you first call `useUpdateContactLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactLinkMutation, { data, loading, error }] = useUpdateContactLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContactLinkMutation,
    UpdateContactLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateContactLinkMutation,
    UpdateContactLinkMutationVariables
  >(UpdateContactLinkDocument, options);
}
export type UpdateContactLinkMutationHookResult = ReturnType<
  typeof useUpdateContactLinkMutation
>;
export type UpdateContactLinkMutationResult =
  Apollo.MutationResult<UpdateContactLinkMutation>;
export type UpdateContactLinkMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactLinkMutation,
  UpdateContactLinkMutationVariables
>;
export const UpdateEvent1Document = gql`
  mutation UpdateEvent1($updateEvent1Id: ID!, $input: UpdateEvent1Input!) {
    updateEvent1(id: $updateEvent1Id, input: $input) {
      ... on UpdateEvent1 {
        event {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateEvent1MutationFn = Apollo.MutationFunction<
  UpdateEvent1Mutation,
  UpdateEvent1MutationVariables
>;

/**
 * __useUpdateEvent1Mutation__
 *
 * To run a mutation, you first call `useUpdateEvent1Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvent1Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvent1Mutation, { data, loading, error }] = useUpdateEvent1Mutation({
 *   variables: {
 *      updateEvent1Id: // value for 'updateEvent1Id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEvent1Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEvent1Mutation,
    UpdateEvent1MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEvent1Mutation,
    UpdateEvent1MutationVariables
  >(UpdateEvent1Document, options);
}
export type UpdateEvent1MutationHookResult = ReturnType<
  typeof useUpdateEvent1Mutation
>;
export type UpdateEvent1MutationResult =
  Apollo.MutationResult<UpdateEvent1Mutation>;
export type UpdateEvent1MutationOptions = Apollo.BaseMutationOptions<
  UpdateEvent1Mutation,
  UpdateEvent1MutationVariables
>;
export const UpdateEvent2Document = gql`
  mutation UpdateEvent2($updateEvent2Id: ID!, $input: UpdateEvent2Input!) {
    updateEvent2(id: $updateEvent2Id, input: $input) {
      ... on UpdateEvent2 {
        event {
          id
          name
          categoryId
          category
          cityId
          city
          themes
          address
          dates
          time
          spot
          image
          online
          isLocationUnknown
          shortDescription
          fullDescription
          spotClient
          peopleAmountFrom
          peopleAmountTo
          targetAudienceId
          free
          priceFrom
          priceTo
          favorite
          status
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateEvent2MutationFn = Apollo.MutationFunction<
  UpdateEvent2Mutation,
  UpdateEvent2MutationVariables
>;

/**
 * __useUpdateEvent2Mutation__
 *
 * To run a mutation, you first call `useUpdateEvent2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvent2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvent2Mutation, { data, loading, error }] = useUpdateEvent2Mutation({
 *   variables: {
 *      updateEvent2Id: // value for 'updateEvent2Id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEvent2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEvent2Mutation,
    UpdateEvent2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEvent2Mutation,
    UpdateEvent2MutationVariables
  >(UpdateEvent2Document, options);
}
export type UpdateEvent2MutationHookResult = ReturnType<
  typeof useUpdateEvent2Mutation
>;
export type UpdateEvent2MutationResult =
  Apollo.MutationResult<UpdateEvent2Mutation>;
export type UpdateEvent2MutationOptions = Apollo.BaseMutationOptions<
  UpdateEvent2Mutation,
  UpdateEvent2MutationVariables
>;
export const UpdateEvent3Document = gql`
  mutation UpdateEvent3($updateEvent3Id: ID!, $input: UpdateEvent3Input!) {
    updateEvent3(id: $updateEvent3Id, input: $input) {
      ... on UpdateEvent3 {
        event {
          id
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateEvent3MutationFn = Apollo.MutationFunction<
  UpdateEvent3Mutation,
  UpdateEvent3MutationVariables
>;

/**
 * __useUpdateEvent3Mutation__
 *
 * To run a mutation, you first call `useUpdateEvent3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvent3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvent3Mutation, { data, loading, error }] = useUpdateEvent3Mutation({
 *   variables: {
 *      updateEvent3Id: // value for 'updateEvent3Id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEvent3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEvent3Mutation,
    UpdateEvent3MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEvent3Mutation,
    UpdateEvent3MutationVariables
  >(UpdateEvent3Document, options);
}
export type UpdateEvent3MutationHookResult = ReturnType<
  typeof useUpdateEvent3Mutation
>;
export type UpdateEvent3MutationResult =
  Apollo.MutationResult<UpdateEvent3Mutation>;
export type UpdateEvent3MutationOptions = Apollo.BaseMutationOptions<
  UpdateEvent3Mutation,
  UpdateEvent3MutationVariables
>;
export const UpdateEvent4Document = gql`
  mutation UpdateEvent4($updateEvent4Id: ID!, $input: UpdateEvent4Input!) {
    updateEvent4(id: $updateEvent4Id, input: $input) {
      ... on UpdateEvent4 {
        event {
          id
          name
          categoryId
          category
          cityId
          city
          themes
          address
          dates
          time
          spot
          image
          online
          isLocationUnknown
          shortDescription
          fullDescription
          spotClient
          peopleAmountFrom
          peopleAmountTo
          targetAudienceId
          free
          priceFrom
          priceTo
          favorite
          status
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateEvent4MutationFn = Apollo.MutationFunction<
  UpdateEvent4Mutation,
  UpdateEvent4MutationVariables
>;

/**
 * __useUpdateEvent4Mutation__
 *
 * To run a mutation, you first call `useUpdateEvent4Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEvent4Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEvent4Mutation, { data, loading, error }] = useUpdateEvent4Mutation({
 *   variables: {
 *      updateEvent4Id: // value for 'updateEvent4Id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEvent4Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEvent4Mutation,
    UpdateEvent4MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEvent4Mutation,
    UpdateEvent4MutationVariables
  >(UpdateEvent4Document, options);
}
export type UpdateEvent4MutationHookResult = ReturnType<
  typeof useUpdateEvent4Mutation
>;
export type UpdateEvent4MutationResult =
  Apollo.MutationResult<UpdateEvent4Mutation>;
export type UpdateEvent4MutationOptions = Apollo.BaseMutationOptions<
  UpdateEvent4Mutation,
  UpdateEvent4MutationVariables
>;
export const UpdateFirstRegistrationDocument = gql`
  mutation UpdateFirstRegistration($input: UpdateFirstRegistrationInput!) {
    updateFirstRegistration(input: $input) {
      ... on UpdateFirstRegistration {
        client {
          firstRegistration
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateFirstRegistrationMutationFn = Apollo.MutationFunction<
  UpdateFirstRegistrationMutation,
  UpdateFirstRegistrationMutationVariables
>;

/**
 * __useUpdateFirstRegistrationMutation__
 *
 * To run a mutation, you first call `useUpdateFirstRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFirstRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFirstRegistrationMutation, { data, loading, error }] = useUpdateFirstRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFirstRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFirstRegistrationMutation,
    UpdateFirstRegistrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFirstRegistrationMutation,
    UpdateFirstRegistrationMutationVariables
  >(UpdateFirstRegistrationDocument, options);
}
export type UpdateFirstRegistrationMutationHookResult = ReturnType<
  typeof useUpdateFirstRegistrationMutation
>;
export type UpdateFirstRegistrationMutationResult =
  Apollo.MutationResult<UpdateFirstRegistrationMutation>;
export type UpdateFirstRegistrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateFirstRegistrationMutation,
  UpdateFirstRegistrationMutationVariables
>;
export const UpdateLocationDocument = gql`
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      ... on UpdateLocation {
        location {
          id
          address
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >(UpdateLocationDocument, options);
}
export type UpdateLocationMutationHookResult = ReturnType<
  typeof useUpdateLocationMutation
>;
export type UpdateLocationMutationResult =
  Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;
export const UpdateStylingDocument = gql`
  mutation UpdateStyling($input: UpdateStylingInput!) {
    updateStyling(input: $input) {
      ... on UpdateStyling {
        styling {
          id
          color
          cover
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type UpdateStylingMutationFn = Apollo.MutationFunction<
  UpdateStylingMutation,
  UpdateStylingMutationVariables
>;

/**
 * __useUpdateStylingMutation__
 *
 * To run a mutation, you first call `useUpdateStylingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStylingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStylingMutation, { data, loading, error }] = useUpdateStylingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStylingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStylingMutation,
    UpdateStylingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStylingMutation,
    UpdateStylingMutationVariables
  >(UpdateStylingDocument, options);
}
export type UpdateStylingMutationHookResult = ReturnType<
  typeof useUpdateStylingMutation
>;
export type UpdateStylingMutationResult =
  Apollo.MutationResult<UpdateStylingMutation>;
export type UpdateStylingMutationOptions = Apollo.BaseMutationOptions<
  UpdateStylingMutation,
  UpdateStylingMutationVariables
>;
export const UploadFileDocument = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
      ... on UploadFileClient {
        fileName
      }
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const AddEventWithoutStepsDocument = gql`
  mutation AddEventWithoutSteps($input: AddEventWithoutStepsInput!) {
    addEventWithoutSteps(input: $input) {
      ... on AddEventWithoutSteps {
        event {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type AddEventWithoutStepsMutationFn = Apollo.MutationFunction<
  AddEventWithoutStepsMutation,
  AddEventWithoutStepsMutationVariables
>;

/**
 * __useAddEventWithoutStepsMutation__
 *
 * To run a mutation, you first call `useAddEventWithoutStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventWithoutStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventWithoutStepsMutation, { data, loading, error }] = useAddEventWithoutStepsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventWithoutStepsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddEventWithoutStepsMutation,
    AddEventWithoutStepsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddEventWithoutStepsMutation,
    AddEventWithoutStepsMutationVariables
  >(AddEventWithoutStepsDocument, options);
}
export type AddEventWithoutStepsMutationHookResult = ReturnType<
  typeof useAddEventWithoutStepsMutation
>;
export type AddEventWithoutStepsMutationResult =
  Apollo.MutationResult<AddEventWithoutStepsMutation>;
export type AddEventWithoutStepsMutationOptions = Apollo.BaseMutationOptions<
  AddEventWithoutStepsMutation,
  AddEventWithoutStepsMutationVariables
>;
export const CanSentAppDocument = gql`
  query CanSentApp($recipientId: Int!, $senderId: Int!) {
    canSentApp(recipientId: $recipientId, senderId: $senderId)
  }
`;

/**
 * __useCanSentAppQuery__
 *
 * To run a query within a React component, call `useCanSentAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanSentAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanSentAppQuery({
 *   variables: {
 *      recipientId: // value for 'recipientId'
 *      senderId: // value for 'senderId'
 *   },
 * });
 */
export function useCanSentAppQuery(
  baseOptions: Apollo.QueryHookOptions<
    CanSentAppQuery,
    CanSentAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanSentAppQuery, CanSentAppQueryVariables>(
    CanSentAppDocument,
    options
  );
}
export function useCanSentAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanSentAppQuery,
    CanSentAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CanSentAppQuery, CanSentAppQueryVariables>(
    CanSentAppDocument,
    options
  );
}
export type CanSentAppQueryHookResult = ReturnType<typeof useCanSentAppQuery>;
export type CanSentAppLazyQueryHookResult = ReturnType<
  typeof useCanSentAppLazyQuery
>;
export type CanSentAppQueryResult = Apollo.QueryResult<
  CanSentAppQuery,
  CanSentAppQueryVariables
>;
export const CheckApplicationDocument = gql`
  mutation CheckApplication($checkApplicationId: Int!) {
    checkApplication(id: $checkApplicationId) {
      ... on CheckApplication {
        checked
      }
    }
  }
`;
export type CheckApplicationMutationFn = Apollo.MutationFunction<
  CheckApplicationMutation,
  CheckApplicationMutationVariables
>;

/**
 * __useCheckApplicationMutation__
 *
 * To run a mutation, you first call `useCheckApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkApplicationMutation, { data, loading, error }] = useCheckApplicationMutation({
 *   variables: {
 *      checkApplicationId: // value for 'checkApplicationId'
 *   },
 * });
 */
export function useCheckApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckApplicationMutation,
    CheckApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckApplicationMutation,
    CheckApplicationMutationVariables
  >(CheckApplicationDocument, options);
}
export type CheckApplicationMutationHookResult = ReturnType<
  typeof useCheckApplicationMutation
>;
export type CheckApplicationMutationResult =
  Apollo.MutationResult<CheckApplicationMutation>;
export type CheckApplicationMutationOptions = Apollo.BaseMutationOptions<
  CheckApplicationMutation,
  CheckApplicationMutationVariables
>;
export const CheckFreeAddressDocument = gql`
  query CheckFreeAddress($input: CheckFreeAddressInput!) {
    checkFreeAddress(input: $input) {
      ... on CheckFreeAddress {
        free
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useCheckFreeAddressQuery__
 *
 * To run a query within a React component, call `useCheckFreeAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckFreeAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckFreeAddressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckFreeAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckFreeAddressQuery,
    CheckFreeAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckFreeAddressQuery, CheckFreeAddressQueryVariables>(
    CheckFreeAddressDocument,
    options
  );
}
export function useCheckFreeAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckFreeAddressQuery,
    CheckFreeAddressQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckFreeAddressQuery,
    CheckFreeAddressQueryVariables
  >(CheckFreeAddressDocument, options);
}
export type CheckFreeAddressQueryHookResult = ReturnType<
  typeof useCheckFreeAddressQuery
>;
export type CheckFreeAddressLazyQueryHookResult = ReturnType<
  typeof useCheckFreeAddressLazyQuery
>;
export type CheckFreeAddressQueryResult = Apollo.QueryResult<
  CheckFreeAddressQuery,
  CheckFreeAddressQueryVariables
>;
export const CreateApplicationLongDocument = gql`
  mutation CreateApplicationLong($input: CreateApplicationLongInput!) {
    createApplicationLong(input: $input) {
      ... on CreateApplicationLong {
        id
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type CreateApplicationLongMutationFn = Apollo.MutationFunction<
  CreateApplicationLongMutation,
  CreateApplicationLongMutationVariables
>;

/**
 * __useCreateApplicationLongMutation__
 *
 * To run a mutation, you first call `useCreateApplicationLongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationLongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationLongMutation, { data, loading, error }] = useCreateApplicationLongMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplicationLongMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateApplicationLongMutation,
    CreateApplicationLongMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateApplicationLongMutation,
    CreateApplicationLongMutationVariables
  >(CreateApplicationLongDocument, options);
}
export type CreateApplicationLongMutationHookResult = ReturnType<
  typeof useCreateApplicationLongMutation
>;
export type CreateApplicationLongMutationResult =
  Apollo.MutationResult<CreateApplicationLongMutation>;
export type CreateApplicationLongMutationOptions = Apollo.BaseMutationOptions<
  CreateApplicationLongMutation,
  CreateApplicationLongMutationVariables
>;
export const GetApplicationCountDocument = gql`
  query GetApplicationCount {
    getApplicationCount
  }
`;

/**
 * __useGetApplicationCountQuery__
 *
 * To run a query within a React component, call `useGetApplicationCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApplicationCountQuery,
    GetApplicationCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationCountQuery,
    GetApplicationCountQueryVariables
  >(GetApplicationCountDocument, options);
}
export function useGetApplicationCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationCountQuery,
    GetApplicationCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationCountQuery,
    GetApplicationCountQueryVariables
  >(GetApplicationCountDocument, options);
}
export type GetApplicationCountQueryHookResult = ReturnType<
  typeof useGetApplicationCountQuery
>;
export type GetApplicationCountLazyQueryHookResult = ReturnType<
  typeof useGetApplicationCountLazyQuery
>;
export type GetApplicationCountQueryResult = Apollo.QueryResult<
  GetApplicationCountQuery,
  GetApplicationCountQueryVariables
>;
export const GetApplicationStatusesDocument = gql`
  query GetApplicationStatuses {
    getApplicationStatuses
  }
`;

/**
 * __useGetApplicationStatusesQuery__
 *
 * To run a query within a React component, call `useGetApplicationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetApplicationStatusesQuery,
    GetApplicationStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetApplicationStatusesQuery,
    GetApplicationStatusesQueryVariables
  >(GetApplicationStatusesDocument, options);
}
export function useGetApplicationStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApplicationStatusesQuery,
    GetApplicationStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetApplicationStatusesQuery,
    GetApplicationStatusesQueryVariables
  >(GetApplicationStatusesDocument, options);
}
export type GetApplicationStatusesQueryHookResult = ReturnType<
  typeof useGetApplicationStatusesQuery
>;
export type GetApplicationStatusesLazyQueryHookResult = ReturnType<
  typeof useGetApplicationStatusesLazyQuery
>;
export type GetApplicationStatusesQueryResult = Apollo.QueryResult<
  GetApplicationStatusesQuery,
  GetApplicationStatusesQueryVariables
>;
export const GetBrandsDocument = gql`
  query GetBrands {
    getBrands {
      ... on GetBrands {
        brands {
          id
          src
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(
    GetBrandsDocument,
    options
  );
}
export function useGetBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandsQuery,
    GetBrandsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(
    GetBrandsDocument,
    options
  );
}
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<
  typeof useGetBrandsLazyQuery
>;
export type GetBrandsQueryResult = Apollo.QueryResult<
  GetBrandsQuery,
  GetBrandsQueryVariables
>;
export const GetCasesDocument = gql`
  query GetCases {
    getCases {
      ... on GetCases {
        cases {
          id
          name
          text
          src
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetCasesQuery__
 *
 * To run a query within a React component, call `useGetCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCasesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCasesQuery, GetCasesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCasesQuery, GetCasesQueryVariables>(
    GetCasesDocument,
    options
  );
}
export function useGetCasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCasesQuery,
    GetCasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCasesQuery, GetCasesQueryVariables>(
    GetCasesDocument,
    options
  );
}
export type GetCasesQueryHookResult = ReturnType<typeof useGetCasesQuery>;
export type GetCasesLazyQueryHookResult = ReturnType<
  typeof useGetCasesLazyQuery
>;
export type GetCasesQueryResult = Apollo.QueryResult<
  GetCasesQuery,
  GetCasesQueryVariables
>;
export const GetChannelsDocument = gql`
  query GetChannels($input: GetChannelsInput) {
    getChannels(input: $input) {
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
      ... on GetChannels {
        channels {
          id
          type
          name
          clientId
          deletedAt
          createdAt
          updatedAt
          value
          audience
        }
      }
    }
  }
`;

/**
 * __useGetChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChannelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChannelsQuery,
    GetChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChannelsQuery, GetChannelsQueryVariables>(
    GetChannelsDocument,
    options
  );
}
export function useGetChannelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChannelsQuery,
    GetChannelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChannelsQuery, GetChannelsQueryVariables>(
    GetChannelsDocument,
    options
  );
}
export type GetChannelsQueryHookResult = ReturnType<typeof useGetChannelsQuery>;
export type GetChannelsLazyQueryHookResult = ReturnType<
  typeof useGetChannelsLazyQuery
>;
export type GetChannelsQueryResult = Apollo.QueryResult<
  GetChannelsQuery,
  GetChannelsQueryVariables
>;
export const GetCitiesDocument = gql`
  query GetCities {
    getCities {
      ... on GetCities {
        cities {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
      ... on GetDictCities {
        cities {
          id
          name
          countryId
        }
      }
    }
  }
`;

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCitiesQuery, GetCitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(
    GetCitiesDocument,
    options
  );
}
export function useGetCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCitiesQuery,
    GetCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(
    GetCitiesDocument,
    options
  );
}
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>;
export type GetCitiesLazyQueryHookResult = ReturnType<
  typeof useGetCitiesLazyQuery
>;
export type GetCitiesQueryResult = Apollo.QueryResult<
  GetCitiesQuery,
  GetCitiesQueryVariables
>;
export const ClientDocument = gql`
  query Client($input: GetMyClientInput) {
    getMyClient(input: $input) {
      client {
        id
        type
        email
        name
        published
      }
    }
  }
`;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientQuery(
  baseOptions?: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientQuery, ClientQueryVariables>(
    ClientDocument,
    options
  );
}
export function useClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(
    ClientDocument,
    options
  );
}
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<
  ClientQuery,
  ClientQueryVariables
>;
export const GetClientApplicationDocument = gql`
  query GetClientApplication($input: GetClientApplicationVars!) {
    getClientApplication(input: $input) {
      senderId
      recipientId
      createdAt
      id
    }
  }
`;

/**
 * __useGetClientApplicationQuery__
 *
 * To run a query within a React component, call `useGetClientApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientApplicationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientApplicationQuery,
    GetClientApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientApplicationQuery,
    GetClientApplicationQueryVariables
  >(GetClientApplicationDocument, options);
}
export function useGetClientApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientApplicationQuery,
    GetClientApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientApplicationQuery,
    GetClientApplicationQueryVariables
  >(GetClientApplicationDocument, options);
}
export type GetClientApplicationQueryHookResult = ReturnType<
  typeof useGetClientApplicationQuery
>;
export type GetClientApplicationLazyQueryHookResult = ReturnType<
  typeof useGetClientApplicationLazyQuery
>;
export type GetClientApplicationQueryResult = Apollo.QueryResult<
  GetClientApplicationQuery,
  GetClientApplicationQueryVariables
>;
export const GetClientApplicationByPkDocument = gql`
  query GetClientApplicationByPk($input: GetClientApplicationByPkVars!) {
    getClientApplicationByPk(input: $input) {
      id
      approvedByRecipient
      approvedBySender
      createdAt
      recipient {
        email
        firstName
        id
        lastName
        phone
        position
        profile {
          logo
          name
          address
        }
      }
      recipientId
      sender {
        email
        firstName
        id
        lastName
        phone
        position
        profile {
          logo
          name
          address
        }
      }
      senderId
      status
      text
      updatedAt
    }
  }
`;

/**
 * __useGetClientApplicationByPkQuery__
 *
 * To run a query within a React component, call `useGetClientApplicationByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientApplicationByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientApplicationByPkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientApplicationByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientApplicationByPkQuery,
    GetClientApplicationByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientApplicationByPkQuery,
    GetClientApplicationByPkQueryVariables
  >(GetClientApplicationByPkDocument, options);
}
export function useGetClientApplicationByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientApplicationByPkQuery,
    GetClientApplicationByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientApplicationByPkQuery,
    GetClientApplicationByPkQueryVariables
  >(GetClientApplicationByPkDocument, options);
}
export type GetClientApplicationByPkQueryHookResult = ReturnType<
  typeof useGetClientApplicationByPkQuery
>;
export type GetClientApplicationByPkLazyQueryHookResult = ReturnType<
  typeof useGetClientApplicationByPkLazyQuery
>;
export type GetClientApplicationByPkQueryResult = Apollo.QueryResult<
  GetClientApplicationByPkQuery,
  GetClientApplicationByPkQueryVariables
>;
export const GetClientApplicationsByPkDocument = gql`
  query GetClientApplicationsByPk($input: GetClientApplicationsByPkVars!) {
    getClientApplicationsByPk(input: $input) {
      items {
        id
        createdAt
        recipient {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
        sender {
          email
          firstName
          id
          lastName
          phone
          position
          profile {
            logo
            name
          }
        }
        newByRecipient
        newBySender
        status
        updatedAt
      }
      totalCount
      totalPages
    }
  }
`;

/**
 * __useGetClientApplicationsByPkQuery__
 *
 * To run a query within a React component, call `useGetClientApplicationsByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientApplicationsByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientApplicationsByPkQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientApplicationsByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientApplicationsByPkQuery,
    GetClientApplicationsByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientApplicationsByPkQuery,
    GetClientApplicationsByPkQueryVariables
  >(GetClientApplicationsByPkDocument, options);
}
export function useGetClientApplicationsByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientApplicationsByPkQuery,
    GetClientApplicationsByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientApplicationsByPkQuery,
    GetClientApplicationsByPkQueryVariables
  >(GetClientApplicationsByPkDocument, options);
}
export type GetClientApplicationsByPkQueryHookResult = ReturnType<
  typeof useGetClientApplicationsByPkQuery
>;
export type GetClientApplicationsByPkLazyQueryHookResult = ReturnType<
  typeof useGetClientApplicationsByPkLazyQuery
>;
export type GetClientApplicationsByPkQueryResult = Apollo.QueryResult<
  GetClientApplicationsByPkQuery,
  GetClientApplicationsByPkQueryVariables
>;
export const GetClientCategoriesDocument = gql`
  query GetClientCategories {
    getClientCategories {
      ... on GetClientCategories {
        categories {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetClientCategoriesQuery__
 *
 * To run a query within a React component, call `useGetClientCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientCategoriesQuery,
    GetClientCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientCategoriesQuery,
    GetClientCategoriesQueryVariables
  >(GetClientCategoriesDocument, options);
}
export function useGetClientCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientCategoriesQuery,
    GetClientCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientCategoriesQuery,
    GetClientCategoriesQueryVariables
  >(GetClientCategoriesDocument, options);
}
export type GetClientCategoriesQueryHookResult = ReturnType<
  typeof useGetClientCategoriesQuery
>;
export type GetClientCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetClientCategoriesLazyQuery
>;
export type GetClientCategoriesQueryResult = Apollo.QueryResult<
  GetClientCategoriesQuery,
  GetClientCategoriesQueryVariables
>;
export const GetClientInterestsDocument = gql`
  query GetClientInterests {
    getClientInterests {
      ... on GetClientInterests {
        interests {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetClientInterestsQuery__
 *
 * To run a query within a React component, call `useGetClientInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientInterestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientInterestsQuery,
    GetClientInterestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientInterestsQuery,
    GetClientInterestsQueryVariables
  >(GetClientInterestsDocument, options);
}
export function useGetClientInterestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientInterestsQuery,
    GetClientInterestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientInterestsQuery,
    GetClientInterestsQueryVariables
  >(GetClientInterestsDocument, options);
}
export type GetClientInterestsQueryHookResult = ReturnType<
  typeof useGetClientInterestsQuery
>;
export type GetClientInterestsLazyQueryHookResult = ReturnType<
  typeof useGetClientInterestsLazyQuery
>;
export type GetClientInterestsQueryResult = Apollo.QueryResult<
  GetClientInterestsQuery,
  GetClientInterestsQueryVariables
>;
export const GetClientsDocument = gql`
  query GetClients($input: GetClientsInput) {
    getClients(input: $input) {
      items {
        id
        type
        email
        name
        published
        tariff {
          clientId
          startedAt
          expiredAt
          outgoingRequests
          availableRequests
          type
          request
          assistant
          assistantLink
          paid
          status
          createdAt
        }
        profile {
          clientId
          name
          shortDescription
          logo
          address
          categories {
            id
            name
          }
        }
        audience {
          clientId
          gender
          ageSegments
          interests {
            id
            name
          }
        }
        firstRegistration
        channels {
          id
          type
          name
          value
          audience
          clientId
          deletedAt
          createdAt
          updatedAt
        }
      }
      totalPages
      totalCount
    }
  }
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options
  );
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options
  );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<
  typeof useGetClientsLazyQuery
>;
export type GetClientsQueryResult = Apollo.QueryResult<
  GetClientsQuery,
  GetClientsQueryVariables
>;
export const GetContactDictDocument = gql`
  query GetContactDict {
    getContactDict {
      ... on GetContactDict {
        contacts {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetContactDictQuery__
 *
 * To run a query within a React component, call `useGetContactDictQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactDictQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactDictQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactDictQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContactDictQuery,
    GetContactDictQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactDictQuery, GetContactDictQueryVariables>(
    GetContactDictDocument,
    options
  );
}
export function useGetContactDictLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactDictQuery,
    GetContactDictQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContactDictQuery, GetContactDictQueryVariables>(
    GetContactDictDocument,
    options
  );
}
export type GetContactDictQueryHookResult = ReturnType<
  typeof useGetContactDictQuery
>;
export type GetContactDictLazyQueryHookResult = ReturnType<
  typeof useGetContactDictLazyQuery
>;
export type GetContactDictQueryResult = Apollo.QueryResult<
  GetContactDictQuery,
  GetContactDictQueryVariables
>;
export const GetContactLinksDocument = gql`
  query GetContactLinks($input: GetContactLinksInput) {
    getContactLinks(input: $input) {
      ... on GetContactLinks {
        contacts {
          id
          clientId
          dictionaryId
          link
          dictionary {
            id
            name
          }
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetContactLinksQuery__
 *
 * To run a query within a React component, call `useGetContactLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactLinksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContactLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetContactLinksQuery,
    GetContactLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContactLinksQuery, GetContactLinksQueryVariables>(
    GetContactLinksDocument,
    options
  );
}
export function useGetContactLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContactLinksQuery,
    GetContactLinksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetContactLinksQuery,
    GetContactLinksQueryVariables
  >(GetContactLinksDocument, options);
}
export type GetContactLinksQueryHookResult = ReturnType<
  typeof useGetContactLinksQuery
>;
export type GetContactLinksLazyQueryHookResult = ReturnType<
  typeof useGetContactLinksLazyQuery
>;
export type GetContactLinksQueryResult = Apollo.QueryResult<
  GetContactLinksQuery,
  GetContactLinksQueryVariables
>;
export const GetCountriesDocument = gql`
  query GetCountries {
    getCountries {
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
      ... on GetCountries {
        countries {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options
  );
}
export function useGetCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options
  );
}
export type GetCountriesQueryHookResult = ReturnType<
  typeof useGetCountriesQuery
>;
export type GetCountriesLazyQueryHookResult = ReturnType<
  typeof useGetCountriesLazyQuery
>;
export type GetCountriesQueryResult = Apollo.QueryResult<
  GetCountriesQuery,
  GetCountriesQueryVariables
>;
export const GetDictCitiesDocument = gql`
  query GetDictCities($input: GetDictCitiesVars) {
    getDictCities(input: $input) {
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
      ... on GetDictCities {
        cities {
          id
          name
          countryId
        }
      }
    }
  }
`;

/**
 * __useGetDictCitiesQuery__
 *
 * To run a query within a React component, call `useGetDictCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDictCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDictCitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDictCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDictCitiesQuery,
    GetDictCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDictCitiesQuery, GetDictCitiesQueryVariables>(
    GetDictCitiesDocument,
    options
  );
}
export function useGetDictCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDictCitiesQuery,
    GetDictCitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDictCitiesQuery, GetDictCitiesQueryVariables>(
    GetDictCitiesDocument,
    options
  );
}
export type GetDictCitiesQueryHookResult = ReturnType<
  typeof useGetDictCitiesQuery
>;
export type GetDictCitiesLazyQueryHookResult = ReturnType<
  typeof useGetDictCitiesLazyQuery
>;
export type GetDictCitiesQueryResult = Apollo.QueryResult<
  GetDictCitiesQuery,
  GetDictCitiesQueryVariables
>;
export const GetEventDocument = gql`
  query GetEvent($getEventId: ID!) {
    getEvent(id: $getEventId) {
      ... on GetEvent {
        event {
          id
          clientId
          name
          categoryId
          category
          cityId
          city
          themes
          address
          dates
          time
          spot
          image
          online
          offline
          isLocationUnknown
          shortDescription
          fullDescription
          website
          spotClient
          typeAudience
          targetAudience
          peopleAmountTo
          peopleAmountFrom
          free
          priceFrom
          priceTo
          priceDescription
          status
          favorite
          ageStatements
          genderStatements
          interests
          area
        }
        sponsorCategories {
          id
          name
        }
        packages {
          id
          name
          price
          conditions {
            id
            name
          }
          barter
        }
        partners {
          id
          image
          name
        }
        profile {
          name
          logo
          address
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      getEventId: // value for 'getEventId'
 *   },
 * });
 */
export function useGetEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(
    GetEventDocument,
    options
  );
}
export function useGetEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventQuery,
    GetEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(
    GetEventDocument,
    options
  );
}
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<
  typeof useGetEventLazyQuery
>;
export type GetEventQueryResult = Apollo.QueryResult<
  GetEventQuery,
  GetEventQueryVariables
>;
export const GetEventApplicationDocument = gql`
  query GetEventApplication($input: GetEventApplicationVars!) {
    getEventApplication(input: $input) {
      senderId
      recipientId
      createdAt
      id
      updatedAt
      status
      approvedByRecipient
      approvedBySender
      sender {
        id
        profile {
          name
          logo
          address
        }
        firstName
        lastName
        position
        phone
        email
      }
      recipient {
        id
        profile {
          name
          logo
          address
        }
        firstName
        lastName
        position
        phone
        email
      }
    }
  }
`;

/**
 * __useGetEventApplicationQuery__
 *
 * To run a query within a React component, call `useGetEventApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventApplicationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEventApplicationQuery,
    GetEventApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventApplicationQuery,
    GetEventApplicationQueryVariables
  >(GetEventApplicationDocument, options);
}
export function useGetEventApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventApplicationQuery,
    GetEventApplicationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventApplicationQuery,
    GetEventApplicationQueryVariables
  >(GetEventApplicationDocument, options);
}
export type GetEventApplicationQueryHookResult = ReturnType<
  typeof useGetEventApplicationQuery
>;
export type GetEventApplicationLazyQueryHookResult = ReturnType<
  typeof useGetEventApplicationLazyQuery
>;
export type GetEventApplicationQueryResult = Apollo.QueryResult<
  GetEventApplicationQuery,
  GetEventApplicationQueryVariables
>;
export const GetEventCategoriesDocument = gql`
  query GetEventCategories {
    getEventCategories {
      ... on GetEventCategories {
        categories {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetEventCategoriesQuery__
 *
 * To run a query within a React component, call `useGetEventCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEventCategoriesQuery,
    GetEventCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventCategoriesQuery,
    GetEventCategoriesQueryVariables
  >(GetEventCategoriesDocument, options);
}
export function useGetEventCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventCategoriesQuery,
    GetEventCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventCategoriesQuery,
    GetEventCategoriesQueryVariables
  >(GetEventCategoriesDocument, options);
}
export type GetEventCategoriesQueryHookResult = ReturnType<
  typeof useGetEventCategoriesQuery
>;
export type GetEventCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetEventCategoriesLazyQuery
>;
export type GetEventCategoriesQueryResult = Apollo.QueryResult<
  GetEventCategoriesQuery,
  GetEventCategoriesQueryVariables
>;
export const GetEventInterestsDocument = gql`
  query GetEventInterests {
    getEventInterests {
      ... on GetEventInterests {
        interests {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetEventInterestsQuery__
 *
 * To run a query within a React component, call `useGetEventInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEventInterestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEventInterestsQuery,
    GetEventInterestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventInterestsQuery,
    GetEventInterestsQueryVariables
  >(GetEventInterestsDocument, options);
}
export function useGetEventInterestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventInterestsQuery,
    GetEventInterestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventInterestsQuery,
    GetEventInterestsQueryVariables
  >(GetEventInterestsDocument, options);
}
export type GetEventInterestsQueryHookResult = ReturnType<
  typeof useGetEventInterestsQuery
>;
export type GetEventInterestsLazyQueryHookResult = ReturnType<
  typeof useGetEventInterestsLazyQuery
>;
export type GetEventInterestsQueryResult = Apollo.QueryResult<
  GetEventInterestsQuery,
  GetEventInterestsQueryVariables
>;
export const GetEventStatusDocument = gql`
  query GetEventStatus($getEventStatusId: ID) {
    getEventStatus(id: $getEventStatusId) {
      status
    }
  }
`;

/**
 * __useGetEventStatusQuery__
 *
 * To run a query within a React component, call `useGetEventStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventStatusQuery({
 *   variables: {
 *      getEventStatusId: // value for 'getEventStatusId'
 *   },
 * });
 */
export function useGetEventStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEventStatusQuery,
    GetEventStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventStatusQuery, GetEventStatusQueryVariables>(
    GetEventStatusDocument,
    options
  );
}
export function useGetEventStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventStatusQuery,
    GetEventStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventStatusQuery, GetEventStatusQueryVariables>(
    GetEventStatusDocument,
    options
  );
}
export type GetEventStatusQueryHookResult = ReturnType<
  typeof useGetEventStatusQuery
>;
export type GetEventStatusLazyQueryHookResult = ReturnType<
  typeof useGetEventStatusLazyQuery
>;
export type GetEventStatusQueryResult = Apollo.QueryResult<
  GetEventStatusQuery,
  GetEventStatusQueryVariables
>;
export const GetEventStepsDocument = gql`
  query GetEventSteps($getEventStepsId: ID) {
    getEventSteps(id: $getEventStepsId) {
      ... on GetEventSteps {
        steps {
          filled
          verified
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetEventStepsQuery__
 *
 * To run a query within a React component, call `useGetEventStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventStepsQuery({
 *   variables: {
 *      getEventStepsId: // value for 'getEventStepsId'
 *   },
 * });
 */
export function useGetEventStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEventStepsQuery,
    GetEventStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventStepsQuery, GetEventStepsQueryVariables>(
    GetEventStepsDocument,
    options
  );
}
export function useGetEventStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventStepsQuery,
    GetEventStepsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventStepsQuery, GetEventStepsQueryVariables>(
    GetEventStepsDocument,
    options
  );
}
export type GetEventStepsQueryHookResult = ReturnType<
  typeof useGetEventStepsQuery
>;
export type GetEventStepsLazyQueryHookResult = ReturnType<
  typeof useGetEventStepsLazyQuery
>;
export type GetEventStepsQueryResult = Apollo.QueryResult<
  GetEventStepsQuery,
  GetEventStepsQueryVariables
>;
export const GetEventsDocument = gql`
  query GetEvents($input: GetEventsInput) {
    getEvents(input: $input) {
      ... on GetEvents {
        events {
          id
          clientId
          name
          categoryId
          category
          cityId
          city
          themes
          address
          dates
          time
          spot
          image
          online
          offline
          isLocationUnknown
          shortDescription
          fullDescription
          website
          spotClient
          typeAudience
          targetAudience
          peopleAmountTo
          peopleAmountFrom
          free
          priceFrom
          priceTo
          priceDescription
          status
          favorite
          ageStatements
          genderStatements
          interests
          area
          expired
        }
        totalCount
        totalPages
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options
  );
}
export function useGetEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsQuery,
    GetEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(
    GetEventsDocument,
    options
  );
}
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<
  typeof useGetEventsLazyQuery
>;
export type GetEventsQueryResult = Apollo.QueryResult<
  GetEventsQuery,
  GetEventsQueryVariables
>;
export const GetFirstRegistrationDocument = gql`
  query GetFirstRegistration {
    getFirstRegistration
  }
`;

/**
 * __useGetFirstRegistrationQuery__
 *
 * To run a query within a React component, call `useGetFirstRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstRegistrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFirstRegistrationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFirstRegistrationQuery,
    GetFirstRegistrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFirstRegistrationQuery,
    GetFirstRegistrationQueryVariables
  >(GetFirstRegistrationDocument, options);
}
export function useGetFirstRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFirstRegistrationQuery,
    GetFirstRegistrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFirstRegistrationQuery,
    GetFirstRegistrationQueryVariables
  >(GetFirstRegistrationDocument, options);
}
export type GetFirstRegistrationQueryHookResult = ReturnType<
  typeof useGetFirstRegistrationQuery
>;
export type GetFirstRegistrationLazyQueryHookResult = ReturnType<
  typeof useGetFirstRegistrationLazyQuery
>;
export type GetFirstRegistrationQueryResult = Apollo.QueryResult<
  GetFirstRegistrationQuery,
  GetFirstRegistrationQueryVariables
>;
export const GetImageByUrlDocument = gql`
  query GetImageByUrl($url: String!) {
    getImageByUrl(url: $url)
  }
`;

/**
 * __useGetImageByUrlQuery__
 *
 * To run a query within a React component, call `useGetImageByUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageByUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageByUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetImageByUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImageByUrlQuery,
    GetImageByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetImageByUrlQuery, GetImageByUrlQueryVariables>(
    GetImageByUrlDocument,
    options
  );
}
export function useGetImageByUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImageByUrlQuery,
    GetImageByUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetImageByUrlQuery, GetImageByUrlQueryVariables>(
    GetImageByUrlDocument,
    options
  );
}
export type GetImageByUrlQueryHookResult = ReturnType<
  typeof useGetImageByUrlQuery
>;
export type GetImageByUrlLazyQueryHookResult = ReturnType<
  typeof useGetImageByUrlLazyQuery
>;
export type GetImageByUrlQueryResult = Apollo.QueryResult<
  GetImageByUrlQuery,
  GetImageByUrlQueryVariables
>;
export const GetIsNewAppCountDocument = gql`
  query GetIsNewAppCount {
    getIsNewAppCount {
      outgoingCount
      incomingCount
    }
  }
`;

/**
 * __useGetIsNewAppCountQuery__
 *
 * To run a query within a React component, call `useGetIsNewAppCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsNewAppCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsNewAppCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIsNewAppCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIsNewAppCountQuery,
    GetIsNewAppCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIsNewAppCountQuery, GetIsNewAppCountQueryVariables>(
    GetIsNewAppCountDocument,
    options
  );
}
export function useGetIsNewAppCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIsNewAppCountQuery,
    GetIsNewAppCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIsNewAppCountQuery,
    GetIsNewAppCountQueryVariables
  >(GetIsNewAppCountDocument, options);
}
export type GetIsNewAppCountQueryHookResult = ReturnType<
  typeof useGetIsNewAppCountQuery
>;
export type GetIsNewAppCountLazyQueryHookResult = ReturnType<
  typeof useGetIsNewAppCountLazyQuery
>;
export type GetIsNewAppCountQueryResult = Apollo.QueryResult<
  GetIsNewAppCountQuery,
  GetIsNewAppCountQueryVariables
>;
export const GetLocationDocument = gql`
  query GetLocation($input: LocationInput) {
    getLocation(input: $input) {
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
      ... on GetLocation {
        location {
          id
          address
          countries {
            id
            name
          }
          cities {
            id
            name
            countryId
          }
        }
      }
    }
  }
`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLocationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export function useGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<
  typeof useGetLocationLazyQuery
>;
export type GetLocationQueryResult = Apollo.QueryResult<
  GetLocationQuery,
  GetLocationQueryVariables
>;
export const GetMailingStatusDocument = gql`
  query GetMailingStatus($getMailingStatusId: ID!) {
    getMailingStatus(id: $getMailingStatusId) {
      status
    }
  }
`;

/**
 * __useGetMailingStatusQuery__
 *
 * To run a query within a React component, call `useGetMailingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMailingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMailingStatusQuery({
 *   variables: {
 *      getMailingStatusId: // value for 'getMailingStatusId'
 *   },
 * });
 */
export function useGetMailingStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMailingStatusQuery,
    GetMailingStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMailingStatusQuery, GetMailingStatusQueryVariables>(
    GetMailingStatusDocument,
    options
  );
}
export function useGetMailingStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMailingStatusQuery,
    GetMailingStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMailingStatusQuery,
    GetMailingStatusQueryVariables
  >(GetMailingStatusDocument, options);
}
export type GetMailingStatusQueryHookResult = ReturnType<
  typeof useGetMailingStatusQuery
>;
export type GetMailingStatusLazyQueryHookResult = ReturnType<
  typeof useGetMailingStatusLazyQuery
>;
export type GetMailingStatusQueryResult = Apollo.QueryResult<
  GetMailingStatusQuery,
  GetMailingStatusQueryVariables
>;
export const GetMyClientDocument = gql`
  query GetMyClient($input: GetMyClientInput) {
    getMyClient(input: $input) {
      client {
        id
        type
        firstName
        lastName
        position
        email
        name
        phone
        published
        firstRegistration
        tariff {
          clientId
          startedAt
          expiredAt
          outgoingRequests
          availableRequests
          type
          request
          assistant
          assistantLink
          paid
          status
          createdAt
        }
        active
        profile {
          clientId
          name
          shortDescription
          logo
          categories {
            id
            name
          }
          address
        }
        audience {
          clientId
          gender
          ageSegments
          interests {
            id
            name
          }
        }
        channels {
          id
          type
          name
          value
          audience
          clientId
          deletedAt
          createdAt
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetMyClientQuery__
 *
 * To run a query within a React component, call `useGetMyClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClientQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyClientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyClientQuery,
    GetMyClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyClientQuery, GetMyClientQueryVariables>(
    GetMyClientDocument,
    options
  );
}
export function useGetMyClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyClientQuery,
    GetMyClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyClientQuery, GetMyClientQueryVariables>(
    GetMyClientDocument,
    options
  );
}
export type GetMyClientQueryHookResult = ReturnType<typeof useGetMyClientQuery>;
export type GetMyClientLazyQueryHookResult = ReturnType<
  typeof useGetMyClientLazyQuery
>;
export type GetMyClientQueryResult = Apollo.QueryResult<
  GetMyClientQuery,
  GetMyClientQueryVariables
>;
export const ClientAudienceDocument = gql`
  query ClientAudience($input: GetMyClientAudienceInput) {
    getMyClientAudience(input: $input) {
      clientAudience {
        clientId
        gender
        ageSegments
        interests {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useClientAudienceQuery__
 *
 * To run a query within a React component, call `useClientAudienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientAudienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientAudienceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientAudienceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClientAudienceQuery,
    ClientAudienceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientAudienceQuery, ClientAudienceQueryVariables>(
    ClientAudienceDocument,
    options
  );
}
export function useClientAudienceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientAudienceQuery,
    ClientAudienceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientAudienceQuery, ClientAudienceQueryVariables>(
    ClientAudienceDocument,
    options
  );
}
export type ClientAudienceQueryHookResult = ReturnType<
  typeof useClientAudienceQuery
>;
export type ClientAudienceLazyQueryHookResult = ReturnType<
  typeof useClientAudienceLazyQuery
>;
export type ClientAudienceQueryResult = Apollo.QueryResult<
  ClientAudienceQuery,
  ClientAudienceQueryVariables
>;
export const GetMyClientPartnershipDocument = gql`
  query GetMyClientPartnership($input: GetMyClientPartnershipInput) {
    getMyClientPartnership(input: $input) {
      clientPartnership {
        clientId
        categories {
          id
          name
        }
        interested_in_channels {
          id
          type
          name
          clientId
          deletedAt
          createdAt
          updatedAt
          value
          audience
        }
        possibilities_channels {
          id
          type
          name
          clientId
          deletedAt
          createdAt
          updatedAt
          value
          audience
        }
      }
    }
  }
`;

/**
 * __useGetMyClientPartnershipQuery__
 *
 * To run a query within a React component, call `useGetMyClientPartnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyClientPartnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyClientPartnershipQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMyClientPartnershipQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyClientPartnershipQuery,
    GetMyClientPartnershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyClientPartnershipQuery,
    GetMyClientPartnershipQueryVariables
  >(GetMyClientPartnershipDocument, options);
}
export function useGetMyClientPartnershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyClientPartnershipQuery,
    GetMyClientPartnershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyClientPartnershipQuery,
    GetMyClientPartnershipQueryVariables
  >(GetMyClientPartnershipDocument, options);
}
export type GetMyClientPartnershipQueryHookResult = ReturnType<
  typeof useGetMyClientPartnershipQuery
>;
export type GetMyClientPartnershipLazyQueryHookResult = ReturnType<
  typeof useGetMyClientPartnershipLazyQuery
>;
export type GetMyClientPartnershipQueryResult = Apollo.QueryResult<
  GetMyClientPartnershipQuery,
  GetMyClientPartnershipQueryVariables
>;
export const ClientProfileDocument = gql`
  query ClientProfile($input: GetMyClientProfileInput) {
    getMyClientProfile(input: $input) {
      clientProfile {
        clientId
        name
        address
        model
        logo
        shortDescription
        description
        categories {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useClientProfileQuery__
 *
 * To run a query within a React component, call `useClientProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfileQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ClientProfileQuery,
    ClientProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientProfileQuery, ClientProfileQueryVariables>(
    ClientProfileDocument,
    options
  );
}
export function useClientProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientProfileQuery,
    ClientProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientProfileQuery, ClientProfileQueryVariables>(
    ClientProfileDocument,
    options
  );
}
export type ClientProfileQueryHookResult = ReturnType<
  typeof useClientProfileQuery
>;
export type ClientProfileLazyQueryHookResult = ReturnType<
  typeof useClientProfileLazyQuery
>;
export type ClientProfileQueryResult = Apollo.QueryResult<
  ClientProfileQuery,
  ClientProfileQueryVariables
>;
export const GetSponsorCategoriesDocument = gql`
  query GetSponsorCategories {
    getSponsorCategories {
      ... on GetSponsorCategories {
        categories {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetSponsorCategoriesQuery__
 *
 * To run a query within a React component, call `useGetSponsorCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSponsorCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSponsorCategoriesQuery,
    GetSponsorCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSponsorCategoriesQuery,
    GetSponsorCategoriesQueryVariables
  >(GetSponsorCategoriesDocument, options);
}
export function useGetSponsorCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSponsorCategoriesQuery,
    GetSponsorCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSponsorCategoriesQuery,
    GetSponsorCategoriesQueryVariables
  >(GetSponsorCategoriesDocument, options);
}
export type GetSponsorCategoriesQueryHookResult = ReturnType<
  typeof useGetSponsorCategoriesQuery
>;
export type GetSponsorCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetSponsorCategoriesLazyQuery
>;
export type GetSponsorCategoriesQueryResult = Apollo.QueryResult<
  GetSponsorCategoriesQuery,
  GetSponsorCategoriesQueryVariables
>;
export const GetStylingDocument = gql`
  query GetStyling($input: GetStylingVars) {
    getStyling(input: $input) {
      ... on GetStyling {
        styling {
          id
          color
          cover
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetStylingQuery__
 *
 * To run a query within a React component, call `useGetStylingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStylingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStylingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStylingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStylingQuery,
    GetStylingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStylingQuery, GetStylingQueryVariables>(
    GetStylingDocument,
    options
  );
}
export function useGetStylingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStylingQuery,
    GetStylingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStylingQuery, GetStylingQueryVariables>(
    GetStylingDocument,
    options
  );
}
export type GetStylingQueryHookResult = ReturnType<typeof useGetStylingQuery>;
export type GetStylingLazyQueryHookResult = ReturnType<
  typeof useGetStylingLazyQuery
>;
export type GetStylingQueryResult = Apollo.QueryResult<
  GetStylingQuery,
  GetStylingQueryVariables
>;
export const GetThemeTagsDocument = gql`
  query GetThemeTags {
    getThemeTags {
      ... on GetThemeTags {
        tags {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetThemeTagsQuery__
 *
 * To run a query within a React component, call `useGetThemeTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetThemeTagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetThemeTagsQuery,
    GetThemeTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThemeTagsQuery, GetThemeTagsQueryVariables>(
    GetThemeTagsDocument,
    options
  );
}
export function useGetThemeTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThemeTagsQuery,
    GetThemeTagsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThemeTagsQuery, GetThemeTagsQueryVariables>(
    GetThemeTagsDocument,
    options
  );
}
export type GetThemeTagsQueryHookResult = ReturnType<
  typeof useGetThemeTagsQuery
>;
export type GetThemeTagsLazyQueryHookResult = ReturnType<
  typeof useGetThemeTagsLazyQuery
>;
export type GetThemeTagsQueryResult = Apollo.QueryResult<
  GetThemeTagsQuery,
  GetThemeTagsQueryVariables
>;
export const GetTypesDocument = gql`
  query GetTypes {
    getTypes {
      ... on GetTypes {
        types {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetTypesQuery__
 *
 * To run a query within a React component, call `useGetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTypesQuery, GetTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTypesQuery, GetTypesQueryVariables>(
    GetTypesDocument,
    options
  );
}
export function useGetTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTypesQuery,
    GetTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTypesQuery, GetTypesQueryVariables>(
    GetTypesDocument,
    options
  );
}
export type GetTypesQueryHookResult = ReturnType<typeof useGetTypesQuery>;
export type GetTypesLazyQueryHookResult = ReturnType<
  typeof useGetTypesLazyQuery
>;
export type GetTypesQueryResult = Apollo.QueryResult<
  GetTypesQuery,
  GetTypesQueryVariables
>;
export const GetUpdateEventDescriptionInfoDocument = gql`
  query GetUpdateEventDescriptionInfo($getUpdateEventDescriptionInfoId: ID!) {
    getUpdateEventDescriptionInfo(id: $getUpdateEventDescriptionInfoId) {
      ... on GetUpdateEventDescriptionInfo {
        shortDescription
        fullDescription
        image
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetUpdateEventDescriptionInfoQuery__
 *
 * To run a query within a React component, call `useGetUpdateEventDescriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateEventDescriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateEventDescriptionInfoQuery({
 *   variables: {
 *      getUpdateEventDescriptionInfoId: // value for 'getUpdateEventDescriptionInfoId'
 *   },
 * });
 */
export function useGetUpdateEventDescriptionInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpdateEventDescriptionInfoQuery,
    GetUpdateEventDescriptionInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUpdateEventDescriptionInfoQuery,
    GetUpdateEventDescriptionInfoQueryVariables
  >(GetUpdateEventDescriptionInfoDocument, options);
}
export function useGetUpdateEventDescriptionInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpdateEventDescriptionInfoQuery,
    GetUpdateEventDescriptionInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUpdateEventDescriptionInfoQuery,
    GetUpdateEventDescriptionInfoQueryVariables
  >(GetUpdateEventDescriptionInfoDocument, options);
}
export type GetUpdateEventDescriptionInfoQueryHookResult = ReturnType<
  typeof useGetUpdateEventDescriptionInfoQuery
>;
export type GetUpdateEventDescriptionInfoLazyQueryHookResult = ReturnType<
  typeof useGetUpdateEventDescriptionInfoLazyQuery
>;
export type GetUpdateEventDescriptionInfoQueryResult = Apollo.QueryResult<
  GetUpdateEventDescriptionInfoQuery,
  GetUpdateEventDescriptionInfoQueryVariables
>;
export const GetUpdateEventInfoDocument = gql`
  query GetUpdateEventInfo($getUpdateEventInfoId: ID) {
    getUpdateEventInfo(id: $getUpdateEventInfoId) {
      ... on GetUpdateEventInfo {
        cities {
          id
          name
        }
        categories {
          id
          name
        }
        tags {
          id
          name
        }
        event {
          name
          website
          online
          offline
          isLocationUnknown
          area
          address
          themes
          category
          dates
          times
          city
          categoryId
          cityId
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetUpdateEventInfoQuery__
 *
 * To run a query within a React component, call `useGetUpdateEventInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateEventInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateEventInfoQuery({
 *   variables: {
 *      getUpdateEventInfoId: // value for 'getUpdateEventInfoId'
 *   },
 * });
 */
export function useGetUpdateEventInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUpdateEventInfoQuery,
    GetUpdateEventInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUpdateEventInfoQuery,
    GetUpdateEventInfoQueryVariables
  >(GetUpdateEventInfoDocument, options);
}
export function useGetUpdateEventInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpdateEventInfoQuery,
    GetUpdateEventInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUpdateEventInfoQuery,
    GetUpdateEventInfoQueryVariables
  >(GetUpdateEventInfoDocument, options);
}
export type GetUpdateEventInfoQueryHookResult = ReturnType<
  typeof useGetUpdateEventInfoQuery
>;
export type GetUpdateEventInfoLazyQueryHookResult = ReturnType<
  typeof useGetUpdateEventInfoLazyQuery
>;
export type GetUpdateEventInfoQueryResult = Apollo.QueryResult<
  GetUpdateEventInfoQuery,
  GetUpdateEventInfoQueryVariables
>;
export const GetUpdateEventSponsorsInfoDocument = gql`
  query GetUpdateEventSponsorsInfo($getUpdateEventSponsorsInfoId: ID!) {
    getUpdateEventSponsorsInfo(id: $getUpdateEventSponsorsInfoId) {
      ... on GetUpdateEventSponsorsInfo {
        sponsorCategories
        packages {
          id
          name
          price
          barter
          conditions {
            id
            name
          }
        }
        allCategories
        addPackages
        categories {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetUpdateEventSponsorsInfoQuery__
 *
 * To run a query within a React component, call `useGetUpdateEventSponsorsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateEventSponsorsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateEventSponsorsInfoQuery({
 *   variables: {
 *      getUpdateEventSponsorsInfoId: // value for 'getUpdateEventSponsorsInfoId'
 *   },
 * });
 */
export function useGetUpdateEventSponsorsInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpdateEventSponsorsInfoQuery,
    GetUpdateEventSponsorsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUpdateEventSponsorsInfoQuery,
    GetUpdateEventSponsorsInfoQueryVariables
  >(GetUpdateEventSponsorsInfoDocument, options);
}
export function useGetUpdateEventSponsorsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpdateEventSponsorsInfoQuery,
    GetUpdateEventSponsorsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUpdateEventSponsorsInfoQuery,
    GetUpdateEventSponsorsInfoQueryVariables
  >(GetUpdateEventSponsorsInfoDocument, options);
}
export type GetUpdateEventSponsorsInfoQueryHookResult = ReturnType<
  typeof useGetUpdateEventSponsorsInfoQuery
>;
export type GetUpdateEventSponsorsInfoLazyQueryHookResult = ReturnType<
  typeof useGetUpdateEventSponsorsInfoLazyQuery
>;
export type GetUpdateEventSponsorsInfoQueryResult = Apollo.QueryResult<
  GetUpdateEventSponsorsInfoQuery,
  GetUpdateEventSponsorsInfoQueryVariables
>;
export const GetUpdateTargetsInfoDocument = gql`
  query GetUpdateTargetsInfo($getUpdateTargetsInfoId: ID!) {
    getUpdateTargetsInfo(id: $getUpdateTargetsInfoId) {
      ... on GetUpdateTargetsInfo {
        target {
          peopleAmountFrom
          onlyPeopleAmountFrom
          peopleAmountTo
          priceFrom
          priceTo
          model
          ageStatements
          genderStatements
          free
          priceDescription
          eventInterest {
            id
            name
          }
        }
        interests {
          id
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;

/**
 * __useGetUpdateTargetsInfoQuery__
 *
 * To run a query within a React component, call `useGetUpdateTargetsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateTargetsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateTargetsInfoQuery({
 *   variables: {
 *      getUpdateTargetsInfoId: // value for 'getUpdateTargetsInfoId'
 *   },
 * });
 */
export function useGetUpdateTargetsInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUpdateTargetsInfoQuery,
    GetUpdateTargetsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUpdateTargetsInfoQuery,
    GetUpdateTargetsInfoQueryVariables
  >(GetUpdateTargetsInfoDocument, options);
}
export function useGetUpdateTargetsInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUpdateTargetsInfoQuery,
    GetUpdateTargetsInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUpdateTargetsInfoQuery,
    GetUpdateTargetsInfoQueryVariables
  >(GetUpdateTargetsInfoDocument, options);
}
export type GetUpdateTargetsInfoQueryHookResult = ReturnType<
  typeof useGetUpdateTargetsInfoQuery
>;
export type GetUpdateTargetsInfoLazyQueryHookResult = ReturnType<
  typeof useGetUpdateTargetsInfoLazyQuery
>;
export type GetUpdateTargetsInfoQueryResult = Apollo.QueryResult<
  GetUpdateTargetsInfoQuery,
  GetUpdateTargetsInfoQueryVariables
>;
export const MakeModerationEventDocument = gql`
  mutation MakeModerationEvent($makeModerationEventId: ID!) {
    makeModerationEvent(id: $makeModerationEventId) {
      ... on MakeModerationEvent {
        event {
          name
        }
      }
      ... on Error {
        warnings {
          key
          warning
        }
        error
      }
    }
  }
`;
export type MakeModerationEventMutationFn = Apollo.MutationFunction<
  MakeModerationEventMutation,
  MakeModerationEventMutationVariables
>;

/**
 * __useMakeModerationEventMutation__
 *
 * To run a mutation, you first call `useMakeModerationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeModerationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeModerationEventMutation, { data, loading, error }] = useMakeModerationEventMutation({
 *   variables: {
 *      makeModerationEventId: // value for 'makeModerationEventId'
 *   },
 * });
 */
export function useMakeModerationEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeModerationEventMutation,
    MakeModerationEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MakeModerationEventMutation,
    MakeModerationEventMutationVariables
  >(MakeModerationEventDocument, options);
}
export type MakeModerationEventMutationHookResult = ReturnType<
  typeof useMakeModerationEventMutation
>;
export type MakeModerationEventMutationResult =
  Apollo.MutationResult<MakeModerationEventMutation>;
export type MakeModerationEventMutationOptions = Apollo.BaseMutationOptions<
  MakeModerationEventMutation,
  MakeModerationEventMutationVariables
>;
