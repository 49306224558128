import css from "./title.module.scss";
import cn from "classnames";

type TitleProps = {
  children?: React.ReactNode;
  className?: string;
  size?: "small" | "large";
  style?: React.CSSProperties;
};

export const Title = ({
  children,
  className,
  size = "small",
  style,
}: TitleProps) => {
  return (
    <span
      className={cn(css.Title, size === "large" && css.Large, className)}
      style={style}
    >
      {children}
    </span>
  );
};
