import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React from "react";
import { AiOutlineForm } from "react-icons/ai";
import z, { baseObjectOutputType, ZodRawShape } from "zod";
import { Control, DefaultValues, UseFormSetError } from "react-hook-form";
import css from "./helpers.module.scss";

const itemStyle = {
  background: "white",
  color: "black",
  padding: "8px 16px",
  borderRadius: 4,
  border: "1px solid #ccc",
  cursor: "pointer",
};

const FormHelper = <T extends ZodRawShape>({
  schema,
  mock,
  control,
  setError,
  defaultValues,
}: {
  schema: z.ZodObject<T>;
  mock: z.infer<z.ZodObject<T>>;
  control: Control<z.infer<z.ZodObject<T>>, any>;
  setError: UseFormSetError<z.infer<z.ZodObject<T>>>;
  defaultValues?: DefaultValues<z.infer<z.ZodObject<T>>>;
}) => {
  return null;

  return (
    <div className={css.FormHelper}>
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger asChild>
          <button
            className={css.FormHelperButton}
            aria-label="Customise options"
            style={{
              position: "sticky",
              appearance: "none",
              background: "none",
              cursor: "pointer",
              borderRadius: 4,
              backgroundColor: "white",
              border: "1px solid #ccc",
              aspectRatio: "1/1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AiOutlineForm
              fill="#2d3142"
              style={{
                width: 32,
                height: 32,
              }}
            />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content
          sideOffset={5}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
          align="end"
        >
          <DropdownMenu.Item
            style={itemStyle}
            onClick={async () => {
              const names = Object.keys(schema.shape) as Array<
                keyof typeof schema.shape
              >;

              control._reset(
                names.reduce((acc, name) => {
                  return {
                    ...acc,
                    [name]: mock[name as keyof baseObjectOutputType<T>],
                  };
                }, {}) as z.infer<z.ZodObject<T>>
              );
            }}
          >
            Заполнить
          </DropdownMenu.Item>
          <DropdownMenu.Item
            style={itemStyle}
            onClick={() => {
              if (defaultValues) {
                control._reset(defaultValues);
                return;
              }

              const names = Object.keys(schema.shape) as Array<
                keyof typeof schema.shape
              >;

              control._reset(
                names.reduce((acc, name) => {
                  return {
                    ...acc,
                    // if array set [] else ""
                    [name]: Array.isArray(
                      mock[name as keyof baseObjectOutputType<T>]
                    )
                      ? []
                      : "",
                  };
                }, {}) as z.infer<z.ZodObject<T>>
              );
            }}
          >
            Сбросить
          </DropdownMenu.Item>
          <DropdownMenu.Item
            style={itemStyle}
            onClick={() => {
              const names = Object.keys(schema.shape) as Array<
                keyof typeof schema.shape
              >;

              names.forEach((name) => {
                setError(name as any, {
                  message: "Ошибка",
                });
              });
            }}
          >
            Отобразить состояние ошибок
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};

export { FormHelper };
