import React from "react";
import { Button } from "ui";
import { Dialog } from "ui";
import css from "./alert-dialog.module.scss";

interface AlertDialogProps {
  open: boolean;
  header?: string;
  ok?: string;
  cancel?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const AlertDialog: React.FC<AlertDialogProps> = ({
  open,
  header,
  ok,
  cancel,
  onConfirm,
  onCancel,
  children,
}) => (
  <Dialog open={open} header={header} onClose={onCancel}>
    <div className={css.Description}>{children}</div>
    <div className={css.Buttons}>
      <div className={css.Button}>
        <Button type="button" onClick={onCancel} color="secondary">
          {cancel || "Отменить"}
        </Button>
      </div>
      <div className={css.Button}>
        <Button type="button" className={css.AlertButton} onClick={onConfirm}>
          {ok || "OK"}
        </Button>
      </div>
    </div>
  </Dialog>
);
