import css from "./styles.module.scss";

export function Row({ children }: { children?: React.ReactNode }) {
  return <div className={css.Row}>{children}</div>;
}

export function FullRow({ children }: { children?: React.ReactNode }) {
  return <div className={css.FullRow}>{children}</div>;
}

export function HalfRow({ children }: { children?: React.ReactNode }) {
  return <div className={css.HalfRow}>{children}</div>;
}

export function FlexibleRow({ children }: { children?: React.ReactNode }) {
  return <div className={css.FlexibleRow}>{children}</div>;
}
