import { useContext, createContext } from "react";
import css from "./extension.module.scss";

const ExtensionContext = createContext({});

export function Extension({ children }: { children?: React.ReactNode }) {
  return (
    <ExtensionContext.Provider value={{}}>
      <span className={css.Extension}>{children}</span>
    </ExtensionContext.Provider>
  );
}

Extension.Description = function Description({
  children,
}: {
  children?: React.ReactNode;
}) {
  const context = useContext(ExtensionContext);

  if (!context) {
    throw new Error("Extension.Description must be used inside Extension");
  }

  return <span className={css.Description}>{children}</span>;
};

Extension.Format = function Description({
  children,
}: {
  children?: React.ReactNode;
}) {
  const context = useContext(ExtensionContext);

  if (!context) {
    throw new Error("Extension.Format must be used inside Extension");
  }

  return <span className={css.Format}>{children}</span>;
};
