import {
  useClientProfileQuery,
  useGetFirstRegistrationQuery,
} from "generated/types";
import { useRouter } from "next/router";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Hidden } from "ui";
import { Button } from "ui/button";
import { useMobileMenuStore } from "../../../../store/mobile-menu";
import css from "./styles.module.scss";

export function Header() {
  const { data: firstRegistration } = useGetFirstRegistrationQuery({
    fetchPolicy: "no-cache",
  });
  const { data: clientProfile } = useClientProfileQuery();

  const mobileMenuStore = useMobileMenuStore();
  const router = useRouter();

  const isFirstRegistration = firstRegistration?.getFirstRegistration;
  return (
    <div className={css.Header}>
      <Hidden down="tablet" className={css.Header}>
        <span>
          {isFirstRegistration ? "Создание" : "Редактирование"} профиля
        </span>
        {!isFirstRegistration && (
          <Button
            color="secondary"
            onClick={() => {
              router.push(
                `/${clientProfile?.getMyClientProfile?.clientProfile?.address}`
              );
            }}
            size="small"
          >
            <span
              style={{
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              Перейти к профилю
            </span>
            <ChevronRight />
          </Button>
        )}
      </Hidden>
      <Hidden up="tablet">
        <Button
          color="secondary"
          onClick={() => {
            mobileMenuStore.toggle();
          }}
          size="small"
          style={{
            width: 38,
            padding: 0,
          }}
        >
          <ChevronLeft width={20} height={20} />
        </Button>
      </Hidden>
    </div>
  );
}
