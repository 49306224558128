import s from './model.module.scss'
import {ReactNode} from "react";

type Props = {
  children: ReactNode
}

export function Model({children}: Props) {
  return (
    <div className={s.Model}>
      <p className={s.Model__list}>
        {children}
      </p>
    </div>
  )
}
