import { useLazyQuery, useQuery } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";
import GET_MY_CLIENT, {
  ClientModel,
  GetMyClientQuery,
  GetMyClientQueryVars,
} from "application/graphql/get-my-client";

interface UserContextProps {
  user: ClientModel | null;
}

const UserContext = createContext<{
  userContext: UserContextProps;
  updateUserContext: () => void;
  logoutUserContext: () => void;
}>({
  userContext: { user: null },
  updateUserContext: () => {
    throw new Error("updateUserContext not implement");
  },
  logoutUserContext: () => {
    throw new Error("logoutUserContext not implement");
  },
});

export const useUserContext = () => useContext(UserContext);

const UserContextProvider: React.FC<{ user: ClientModel | null }> = ({
  user: defaultUser,
  children,
}) => {
  const [user, setUser] = useState<ClientModel | null>(defaultUser);

  const { data, refetch: refetchUser } = useQuery<
    GetMyClientQuery,
    GetMyClientQueryVars
  >(GET_MY_CLIENT, {
    fetchPolicy: "no-cache" //fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.getMyClient.client) {
      setUser(data.getMyClient.client);
    }
  }, [data]);

  return (
    <UserContext.Provider
      value={{
        userContext: { user },
        updateUserContext: async () => {
          const { data } = await refetchUser();
          if (data?.getMyClient.client !== undefined) {
            setUser(data.getMyClient.client);
          }
        },
        logoutUserContext: () => {
          setUser(null);
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
