import React from "react";
import css from "./visible.module.scss";

interface VisibleProps {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  className?: string;
}

const Visible: React.FC<VisibleProps> = ({
  mobile,
  tablet,
  desktop,
  className,
  children,
}) => {
  const classNames: string[] = [];
  if (className) {
    classNames.push(className);
  }

  if (mobile) {
    classNames.push(css.Mobile);
  }

  if (tablet) {
    classNames.push(css.Tablet);
  }

  if (desktop) {
    classNames.push(css.Desktop);
  }

  return <div className={classNames.join(" ")}>{children}</div>;
};

export default Visible;
