import React from "react";
import { Tooltip } from "react-tooltip";

import css from "./tooltip.module.scss";

type PropsType = {
  children: React.ReactNode;
  id: string;
  isOpen?: boolean;
  index: number;
};

export function ReactTooltip({ children, isOpen, index, ...rest }: PropsType) {
  return isOpen ? (
    <Tooltip className={css.Tooltip} isOpen={isOpen} {...rest}>
      {children}
    </Tooltip>
  ) : null;
}
