import { useEffect, useState } from "react";

export function useHostname() {
  const [hostname, setHostname] = useState<string>("");

  useEffect(() => {
    setHostname(window.location.origin);
  }, []);

  return hostname;
}
