import classNames from "classnames";
import React from "react";
import css from "./select-checkbox.module.scss";
import {BiCheck} from "react-icons/bi";

interface SelectCheckboxProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  label: React.ReactNode;
  error?: boolean;
  description?: string;
}

export const SelectCheckbox = (
  {
    value,
    label,
    disabled = false,
    onChange,
    error,
    description
  }: SelectCheckboxProps) => {
  return (
    <label
      className={classNames(css.Root, {
          [css.Checked]: value,
          [css.ErrorDescription]: error
        },
        disabled ? css.disabled : ""
      )}
      onClick={(e) => {
        if (!disabled) {
          e.preventDefault();
          onChange(!value)
        }
      }}
    >
      <div className={css.Label}>{label}</div>
      <div className={css.Checkbox}>
        {value && <BiCheck className={css.CheckboxIcon} fill="#00AFFA"/>}
      </div>
    </label>
  );
};
