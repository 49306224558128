import React, { useEffect, useRef, useState } from "react";
import sanitizeHtml from "sanitize-html";
import {
  EditorProvider,
  Editor as EditorSimple,
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg";
import css from "./editor.module.scss";
import cn from "classnames";

interface EditorProps {
  value?: string;
  onChange?: (value: string) => void;
  onKeyUp?: () => void;
  placeholder?: string;
  error?: boolean;
  description?: string;
  count?: number;
}

export const Editor = ({
  value = "",
  onChange,
  onKeyUp,
  placeholder,
  error,
  description,
  count,
}: EditorProps) => {
  const [length, setLength] = useState(
    value.trim().replace(/(<([^>]+)>)/gi, "").length
  );

  const changeHandler = useRef(onChange);

  useEffect(() => {
    changeHandler.current = onChange;
  }, [onChange]);

  let countClass = "";
  if (count && count - length <= 10) {
    countClass = css.CountWarning;
  }
  if (count && length > count) {
    countClass = css.CountError;
  }

  return (
    <>
      <div className={cn(css.Root, error && css.EditorError)}>
        <EditorProvider>
          {/* 
            create placeholder
          */}
          {
            <section className={css.Placeholder}>
              {(value.trim().length === 0 ||
                value.trim() === "<br>" ||
                value.trim() === "<div><br></div>") &&
                value.trim().replace(/(<([^>]+)>)/gi, "") === "" &&
                placeholder}
            </section>
          }
          <EditorSimple
            value={value}
            onPaste={(e) => {
              e.preventDefault();
              const text = e.clipboardData.getData("text/plain");

              if (count) {
                document.execCommand("insertHTML", false, text.slice(0, count));
              } else {
                document.execCommand("insertHTML", false, text);
              }
            }}
            onChange={(e) => {
              let newValue;
              if (count) {
                newValue = e.target.value.slice(0, count);
              } else {
                newValue = e.target.value;
              }
              setLength(newValue.trim().replace(/(<([^>]+)>)/gi, "").length);

              changeHandler.current?.(newValue);
            }}
          >
            {/* <Toolbar>
              <BtnBold />
              <BtnItalic />
              <BtnUnderline />
              <Separator />
              <BtnNumberedList />
              <BtnBulletList />
            </Toolbar> */}
          </EditorSimple>
        </EditorProvider>
        {count && countClass && (
          <div className={css.Count}>
            <span className={countClass}>{length}</span>/{count}
          </div>
        )}
      </div>
      {description !== undefined && (
        <div className={error ? css.DescriptionError : css.Description}>
          {description}
        </div>
      )}
    </>
  );
};
