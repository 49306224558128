import React, { InputHTMLAttributes } from "react";
import cx from "classnames";
import css from "./textarea.module.scss";

export interface TextareaProps
  extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, "onChange"> {
  rows?: number;
  error?: boolean;
  description?: string;
  onChange?: (value: string) => void;
  onIconClick?: () => void;
  count?: number;
  unlockCount?: boolean;
}

export const Textarea: React.FC<TextareaProps> = ({
  error = false,
  description,
  onChange,
  onIconClick,
  count,
  unlockCount = false,
  className,
  ...props
}) => {
  let countClass: string = "";
  if (
    count &&
    props.value &&
    count >= props.value.toString().length &&
    count - props.value.toString().length <= 10
  ) {
    countClass = css.CountWarning;
  }
  if (count && props.value && count < props.value.toString().length) {
    countClass = css.CountError;
  }

  return (
    <div className={css.Container}>
      <div className={error ? css.RootError : css.Root}>
        <textarea
          className={cx(
            css.Input,
            count && countClass && css.InputCount,
            className
          )}
          onChange={(e) => {
            if (!!count && !unlockCount) {
              onChange?.(e.currentTarget.value.slice(0, count));
            } else {
              onChange?.(e.currentTarget.value);
            }
          }}
          {...props}
        />
        {count && countClass && (
          <div className={css.Count}>
            <span className={countClass}>
              {props.value?.toString().length || 0}
            </span>
            /{count}
          </div>
        )}
      </div>
      {description && (
        <div className={error ? css.DescriptionError : css.Description}>
          {description}
        </div>
      )}
    </div>
  );
};
